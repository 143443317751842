import * as React from "react";

import { useAppDispatch, useAppSelector } from "../../redux";
import LLMs from "./components/LLMs";
import Agents from "./components/Agents";
import Members from "./components/Members";
import Tab from "../../components/Tab";
import { FaBuilding, FaUserGroup, FaMagento, FaBrain, FaCheck, FaCircleXmark } from "react-icons/fa6";
import Overview from "./components/Overview";
import { getConfiguration, selectConfiguration } from "../../redux/configurationReducer";
import { getAudits, selectAllAudits } from "../../redux/auditReducer";
import Button from "@components/Button";
import { IoSettingsSharp } from "react-icons/io5";
import { Modal } from "flowbite-react";
import TextInput from "@components/Input/textInput";
import FileInput from "@components/Input/fileInput";
import { Option, Select } from "@material-tailwind/react";

type Props = object;

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useAppDispatch();

    const configuration = useAppSelector(selectConfiguration)
    const audits = useAppSelector(selectAllAudits)

    const [edit, setEdit] = React.useState<boolean>(false)

    React.useEffect(() => {
        dispatch(getConfiguration())
    }, [dispatch])


    React.useEffect(() => {
        dispatch(getAudits())
    }, [dispatch])

    const members = configuration.organization?.members || [];
    const invitations = configuration.organization?.invitations || [];

    const tabItems = [
        {
            title: 'Overview',
            icone: <FaBuilding className="mr-2" />,
            content: <Overview audits={audits} />,
        },
        {
            title: 'Users',
            icone: <FaUserGroup className="mr-2" />,
            content: <Members members={members} invitations={invitations} />,
        },
        {
            title: 'Agents',
            icone: <FaMagento className="mr-2" />,
            content: <Agents />,
        },
        {
            title: 'Models',
            icone: <FaBrain className="mr-2" />,
            content: <LLMs />,
        },
        /*{
            title: 'Billing',
            icone: <FaCreditCard className="mr-2" />,
            content: <div>Content for Billing</div>,
        },*/
    ];

    return (
        <div className="pl-3 pr-3 sm:p-4 sm:mt-14">
            {/* Tab header */}
            <h1 className="hidden sm:block text-2xl font-mono font-medium mb-3 flex gap-4 items-end">
                Settings of organization
                <span className="italic text-teal-500 font-semibold">{configuration?.organization?.name}</span> <IoSettingsSharp color={"#3b5998"} className="cursor-pointer" onClick={() => setEdit(!edit)} />
            </h1>
            <Tab items={tabItems} />
            <Modal show={edit} onClose={() => setEdit(false)} dismissible size="3xl" position={"center"}>
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-end">
                        <IoSettingsSharp className="text-xl" />
                        <div>
                            Settings of organization
                            <span className="italic text-teal-500 font-semibold"> {configuration?.organization?.name}</span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="space-y-4" action="#">
                            <div className="w-full flex flex-col gap-6">
                                <TextInput placeholder="Organization name" onValueChange={() => { }} />
                                <FileInput placeholder="Organization logo" onValueChange={() => { }} />
                                <Select
                                    label="Which sector does tour organization belong to?" size="lg" color="teal"
                                    animate={{ mount: { y: 0 }, unmount: { y: 25 }, }}
                                    className="flex items-center opacity-100 px-0 gap-2 bg-gray-50 text-gray-900 text-sm"
                                    value={""}
                                    placeholder="Choose a response"
                                    onChange={() => { }}
                                    disabled={false}
                                    variant="outlined"
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}>

                                    <Option value="finance">Finance</Option>
                                    <Option value="healthcare">Healthcare</Option>
                                    <Option value="technology">Technology (AI, ICT)</Option>
                                    <Option value="manufacturing">Manufacturing</Option>
                                    <Option value="energy">Energy</Option>
                                    <Option value="telecommunications">Telecommunications</Option>
                                    <Option value="administration">Public Administration</Option>
                                    <Option value="transport">Transport</Option>
                                    <Option value="supply">Food Supply Chain</Option>
                                    <Option value="other">Other</Option>

                                </Select>
                                <Select
                                    label="Which is the size of your organization (number of employees)?" size="lg" color="teal"
                                    animate={{ mount: { y: 0 }, unmount: { y: 25 }, }}
                                    className="flex items-center opacity-100 px-0 gap-2 bg-gray-50 text-gray-900 text-sm"
                                    value={""}
                                    placeholder="Choose a response"
                                    onChange={() => { }}
                                    disabled={false}
                                    variant="outlined"
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}>
                                    <Option value="1">1-10</Option>
                                    <Option value="11">11-50</Option>
                                    <Option value="51">51-200</Option>
                                    <Option value="201">201-500</Option>
                                    <Option value="501">501-1000</Option>                                    
                                    <Option value="1001">1000+</Option>
                                </Select>
                                <Select
                                    label="What is the geographical impact of your organization?" size="lg" color="teal"
                                    animate={{ mount: { y: 0 }, unmount: { y: 25 }, }}
                                    className="flex items-center opacity-100 px-0 gap-2 bg-gray-50 text-gray-900 text-sm"
                                    value={""}
                                    placeholder="Choose a response"
                                    onChange={() => { }}
                                    disabled={false}
                                    variant="outlined"
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}>
                                    <Option value="local">Local</Option>
                                    <Option value="national">National</Option>
                                    <Option value="international">International</Option>
                                </Select>
                                <Select
                                    label="Which is your organization type?" size="lg" color="teal"
                                    animate={{ mount: { y: 0 }, unmount: { y: 25 }, }}
                                    className="flex items-center opacity-100 px-0 gap-2 bg-gray-50 text-gray-900 text-sm"
                                    value={""}
                                    placeholder="Choose a response"
                                    onChange={() => { }}
                                    disabled={false}
                                    variant="outlined"
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}>
                                    <Option value="1">Private</Option>
                                    <Option value="11">Public</Option>
                                    <Option value="51">Government</Option>
                                    <Option value="201">NGO</Option>
                                    <Option value="501">Non-Profit</Option>
                                </Select>
                                <Select
                                    label="Is your organization critical to the functionning of the sector?" size="lg" color="teal"
                                    animate={{ mount: { y: 0 }, unmount: { y: 25 }, }}
                                    className="flex items-center opacity-100 px-0 gap-2 bg-gray-50 text-gray-900 text-sm"
                                    value={""}
                                    placeholder="Choose a response"
                                    onChange={() => { }}
                                    disabled={false}
                                    variant="outlined"
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}>
                                    <Option value="yes">Yes</Option>
                                    <Option value="no">No</Option>
                                </Select>
                            </div>
                        </form>
                    </>
                </Modal.Body>
                <Modal.Footer className="items-center justify-center text-center">
                    <>
                        <Button title="Save" icone={<FaCheck />} onClick={() => { }} />
                        <Button icone={<FaCircleXmark />} title="Cancel" color="error" onClick={() => { setEdit(false) }} />
                    </>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Component;
