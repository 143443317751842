import * as React from "react";
import Card from "../Card";
import { Pack } from "pages/Billing";

type Props = {
    totalTokens: number
    pack: Pack
};

const Component: React.FunctionComponent<Props> = ({ totalTokens, pack }) => {

    return (
        <Card title="Pack">
            <div className="flex w-full content-center justify-center">
                <div className="flex flex-col gap-4">
                    <div className="flex gap-x-4">
                        <div className="font-bold">
                            Package name:
                        </div>
                        <div>{pack.name}</div>
                    </div>
                    <div className="flex gap-x-4">
                        <div className="font-bold">Total Tokens:</div>
                        <div>{totalTokens} tkn</div>
                    </div>
                    <div className="flex gap-x-4">
                        <div className="font-bold">Price Tokens:</div>
                        <div>{totalTokens * pack.token_price} €</div>
                    </div>
                    <div className="flex gap-x-4">
                        <div className="font-bold">Total Price:</div>
                        <div>{totalTokens * pack.token_price + pack.price} €</div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default Component;