import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";
import { SimpleUSer } from "./userReducer";

type Organization = {
    slug: string;
    name: string;
    logo: string;
};

export type Audit = {
    id: number;
    organization: Organization;
    user: SimpleUSer;
    action: string;
    message: string;
    created_at: Date;
};

type AuditsState = {
    byId: { [id: number]: Audit };
    allIds: number[];
    loading: boolean;
    error: string | undefined;
};

const initialState: AuditsState = {
    byId: {},
    allIds: [],
    loading: false,
    error: undefined
};

export const getAudits = createAsyncThunk<AuditsState, void, { rejectValue: string }>(
    "audit/getAudits",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}audit/${state.user.user?.organization.slug}`, { headers });
            const data: any = await response.json();
            const audits: Audit[] = data.audits

            const uniqueAudits = audits.reduce((acc, audit) => {
                if (!acc.some(existingAudit => existingAudit.id === audit.id)) {
                    acc.push(audit);
                }
                return acc;
            }, [] as Audit[]);

            const normalizedData = {
                byId: uniqueAudits.reduce((acc, audit) => {
                    acc[audit.id] = audit;
                    return acc;
                }, {} as { [id: number]: Audit }),
                allIds: audits.map(audit => audit.id),
                loading: false,
                error: undefined,
            };

            return normalizedData;
        } catch (error) {
            return rejectWithValue("Failed to fetch audits.");
        }
    }
);

const auditSlice = createSlice({
    name: "audit",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAudits.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getAudits.fulfilled, (state, action) => {
                state.byId = action.payload.byId;
                state.allIds = action.payload.allIds;
                state.loading = false;
            })
            .addCase(getAudits.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const selectAuditById = (state: RootState, auditId: number) => state.auditState.byId[auditId];
export const selectAllAudits = createSelector(
    (state: RootState) => state.auditState.byId,
    (state: RootState) => state.auditState.allIds,

    (byId, allIds) => {

        if (!allIds || allIds.length === 0) {
            return [];
        }

        return allIds.map((id: number) => byId[id]);
    });

export default auditSlice.reducer;
