import * as React from "react";
import { Field } from "formik";

type Props = {
    name: string
    label: string
    type: "password"|"text"
    className?: string
};

const Component: React.FunctionComponent<Props> = ({ name, label, type, className="mt-4" }) => {

    return (
        <div className={className}>
            <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{label}</label>
            <Field name={name} type={type} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        </div>
    );

};

export default Component;
