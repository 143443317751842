import * as React from "react";

type Props = {
    content: React.ReactNode
}

const Component: React.FunctionComponent<Props> = ({ content }) => {

    return (
        <div className="relative px-6 pt-2 flex-auto bg-white">
            <p className="py-5 text-blueGray-500 text-lg leading-relaxed border-t border-b">
                {content}
            </p>
        </div>

    )
};

export default Component;
