import * as React from "react";
import { useEffect, useState } from "react";
import Card from "../Card";
import { FaCircleQuestion } from "react-icons/fa6";
import Button from "../Button";

//type Item = Record<string, any>;

type ColumnConfig<T extends Record<string, any>> = {
    header: string | React.ReactNode;
    accessor?: keyof T;
    render?: (value: any, item: T) => React.ReactNode;
    align?: 'left' | 'center' | 'right';
    width?: string;
    xsHidden?: boolean
};

type Props<T extends Record<string, any>> = {
    title: string | React.ReactNode;
    helpText?: string;
    items: T[];
    listSize?: number;
    search?: boolean;
    searchPlaceholder?: string;
    showStatusFilter?: boolean;
    statusAccessor?: keyof T;
    statusOptions?: string[];
    onFetchItems?: () => void;
    columns: ColumnConfig<T>[];
    customFilter?: (item: T, statusFilter: string) => boolean;
    statusFilterElement?: React.ReactNode;
    children?: React.ReactNode;
};

const TableCard = <T extends Record<string, any>>({
    title,
    helpText,
    items,
    listSize = 25,
    searchPlaceholder = "Search...",
    search = true,
    showStatusFilter = false,
    statusAccessor = "status",
    statusOptions = ["All"],
    onFetchItems,
    columns,
    customFilter,
    statusFilterElement,
    children
}: Props<T>) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [visibleCount, setVisibleCount] = useState<number>(listSize);
    const [statusFilter, setStatusFilter] = useState<string>("All");

    useEffect(() => {
        if (onFetchItems) {
            onFetchItems();
        }
    }, [onFetchItems]);

    const filteredItems = items ? items.filter((item) => {
        const matchesSearchTerm = Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const matchesStatus = customFilter
            ? customFilter(item, statusFilter)
            : statusFilter === "All" || item[statusAccessor]?.toString().toLowerCase() === statusFilter.toLowerCase();

        return matchesSearchTerm && matchesStatus;
    }) : [];

    const visibleItems = filteredItems.slice(0, visibleCount);

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + listSize);
    };

    return (
        <Card appendClass="w-full shadow-xl">
            <div className="w-full flex flex-col grow-2">
                <h2 className="w-full font-bold mb-4">
                    <div className="flex w-full justify-between">
                        <div>{title}</div>
                        {helpText && (
                            <div className="text-[#3b5998]">
                                <button className="font-normal flex flex-rows gap-1 items-center hover:underline">
                                    <FaCircleQuestion /> {helpText}
                                </button>
                            </div>
                        )}
                    </div>
                </h2>
                <div className="w-full">
                    <div className="mb-4">
                        {children}
                        <div className="mr-4 w-full bg-gray-200 rounded-t-lg border-l border-r border-t border-gray-400 flex pb-4 pt-4 items-end">
                            { search && <input
                                type="text"
                                className="w-full ml-2 mr-2 sm:ml-4 sm:w-1/2"
                                placeholder={searchPlaceholder}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />}
                        </div>

                        <div className="w-full bg-gray-200 border-l border-r border-b border-gray-400 pb-4 pt-4 flex justify-between">
                            <div className="ml-4">
                                {statusFilterElement || (showStatusFilter && (
                                    <select
                                        className="border rounded p-2"
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                    >
                                        {statusOptions.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                ))}
                            </div>
                            <div className="hidden sm:block mr-4 font-semibold">
                                Showing {visibleItems.length} of {filteredItems.length}
                            </div>
                        </div>
                        <div className="border-b border-r border-l border-gray-400">
                            <div className="flex gap-4 w-full mb-2">
                                {columns.map((col, index) => (
                                    <div key={index} className={`flex ${col.width || 'flex-grow'} ${col.xsHidden && 'hidden sm:block'} font-bold ml-2 mt-2 items-center text-${col.align || 'left'}`}>
                                        {col.header}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {visibleItems.map((item, index) => (
                            <div className="border-b border-r border-l border-gray-400" key={index}>
                                <div className="flex gap-4 w-full mb-2">
                                    {columns.map((col, colIndex) => (
                                        <div key={colIndex} className={`flex ${col.width || 'flex-grow'} ${col.xsHidden && 'hidden sm:block'} ml-2 mt-2 items-center text-${col.align || 'left'} ${col.align === 'right' && 'justify-end mr-2'}`}>
                                            {col.render ? col.render(col.accessor ? item[col.accessor] : undefined, item) : col.accessor ? item[col.accessor] : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {visibleCount < filteredItems.length && (
                            <div className="mr-4 w-full bg-gray-200 rounded-b-lg border-l border-r border-b border-gray-400 flex pb-4 pt-4 items-center">
                                <div className="text-center mt-4 w-full">
                                    <Button title="Show More" onClick={handleShowMore} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default TableCard;