import {
    setLogLevel as setClientSdkLogLevel,
    setLogExtension as setClientSdkLogExtension,
    LogLevel as LogLevelEnum,
} from './logger';
import loglevel from 'loglevel';

export const log = loglevel.getLogger('pg-components-js');
log.setDefaultLevel('DEBUG');

type LogLevel = Parameters<typeof setClientSdkLogLevel>[0];
type SetLogLevelOptions = {
    pgClientLogLevel?: LogLevel;
};

export function setLogLevel(level: LogLevel, options: SetLogLevelOptions = {}): void {
    log.setLevel(level);
    setClientSdkLogLevel(options.pgClientLogLevel ?? level);
}

type LogExtension = (level: LogLevel, msg: string, context?: object) => void;
type SetLogExtensionOptions = {
    pgClientLogExtension?: LogExtension;
};

export function setLogExtension(extension: LogExtension, options: SetLogExtensionOptions = {}) {
    const originalFactory = log.methodFactory;

    log.methodFactory = (methodName, configLevel, loggerName) => {
        const rawMethod = originalFactory(methodName, configLevel, loggerName);

        const logLevel = LogLevelEnum[methodName];
        const needLog = logLevel >= configLevel && logLevel < LogLevelEnum.silent;

        return (msg, context?: [msg: string, context: object]) => {
            if (context) rawMethod(msg, context);
            else rawMethod(msg);
            if (needLog) {
                extension(logLevel, msg, context);
            }
        };
    };
    log.setLevel(log.getLevel()); // Be sure to call setLevel method in order to apply plugin
    setClientSdkLogExtension(options.pgClientLogExtension ?? extension);
}