import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";

export type LLMGroup = {
    name: string;
    id: number;
};

type LLMGroupsState = {
    llmGroupList: LLMGroup[];
    loading: boolean;
    error: string | undefined;
};

const initialState: LLMGroupsState = {
    llmGroupList: [],
    loading: true,
    error: undefined
};

export const fetchLLMGroups = createAsyncThunk<LLMGroup[], void, { rejectValue: string }>(
    "llms/fetchLLMGroups",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = { 
                'Accept': 'application/json', 
                'Authorization': `Bearer ${state.user.user?.token}` 
            };
            const response = await fetch(`${API_URL}llms/groups`, { headers });
            const data: any =  await response.json();
            const llms: LLMGroup[] = data.llmGroups;
            return llms;
        } catch (error) {
            return rejectWithValue("Failed to fetch LLMs.");
        }
    }
);

const allLlmGroupsSlice = createSlice({
    name: "llmGroups",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //  arguments of action are same first one is state
        //& second one is action
        builder
            .addCase(fetchLLMGroups.fulfilled, (state, action) => {
                state.llmGroupList = action.payload;
                state.loading = false;
            })
            .addCase(fetchLLMGroups.pending, (state, action) => {
                state.loading = true;
                state.error = action.payload;
            })
    },
});

export const llmGroups = (state: RootState) => state.llmGroupsState.llmGroupList;
export default allLlmGroupsSlice.reducer;