import Button from "@components/Button";
import * as React from "react";

type Props = {
    content: React.ReactNode
    onClick: () => void
}

const Component: React.FunctionComponent<Props> = ({ content, onClick }) => {

    return (
        <div className="flex items-center justify-end p-6 border-solid border-blueGray-200 rounded-b">
            <Button color="error" onClick={onClick} title="Close" />
            {content}
        </div>

    )
};

export default Component;
