import * as React from "react";
import { useState, useEffect } from "react";
import { Select, Option } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { Model } from "../../../../redux/allLLMReducer";
import { getConfiguration, selectModels } from "../../../../redux/configurationReducer";
import { AppDispatch, useAppDispatch } from "../../../../redux";
import { useSelector } from "react-redux";


type Props = {
    setModel: (model: string | undefined) => void
    modelChoose?: string
}

const Component: React.FunctionComponent<Props> = ({ setModel, modelChoose = undefined }) => {

    /*const dispatch: AppDispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchLLMsForOrganization())
    },[dispatch])

    const llms = useSelector((state: RootState) => { 
        return state.llms.llmsList
    })*/
   
    const dispatch: AppDispatch = useAppDispatch();
    const llms = useSelector(selectModels);

    useEffect(() => {
        dispatch(getConfiguration())
    }, [dispatch])


    //const llms = useAppSelector(selectModels);

    const [value, setValue] = useState<string | undefined>(modelChoose)

    const params = useParams()

    const disabled: boolean = params.conversationId ? true : false;

    useEffect(() => {
        console.log(value)
        console.log("emptying select")
        setValue(modelChoose)
    }, [modelChoose, params.conversationId, value])

    return (
        <>
            {llms && <Select size="lg" color="teal"
                label="Choose a model"
                animate={{mount: { y: 0 },unmount: { y: 25 },}}
                className="flex items-center opacity-100 px-0 gap-2"
                value={value}
                placeholder="Choose a model"
                onChange={setModel}
                disabled={disabled}
                variant="static"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
                {llms && llms.map(({ name, id }: Model) => (
                    <Option key={id} value={name} className="flex items-center gap-2">
                        {/*<div className="h-5 w-5 rounded-full object-cover" dangerouslySetInnerHTML={{ __html: avatar }} />*/}
                        {name}
                    </Option>
                ))}
            </Select>}
        </>
    )
};

export default Component;