import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTrash } from "react-icons/fa6";
import { log } from "../../../../tools/log";
import { AppDispatch, useAppDispatch, useAppSelector } from "../../../../redux";
import { delConversation } from "../../../../redux/allConversationsReducer";
import { useState } from "react";
import { selectCurrentUser } from "../../../../redux/userReducer";

type Props = {
    title: string;
    slug: string;
};

const Component: React.FunctionComponent<Props> = ({ title, slug }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const dispatch: AppDispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);
    const location = useLocation();
    const [selectedClass, setSelectedClass] = React.useState<string>("");

    React.useEffect(() => {
        if (location.pathname === '/prompts/' + slug) {
            setSelectedClass("text-blue-500 hover:text-blue-700");
        } else {
            setSelectedClass("");
        }
    }, [location.pathname, slug]);

    const handleTrash = () => {
        setShowConfirmation(true)
    }

    const deleteConversation = () => {
        setShowConfirmation(false)
        log.debug(`delete conversation ${slug}`)
        dispatch(delConversation({ token: user?.token || "", conversation_id: slug }))
    }

    const closeConfirmation = () => {
        setShowConfirmation(false)
    }

    return (
        <div className="flex -ml-2  border-teal-400 hover:cursor-pointer hover:border-l-2 ">
            <div className="py-3 px-7 rounded-md text-ellipsis overflow-hidden truncate">
                <Link to={'/prompts/' + slug} className={"text-slate-500 hover:text-black" + selectedClass}>
                    {title}
                </Link>
            </div>
            <div>
                <button className="py-3 text-slate-500 hover:text-red-600 mr-3" onClick={handleTrash}>
                    <FaTrash />
                </button>
                {showConfirmation &&
                    <div className="fixed bg-white p-4 hover:shadow-2xl shadow-lg rounded-lg bubble -translate-x-6">
                        <div className="border-b pb-2 font-semibold">Are you sure ?</div>
                        <div className="flex justify-between pt-3">
                            <div className="px-2 py-1 rounded-md hover:text-blue-700" onClick={deleteConversation}>Yes</div>
                            <div className="px-2 py-1 rounded-md hover:text-red-500" onClick={closeConfirmation}>No</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Component;
