import * as React from "react";

import Card from "../../../../components/Card";

type Props = {
    title: string|React.ReactNode
    children: React.ReactNode
    appendClass?: string
};

const Component: React.FunctionComponent<Props> = ({ title, children, appendClass = '' }) => {

    return (
        <Card appendClass={`w-full shadow-xl ${appendClass}`}>
            <div className="w-full flex flex-col grow-2">
                <h2 className="w-full font-bold mb-4">
                    { title }
                </h2>
                <div className="w-full">
                    { children }
                </div>
            </div>
        </Card>
    )
}

export default Component;