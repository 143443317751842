import * as React from "react";
import { useAppSelector } from "../../redux";
import { selectCurrentUser, SimpleUSer } from "../../redux/userReducer";
import { API_URL } from "constant";
import ByDate from "./components/ByDate";
import ByUser from "./components/ByUser";
import ByUserByDate from "./components/ByUserByDate";
import PackComponent from "./components/Pack";
import H1 from "@components/Header/h1";

type Props = object

export type TokensByDate = {
    date: string
    tokens: number
}

export type TokensByUserByDate = {
    date: string
    user: SimpleUSer
    tokens: number
}

export type TokensByUser = {
    user: SimpleUSer
    tokens: number
}

export type Pack = {
    name: string
    price: number
    token_price: number
}

type Billing = {
    pack: Pack
    total_tokens: number
    tokens_by_date: TokensByDate[]
    tokens_by_user: TokensByUser[]
    tokens_by_user_by_date: TokensByUserByDate[]
}

const Component: React.FunctionComponent<Props> = () => {

    const user = useAppSelector(selectCurrentUser);
    const [billing, setBilling] = React.useState<Billing | undefined>(undefined)

    React.useEffect(() => {

        const computeBilling = async () => {
            try {
                const headers = {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                };
                const response = await fetch(`${API_URL}billing/${user.organization.slug}`, { headers });
                const data: any = await response.json();
                const billing: Billing = data.billing

                setBilling(billing)
            } catch (error) {
                return undefined
            }
        }
        computeBilling();
    }, [user.organization.slug, user.token])

    return (
        <div className="pl-3 pr-3 lg:p-4 lg:mt-14">
            <div className="flex flex-col gap-y-3 lg:gap-y-6">
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-start-2 lg:col-span-2">
                        <H1 text="My Billing" />
                    </div>
                </div>
                {/*<Tab items={tabItems} />*/}
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-start-2 lg:col-span-2">
                        {billing && <PackComponent totalTokens={billing.total_tokens} pack={billing.pack} />}
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-start-2 lg:col-span-2">
                        {billing && <ByDate datas={billing.tokens_by_date} />}
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-start-2 lg:col-span-2">
                        {billing && <ByUser datas={billing.tokens_by_user} />}
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-4">
                    <div className="lg:col-start-2 lg:col-span-2">
                        {billing && <ByUserByDate datas={billing.tokens_by_user_by_date} />}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Component;