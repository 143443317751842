import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ClickOutside from '../ClickOutside';
import UserOne from '../../images/user/user-01.png';
import { persistor, useAppDispatch, useAppSelector } from '../../redux';
import { logout, selectCurrentUser } from '../../redux/userReducer';
import { HiCog } from 'react-icons/hi2';
import { HiLogout } from 'react-icons/hi';
import { Modal } from 'flowbite-react';
import { FaUserCog } from 'react-icons/fa';
import { FaUserShield } from 'react-icons/fa6';
import Profile from "./components/profile";
import Security from "./components/security";

const DropdownUser = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(selectCurrentUser);
    const [screen, setScreen] = useState<string>("profile")
    const [isModalVisible, setModalVisible] = useState(false);

    const onLogout = () => {
        dispatch(logout());
        persistor.purge();
        navigate('/login');
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const getScreen = () => {
        if (screen === "profile") {
            return (<Profile closed={() => setModalVisible(false)}/>)
        }
        if (screen === "security") {
            return (<Security setScreen={setScreen} setModalVisible={setModalVisible} />)
        }
        return (<Profile closed={() => setModalVisible(false)}/>)
    }

    return (
        <>
        <ClickOutside onClick={() => setDropdownOpen(false)} className="relative">
            <Link
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center gap-4"
                to="#"
            >
                <span className="hidden text-right lg:block">
                    <span className="block text-sm font-medium text-black dark:text-white capitalize">
                        {user.firstname} {user.lastname}
                    </span>
                    <span className="block text-xs dark:text-white">{user.username}</span>
                </span>

                <span className="h-12 w-12 rounded-full">
                    <img src={UserOne} alt="User" />
                </span>

                <svg
                    className="hidden fill-current sm:block"
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                        fill=""
                    />
                </svg>
            </Link>

            {/* <!-- Dropdown Start --> */}
            {dropdownOpen && (
                <div
                    className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark`}
                >
                    <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
                        <li className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base mt-6 mb-4 cursor-pointer" onClick={() => setModalVisible(true)}>
                            <HiCog size={22} />
                            Settings
                        </li>
                    </ul>
                    <button className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base" onClick={() => onLogout()}>
                        <HiLogout size={22} />
                        Log Out
                    </button>
                </div>
            )}
            {/* <!-- Dropdown End --> */}
        </ClickOutside>
        <Modal show={isModalVisible} onClose={() => setModalVisible(false)} dismissible size="3xl" position={"center"} className='z-999999'>
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-end">
                        <HiCog className="text-xl" />
                        <div>
                            Settings
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                <div className="grid grid-cols-5">
                    <div className="flex flex-col">
                        <div className="flex items-center p-2 gap-3 cursor-pointer" onClick={()=>setScreen("profile")}>
                            <FaUserCog /> 
                            Profile
                        </div>
                        <div className="flex items-center p-2 gap-3 cursor-pointer" onClick={()=>setScreen("security")}>
                            <FaUserShield /> 
                            Security
                        </div>
                    </div>
                    <div className="col-span-4">
                        {getScreen()}
                    </div>
                </div>
            </Modal.Body>
            </Modal>
        </>
    );
};

export default DropdownUser;