import React from 'react';

type HeaderProps = {
    text: string;
};

const H1: React.FC<HeaderProps> = ({ text }) => {

    return (
        <h1 className="hidden font-mono font-medium mb-3 flex gap-4 w-full text-nowrap sm:block sm:justify-start sm:text-2xl">
            { text }
        </h1>
    );
};

export default H1;