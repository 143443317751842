import { ReportOrganization } from "pages/GRC";
import * as React from "react";
import Categories from "./categories";
import Recommended from "./recommended";
import Risk from "./risk";
import { useAppSelector } from "../../../../redux";
import { selectCurrentUser } from "../../../../redux/userReducer";
import { API_URL } from "constant";

type Props = {
    report: ReportOrganization | null
}

export type Category = {
    name: string;
    count: number;
    campaign?: Campaign;
}

export type Campaign = {
    id: number;
    theme: string;
    focus: string;
    activities: string;
}

const Component: React.FunctionComponent<Props> = ({ report }) => {

    const user = useAppSelector(selectCurrentUser);
    const [categories, setCategories] = React.useState<[string, Category][]>([]);

    React.useEffect(() => {

        const computeCampaign = async (category: string) => {

            try {
                const headers = {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                };
                const response = await fetch(`${API_URL}campaign/${category}`, { headers });
                const data: any = await response.json();
                const campaign: Campaign = data.campaign

                return campaign
            } catch (error) {
                return undefined
            }
        }

        const computeByPass = async () => {

            if (!report?.prompts.detail.byPass) {
                return;
            }

            const categoryCounts: Record<string, Category> = {};

            for (const bypass of report.prompts.detail.byPass) {
                for (const violation of bypass.violations) {
                    const { categorie, count } = violation;
                    if (categoryCounts[categorie]) {
                        categoryCounts[categorie].count += count
                    } else {
                        const campaign = await computeCampaign(categorie);
                        categoryCounts[categorie] = {
                            name: categorie,
                            count: count,
                            campaign: campaign
                        };
                    }
                }
            }

            const sorted = Object.entries(categoryCounts).sort((a, b) => b[1].count - a[1].count);
            setCategories(sorted);
        };

        computeByPass();
    }, [report, user?.token])

    return (
        <div className="pl-3 pr-3 xl:p-4 xl:mt-14">
            <div className="flex flex-col gap-y-3 xl:gap-y-6">
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Categories categories={categories} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Recommended categories={categories} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Risk categoriesMap={categories} violations={report?.prompts.usage.Violations} prompts={report?.prompts.usage.Prompts} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Component;