import * as React from "react";
import { API_URL } from "constant";
import { useAppSelector } from "../../../redux";
import { selectCurrentUser } from "../../../redux/userReducer";
import Generate from "./generate";
import QrCode from "./qrcode";

type Props = {
    setScreen: (screen:string)=>void
    setModalVisible: (visible:boolean)=>void
}

const Component: React.FunctionComponent<Props> = ({setScreen, setModalVisible}) => {

    const user = useAppSelector(selectCurrentUser);

    const [innerScreen, setInnerScreen] = React.useState<string>("generate")

    const [secret, setSecret] = React.useState({
        otpauth_url: "",
        base32: "",
    });

    const getScreen = () => {
        if (innerScreen === "generate") {
            return (<Generate generateQrCode={generateQrCode} user={user}/>)
        }
        if (innerScreen === "qrcode") {
            return (<QrCode otpauth_url={secret.otpauth_url} base32={secret.base32} user={user} setModalVisible={setModalVisible}/>)
        }
    }

    const generateQrCode = async () => {
        const headers = { 'Authorization': `Bearer ${user?.token}` }
        await fetch(`${API_URL}user/otp/generate`, { method: 'POST', headers: headers })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    setScreen("profile")
                }
                return response.json()
            })
            .then((data) => {
                setSecret({
                    base32: data.secret,
                    otpauth_url: data.auth_url,
                });
                setInnerScreen('qrcode')
            })
    }

    return (
        <div className="flex min-h-80 items-start">
            {getScreen()}
        </div>
    )
};

export default Component;