import Button from "./inputButton";
import * as React from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

export type InputType = "text" | "password"

type Props = {
    placeholder?: string;
    onValueChange: (value: string) => void;
    icon?: React.ReactNode;
    errorMessage?: string;
    submitButton?: boolean;
    labelSubmit?: string;
    type?: InputType;
    inputValue?:string;
    [x: string]: any;
}

const IconInput: React.FunctionComponent<Props> = ({ placeholder, onValueChange, inputValue, icon, errorMessage, submitButton = false, labelSubmit = 'Search', type="text", ...rest }) => {
    const [inputType, setInputType] = React.useState<InputType>(type);
    const [value, setValue] = React.useState<string>('');
    const [errorVisible, setErrorVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (errorMessage !== undefined) {
            setErrorVisible(true)
        }
    }, [errorMessage]);

    React.useEffect(() => {
        if (inputValue !== undefined) {
            setValue(inputValue)
        }
    }, [inputValue]);

    const handleBlur = () => {
        if (!submitButton) {
            onValueChange(value);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorVisible(false)
        setValue(event.target.value);
    };

    const handleSubmit = () => {
        onValueChange(value);
    };

    const toggleVisibility = () => {
        setInputType((prevType) => (prevType === "password" ? "text" : "password"));
    }

    return (
        <div className="flex flex-col w-full">
            <div className="flex">
                { icon && <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    {icon}
                </span>}
                <input type={inputType} {...rest}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`rounded-none ${!icon && 'border-e-0 rounded-s-md'} ${!submitButton && type!=="password" && 'rounded-e-lg'} bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder={placeholder} />
                {type === "password" && (
                    <span className={`inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 ${!submitButton && 'rounded-e-lg'} dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600`} onClick={()=>toggleVisibility()}>
                        {inputType === "password" ? <FaEye size={20}/> : <FaEyeSlash size={20}/>}
                    </span>
                )}
                {submitButton && (<Button title={labelSubmit} onClick={handleSubmit} />)}
            </div>
            {errorVisible && (
                <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
            )}
        </div>
    );
};

export default IconInput;