import * as React from "react";
import { cn } from "tools/cn";

export type Color = "primary" | "error"

type Props = {
    icone?: React.ReactNode;
    title?: string;
    onClick?: () => void;
    active?: boolean;
    color?: Color;
    isSubmit?:boolean
    isFullWidth?:boolean
    appendClass?:string[]
};

const Button: React.FunctionComponent<Props> = ({ icone, title, onClick, active = false, color="primary", isSubmit=false, isFullWidth=false, appendClass=[], ...rest }) => {

    const primaryColor = '[#3b5998]'
    const errorColor = 'red-800'

    const buttonColor = () => {
        if (color === 'primary') {
            return primaryColor
        }
        return errorColor
    }

    const inactiveClasses = [`text-${buttonColor()}`, 'hover:text-white', 'bg-white', `hover:bg-${buttonColor()}`]
    const activeClasses = [`hover:text-${buttonColor()}`, `bg-${buttonColor()}`, 'hover:bg-white', 'text-white']
    
    const buttonStateClasses = active ? activeClasses : inactiveClasses;
    
    const border = ['border', `border-${buttonColor()}`, 'p-2.5', 'rounded-lg']
    const fullClasses = isFullWidth ? 'w-full' : ''

    const buttonClasses = cn(buttonStateClasses, border, fullClasses, appendClass)

    return (
        <button type={isSubmit?"submit": "button"} className={buttonClasses} onClick={onClick} {...rest}>
            <div className="flex flex-cols gap-x-4 items-end justify-center">
                { icone && <div className="text-xl">{icone}</div>}
                {title}
            </div>
        </button>
    )
}

export default Button;