import * as React from "react";
import { IoIosSend } from "react-icons/io";

type Props = {
    disabled: boolean
    onSend: ()=>void
};

const Component: React.FunctionComponent<Props> = ({ disabled, onSend }) => {
    return (
        <button disabled={disabled} className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-black text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary" onClick={onSend}>
            <IoIosSend />
        </button>
    )
};

export default Component;