import * as React from "react";

import Tab from "../../components/Tab";
import { BiCategoryAlt } from "react-icons/bi";
import { TbReportSearch } from "react-icons/tb";
import { GiAwareness } from "react-icons/gi";

import Compliance from "./components/Compliance";
import Categories from "./components/Categories";
import Awareness from "./components/Awareness";
import { useAppSelector } from "../../redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../redux/userReducer";
import { log } from "../../tools/log";
import { API_URL } from "constant";
import H1 from "@components/Header/h1";

type Props = object;

type Organization = {
    slug: string;
    name: string;
}

type Violation = {
    ID: number;
    Name: string;
    Explanation: string;
    Slug: string;
}

export type ByCategoryAndSubCategory = {
    category: Violation;
    number: number;
}

export type RegulatoryViolation = {
    category: string;
    count: number;
    description: string;
    articles: string;
}

export type Regulatory = {
    title: string;
    description: string;
    protected: RegulatoryViolation[];
    byPass: RegulatoryViolation[];
}

export type Usage = {
    Prompts: number;
    Violations: number;
    Protected: number,
    Users: number,
}

type ByCategory = {
    count: number;
    categorie: string;
}

type Protected = {
    violations: ByCategory[];
    date: string;
}

type Detail = {
    protected: Protected[];
    byPass: Protected[];
}

type Prompts = {
    usage: Usage;
    byCategoryAndSubCategory: ByCategoryAndSubCategory[]
    regulations: Regulatory[];
    detail: Detail;
}

export type ReportOrganization = {
    start_date: string;
    end_date: string;
    organization: Organization;
    prompts: Prompts;
};

const Component: React.FunctionComponent<Props> = () => {
    
    const user = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();

    const [report, setReport] = React.useState<ReportOrganization | null>(null)

    React.useEffect(() => {
        const reportFetcher = async () => {
            log.debug('fetching report');
            const temp = new Date()
            temp.setDate(temp.getDate() + 1);
            const day = temp.getDate().toString().padStart(2, '0');
            const month = (temp.getMonth() + 1).toString().padStart(2, '0');
            const year = temp.getFullYear();
            const tomorrow = `${year}/${month}/${day}`;
            const headers = { 'Authorization': `Bearer ${user?.token}` }
            log.debug(`${API_URL}report/organization/${user?.organization.slug}`);
            log.debug(user)
            await fetch(`${API_URL}report/organization/${user?.organization.slug}?end=${tomorrow}`, { headers })
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        log.error(`Could not fetch token. Server responded with status ${response.status}: ${response.statusText}`)
                        navigate("/")
                    }
                    return response.json()
                })
                .then((data) => {
                    setReport(data);
                })
        };

        if (user?.token !== "") {
            reportFetcher();
        }
    }, [navigate, user, user?.token])

    const tabItems = [
        {
            title: 'Compliance',
            icone: <TbReportSearch className="mr-2" />,
            content: <Compliance report={report} />
        },
        {
            title: 'Categories',
            icone: <BiCategoryAlt className="mr-2" />,
            content: <Categories report={report} />,
        },
        {
            title: 'Awareness',
            icone: <GiAwareness className="mr-2" />,
            content: <Awareness report={report} />,
        },
    ];

    return (
        <div className="pl-3 pr-3 sm:p-4 sm:mt-14">
            {/* Tab header */}
            <H1 text="GRC" />
            <Tab items={tabItems} />
        </div>
    );
};

export default Component;
