import * as React from "react";

type Props = {
    placeholder?: string;
    onValueChange: (text: string) => void;
    onBlur?: (text: string) => void;
    id?: string;
    inputValue?: string;
    error?: boolean;
    [x: string]: any;
}

const TextInput: React.FunctionComponent<Props> = ({ placeholder, onValueChange, onBlur, id, inputValue, error, ...rest }) => {
    const [text, setText] = React.useState<string>('');

    React.useEffect(() => {
        if (inputValue !== undefined) {
            setText(inputValue)
        }
    }, [inputValue]);

    const handleChange = (value: string) => {
        setText(value);
        onValueChange(value);
    };

    const handleBur = (value: string) => {
        if (onBlur) {
            onBlur(value);
        }
    }

    return (
        <>
            <input
                id={id}
                type="text"
                value={text}
                onChange={(event) => handleChange(event.target.value)}
                onBlur={(event) => handleBur(event.target.value)}
                placeholder={placeholder}
                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${error !==undefined && error && "border-red-300"}`}
                {...rest}
            />
        </>
    );
};

export default TextInput;