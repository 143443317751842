import * as React from "react";

type Props = {
    label?: string;
    checked: boolean;
    onChange: (state:boolean) => void;
    labelClass?: string
};

const Toggle: React.FunctionComponent<Props> = ({ label, checked, onChange, labelClass = '' }) => {

    const [status, setStatus] = React.useState<boolean>(checked)

    React.useEffect(() => {
        setStatus(checked);
    }, [checked]);

    const ringClass = status ? "peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800" : "peer-focus:ring-red-300 dark:peer-focus:ring-red-800"

    const handleChange = () => {
        const newStatus = !status;
        setStatus(newStatus);
        onChange(newStatus);
    }

    return (
        <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" className="sr-only peer" checked={status} onChange={handleChange}/>
            <div 
                className={`relative w-11 h-6 bg-red-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-red-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-red-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-red-600 peer-checked:bg-[#3b5998] ${ringClass}`}
            ></div>
            {label && (
                <span className={`ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ${labelClass}`}>
                    { label }
                </span>
            )}
        </label>
    )
}

export default Toggle;