import * as React from "react";

type Props = {
    placeholder?: string;
    onEmailChange: (email: string) => void;
}

const EmailInput: React.FunctionComponent<Props> = ({ placeholder="Enter your email", onEmailChange }) => {
    const [email, setEmail] = React.useState<string>('');
    const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleBlur = () => {
        const isValid = validateEmail(email);
        setIsEmailValid(isValid);
        if (isValid) {
            onEmailChange(email);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        if (!isEmailValid) {
            setIsEmailValid(true);
        }
    };

    return (
        <>
            <input
                type="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                className={`w-full p-2 border ${!isEmailValid ? 'border-red-500' : 'border-gray-300'} rounded`}
            />
            {!isEmailValid && (
                <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
            )}
        </>
    );
};

export default EmailInput;