import * as React from "react";
import Card from "../Card";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { TokensByUserByDate } from "pages/Billing";

type Props = {
    datas: TokensByUserByDate[]
};

const parseDate = (dateStr: string): Date => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day - 1);
}

const findEarliestDate = (tokensList: TokensByUserByDate[]): string | null => {
    if (tokensList.length === 0) return null;

    return tokensList
        .map(item => item.date)
        .reduce((earliest, current) => {
            const currentDate = parseDate(current);
            const earliestDate = parseDate(earliest);
            return currentDate < earliestDate ? current : earliest;
        })
}

const findLatestDate = (tokensList: TokensByUserByDate[]): string | null => {
    if (tokensList.length === 0) return null;

    return tokensList
        .map(item => item.date)
        .reduce((latest, current) => {
            const currentDate = parseDate(current);
            const latestDate = parseDate(latest);
            return currentDate > latestDate ? current : latest;
        });
};

const getFirstDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getLastDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const transformData = (datas: TokensByUserByDate[]): { name: string; data: { x: number; y: number }[] }[] => {
    const usersMap: Record<string, { x: number, y: number }[]> = {};

    datas.forEach((dt) => {
        const dateTimestamp = parseDate(dt.date).getTime();
        if (!usersMap[dt.user.email]) {
            usersMap[dt.user.email] = [];
        }
        usersMap[dt.user.email].push({ x: dateTimestamp, y: dt.tokens });
    });

    return Object.keys(usersMap).map(user => ({
        name: user,
        data: usersMap[user]
    }));
};

const Component: React.FunctionComponent<Props> = ({ datas }) => {

    const earliestTmp = findEarliestDate(datas);
    const latestTmp = findLatestDate(datas);

    const earliestDate = earliestTmp ? parseDate(earliestTmp) : new Date();
    const latestDate = latestTmp ? parseDate(latestTmp) : new Date();

    const firstDayOfMonth = getFirstDayOfMonth(earliestDate);
    const lastDayOfMonth = getLastDayOfMonth(latestDate);

    const series = transformData(datas);

    const options: ApexOptions = {
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            height: 335,
            width: 800,
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                color: '#623CEA14',
                top: 10,
                blur: 4,
                left: 0,
                opacity: 0.1,
            },
        },
        responsive: [
            {
                breakpoint: 425,
                options: {
                    chart: {
                        height: 200,
                        width: 350
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    chart: {
                        height: 200,
                        width: 700
                    },
                },
            },
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        height: 200,
                        width: 800
                    },
                },
            },
            {
                breakpoint: 1366,
                options: {
                    chart: {
                        height: 250,
                        width: 800
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                columnWidth: 5,
            }
        },
        grid: {
            show: true
        },
        xaxis: {
            type: "datetime",
            min: firstDayOfMonth.getTime(),
            max: lastDayOfMonth.getTime(),
            tickAmount: Math.ceil((lastDayOfMonth.getTime() - firstDayOfMonth.getTime()) / (1000 * 60 * 60 * 24 * 5)),
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        }
    }

    return (
        <Card title="Used token by user by date">
            <div className="flex w-full content-center justify-center">
                <ReactApexChart options={options} type="bar" height={200} width={800} series={series} />
            </div>
        </Card>
    )
}

export default Component;