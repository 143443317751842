import * as React from "react";
import Recommandation from "./recommandation"
import Resource from "./resource"
import Security from "./security"
import { Detail } from "../../../../redux/reportUserReducer";
import { ReportOrganization } from "pages/GRC";

type Props = {
    report: ReportOrganization | null
};

export type SeriesItem = {
    categorie: string;
    protected: number;
    byPass: number;
};

const transformData = (detail: Detail): SeriesItem[] => {
    const series: SeriesItem[] = [];

    // Un objet pour faciliter l'accès et l'accumulation des données
    const violationsMap: { [key: string]: { protected: number; byPass: number } } = {};

    // Traiter les violations dans "protected"
    detail.protected.forEach(item => {
        item.violations.forEach(violation => {
            if (!violationsMap[violation.categorie]) {
                violationsMap[violation.categorie] = { protected: 0, byPass: 0 };
            }
            violationsMap[violation.categorie].protected += violation.count;
        });
    });

    // Traiter les violations dans "byPass"
    detail.byPass.forEach(item => {
        item.violations.forEach(violation => {
            if (!violationsMap[violation.categorie]) {
                violationsMap[violation.categorie] = { protected: 0, byPass: 0 };
            }
            violationsMap[violation.categorie].byPass += violation.count;
        });
    });

    // Construire la série à partir de la carte des violations
    for (const categorie in violationsMap) {
        const protectedCount = violationsMap[categorie].protected;
        const byPassCount = violationsMap[categorie].byPass;

        series.push({
            categorie,
            protected: protectedCount - byPassCount,
            byPass: byPassCount,
        });
    }

    return series;
};

const Component: React.FunctionComponent<Props> = ({ report }) => {

    const series: SeriesItem[] = report ? transformData(report.prompts.detail) : []

    return (
        <div className="pl-3 pr-3 xl:p-4 xl:mt-14">
            <div className="flex flex-col gap-y-3 xl:gap-y-6">
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Recommandation violations={report?.prompts.usage.Violations} blocked={report?.prompts.usage.Protected} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Resource series={series} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Security series={series} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Component;