import * as React from "react";
import QuestionAnswer from "./components/Messages";
import { AppDispatch, RootState, useAppDispatch } from "../../../../redux";
import { fetchMessages, Message } from "../../../../redux/allMessagesReducer";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/Spinner";

type Props = {
    conversationId: string|undefined
}

const Component: React.FunctionComponent<Props> = ({ conversationId }) => {
    
    const dispatch: AppDispatch = useAppDispatch();

    React.useEffect(()=>{
        if(conversationId){
            dispatch(fetchMessages(conversationId))
        }
    }, [conversationId, dispatch])

    const messages_list = useSelector((state: RootState) => state.messages.messagesList)

    return (
        <div className="w-full">
            {messages_list.map((message: Message)=> (
               <QuestionAnswer message={message} key={message.id}/>
            ))}
            {conversationId && !messages_list && <Spinner />}
        </div>
    )
};

export default Component;