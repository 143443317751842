import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState, useAppDispatch } from "../../redux";
import { cleanMessages } from "../../redux/allMessagesReducer";
import ConversationComponent from './components/Conversation';
import Conversations from "./components/Conversations";
import Interaction from "./components/Interaction";
import LLMs from "./components/LLM";
import { MdOutlineChromeReaderMode } from "react-icons/md";
import { Conversation } from "../../redux/allConversationsReducer";


type Props = object

/**
 * Le composant à la structure html suivante:
 * |-----------------------------------------------------------------|
 * | [models]            | [conversation                             |
 * | [conversations      | ...                                       |
 * | ...                 | ...                                       |
 * | ...                 | ]                                         |
 * | ]                   | [prompt]                                  |
 * |-----------------------------------------------------------------|
 * */
const Component: React.FunctionComponent<Props> = () => {

    const params = useParams()

    const dispatch: AppDispatch = useAppDispatch();

    const conversation_id = params.conversationId
    const conversations = useSelector((state: RootState) => state.conversations.conversationList)
    const conversation = conversations[conversations.findIndex((conversation: Conversation)=>conversation.conversation_id === conversation_id)]

    const [model, setModel] = React.useState<string|undefined>(undefined)

    React.useEffect(()=>{
        if (conversation) {
            setModel(conversation.model)
        } else {
            dispatch(cleanMessages())
            setModel(undefined)
        }
    }, [conversation, dispatch])

    return (
        <div className="p-4 mt-14">
            {/* Affichage d'un modal en cas d'erreurs */}
            
            <div className="lg:grid-cols-10 lg:grid lg:min-w-20 lg:h-full">
                <div className="lg:col-span-2">
                    {/* Choix du model et historiques des conversations */}
                    <div className="lg:fixed lg:w-64 lg:border-r lg:h-screen lg:pr-5">
                        <div className="mb-3">
                            <LLMs setModel={setModel} modelChoose={model}/>
                        </div>
                        <Conversations />
                    </div>
                </div>
                <div className="lg:col-span-8">
                    {/* Conversation en cours */}
                    <ConversationComponent conversationId={conversation_id} />
                </div>
            </div>
            <Interaction model={model}/>
        </div>
    )
};

export default Component;