import * as React from "react";
import { FaUserPlus, FaEnvelope } from "react-icons/fa6";
import Button from "@components/Button";
import { Modal } from "flowbite-react";

import EmailInput from "@components/Input/emailInput";
import { AppDispatch, useAppDispatch } from "../../../../redux";
import { inviteMember } from "../../../../redux/allMemberReducer";
import { useAlert } from "../../../../hooks/useAlert";

type Props = object

const Component: React.FunctionComponent<Props> = () => {

    const [email, setEmail] = React.useState<string>("");
    const [isModalVisible, setModalVisible] = React.useState(false);

    const { showAlert } = useAlert();

    const handleEmail = (email: string) => {
        setEmail(email);
    }

    const dispatch: AppDispatch = useAppDispatch();

    const addPerson = () => {
        if (email) {
            dispatch(inviteMember(email))
                .then((arg: unknown) => {
                    showAlert("Invitation sent successfully!", "success");
                    setModalVisible(false)
                })
                .catch((error: unknown) => {
                    showAlert("Failed to send invitation. Please try again.", "error");
                });
            // should dismiss modal
        } else {
            showAlert("Please enter a valid email address.", "warning");
        }
    }

    return (
        <>
            <Button icone={<FaUserPlus />} title="Add person" onClick={() => setModalVisible(true)} />
            <Modal show={isModalVisible} onClose={() => setModalVisible(false)} dismissible size="3xl" position={"center"} className="z-999999">
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-end">
                        <FaUserPlus className="text-xl" />
                        <div>
                            Add person
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                <>
                    <div className="text-justify m-4">
                        You are about to send an email to the user you wish to invite. The user will only be active once he or she has accepted the request and registered with ProxyGPT.
                    </div>
                    <form className="space-y-4" action="#">
                        <div className="w-full flex flex-col">
                            <EmailInput placeholder="Enter the email address of the user to invite" onEmailChange={handleEmail} />
                            <div className="w-full text-center mt-4">
                                <Button icone={<FaEnvelope />} title="Send Email" onClick={addPerson} />
                            </div>
                        </div>
                    </form>
                </>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default Component;