import * as React from "react";
import { Profile, SimpleUSer, User } from "../../../../redux/userReducer";
import Button from "@components/Button";
import { Modal } from "flowbite-react";
import { FaUser, FaEnvelope, FaRotate, FaCheck, FaCircleXmark } from "react-icons/fa6";
import { RiMailForbidFill } from "react-icons/ri";
import AutocompleteMultiple from "@components/Autocomplete/multiple";
import TextInput from "@components/Input/textInput";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../redux";
import { API_URL } from "../../../../constant";
import { deleteInvitation, updateUserThunk } from "../../../../redux/allMemberReducer";
import { useAlert } from "../../../../hooks/useAlert";

type ExtendedUser = SimpleUSer & { status: string };

type Props = {
    members: SimpleUSer[];
    invitations: SimpleUSer[];
    list_size: number;
}

type ProfileLabelMap = {
    [key: string]: string;
};

const profileLabels: ProfileLabelMap = {
    ORG_OWNER: "Organization administrator",
    REPORT_VIEWER: "Report viewer",
    FINANCIAL_VIEWER: "Financial viewer",
};

const getProfileKey = (label: string): string | undefined=> {
    return Object.keys(profileLabels).find(key => profileLabels[key] === label);
};

const Component: React.FunctionComponent<Props> = ({ members, invitations, list_size }) => {

    const [visibleMembersCount, setVisibleMembersCount] = React.useState<number>(list_size);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [statusFilter, setStatusFilter] = React.useState<string>("All");
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [selectedMember, setSelectedMember] = React.useState<ExtendedUser | undefined>(undefined);
    const [loginValid, setLoginValid] = React.useState<boolean|undefined>(undefined);

    const { showAlert } = useAlert();
    const user: User = useSelector((state: RootState) => state.user.user);

    const handleShowMore = () => {
        setVisibleMembersCount((prevCount) => prevCount + list_size);
    };

    const combinedList: ExtendedUser[] = [
        ...members.map(member => ({ ...member, status: "active" })),
        ...invitations.map(invitation => ({ ...invitation, status: "pending" }))
    ];

    const filteredList = combinedList.filter((user) => {
        const matchesSearchTerm = user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastname.toLowerCase().includes(searchTerm.toLowerCase())

        const matchesStatus =
            statusFilter === "All" || user.status === statusFilter.toLowerCase();

        return matchesSearchTerm && matchesStatus;
    });

    const sortedMembers = filteredList.sort((a, b) => a.email.localeCompare(b.email));
    const visibleMembers = sortedMembers.slice(0, visibleMembersCount);

    const dispatch: AppDispatch = useAppDispatch();

    const showProfile = (member: ExtendedUser) => {
        console.log(member)
        setSelectedMember(member)
        setModalVisible(true)
    }

    const getLabel = (profile: string): string => {
        return profileLabels[profile];
    };

    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (selectedMember && selectedMember.status === 'active' && selectedMember.profiles) {
            setSelectedOptions(selectedMember.profiles.map((profile) => getLabel(profile.name)))
        } else {
            setSelectedOptions([])
        }
    }, [selectedMember])

    const availableOptions = [
        'Organization administrator',
        'Report viewer',
        'Financial viewer',
    ];

    const loginExists = async (value: string) => {
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user?.token}`
            };
            const response = await fetch(`${API_URL}user/login/${value}`, { headers });
            if (response.status === 404) {
                setLoginValid(false);
            } else {
                setLoginValid(true);
            }
        } catch (error) {
            setLoginValid(false);
        }
    }

    const handleInputChange = (field: string, value: string) => {
        if (selectedMember) {
            setSelectedMember({...selectedMember, [field]: value });
        }
    };

    const updateUser = async () => {
        if (!selectedMember) {
            return;
        }

        const profiles = selectedOptions.map(option => {
            const profileKey = getProfileKey(option);
            return profileKey ? { name: profileKey } : null;
        })
        .filter((profile): profile is Profile => profile !== null);

        //const profiles = selectedOptions.map(option => {return {"name": option}})

        const updatedUserData = {
            id: selectedMember.id,
            username: selectedMember.username,
            firstname: selectedMember.firstname,
            lastname: selectedMember.lastname,
            email: selectedMember.email,
            profiles: profiles,
            image: "",
        };

        // Appel à l'action Redux pour mettre à jour l'utilisateur
        dispatch(updateUserThunk({
            userId: selectedMember.id,
            updatedUserData,
        }))
        .unwrap()
        .then(() => {
            // Fermer la modal après la mise à jour réussie
            setModalVisible(false);
        })
        .catch((error:any) => {
            console.error('Failed to update user:', error);
        });
    };

    const delInvitation = () => {
        if (selectedMember?.email) {
            dispatch(deleteInvitation(selectedMember?.email))
            .then((arg: unknown) => {
                showAlert("Invitation sent successfully!", "success");
                setModalVisible(false)
            })
            .catch((error: unknown) => {
                showAlert("Failed to send invitation. Please try again.", "error");
            });
        }
    }

    return (
        <>
            <Modal show={isModalVisible} onClose={() => setModalVisible(false)} dismissible size="3xl" position={"center"}>
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-end">
                        {selectedMember && selectedMember.status === 'active' ? <FaUser className="text-xl" /> : <FaEnvelope className="text-xl" />}
                        <div>
                            {selectedMember && selectedMember.status === 'active' ? ` Profile of ${selectedMember.username}` : `Invitation for ${selectedMember?.email}`}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form className="max-w-md mx-auto">
                        <div className="mb-5">
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                            <TextInput onValueChange={(value) => handleInputChange("username", value)} onBlur={loginExists} id="username" inputValue={selectedMember?.username} disabled={selectedMember?.status !== 'active' && selectedMember?.username !== ''} error={loginValid}/>
                        </div>
                        <div className="flex flex-cols gap-4">
                            <div className="mb-5 flex-1">
                                <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Firstname</label>
                                <TextInput onValueChange={(value) => handleInputChange("firstname", value)} id="firstname" inputValue={selectedMember?.firstname} disabled={selectedMember?.status !== 'active'} />
                            </div>
                            <div className="mb-5 flex-1">
                                <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lastname</label>
                                <TextInput onValueChange={(value) => handleInputChange("lastname", value)} id="lastname" inputValue={selectedMember?.lastname} disabled={selectedMember?.status !== 'active'} />
                            </div>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <TextInput onValueChange={(value) => handleInputChange("email", value)} id="email" inputValue={selectedMember?.email} disabled />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="profiles" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Profiles</label>
                            <AutocompleteMultiple options={availableOptions} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} disabled={selectedMember?.status !== 'active'} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="items-center justify-center text-center">
                    {selectedMember && selectedMember.status === 'active' ? 
                        <>
                            <Button title="Save" icone={<FaCheck />} onClick={() => updateUser()} /> 
                            <Button icone={<FaCircleXmark />} title="Cancel" color="error" />
                        </> : 
                        <>
                            <Button title="Resend Email" icone={<FaRotate />} /> 
                            <Button icone={<RiMailForbidFill />} title="Cancel Invitation" color="error" onClick={() => delInvitation()} />
                        </>}
                </Modal.Footer>
            </Modal>
            <div className="mr-4 w-full bg-gray-200 rounded-t-lg border-l border-r border-t border-gray-400 flex pb-4 pt-4 items-end">
                <input type="text" className="ml-4 mr-4 sm:mr-0 w-full sm:w-1/2" placeholder="Search for user by first name, email or username" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <div className="w-full bg-gray-200 border-l border-r border-b border-gray-400 pb-4 pt-4 flex justify-between w-full">
                <div className="ml-4">
                    <select className="border rounded p-2" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Pending">Pending</option>
                    </select>
                </div>
                <div className="mr-4 font-semibold">
                    Showing {visibleMembers.length} of {filteredList.length}
                </div>
            </div>
            <div className="border-b border-r border-l border-gray-400">
                <div className="flex gap-4 w-full mb-2">
                    <div className="w-1/4 ml-2 mt-2 font-bold">
                        Person & username
                    </div>
                    <div className="w-1/2 text-left mt-2 font-bold">
                        Email
                    </div>
                    <div className="w-1/4 text-center mr-2 mt-2 font-bold">
                        Status
                    </div>
                </div>
            </div>
            {visibleMembers.map((member, index) => {
                return (
                    <div className="border-b border-r border-l border-gray-400" key={index}>
                        <div className="flex gap-4 w-full mb-2">
                            <div className="w-1/4 ml-2 mt-2">
                                <div className="flex flex-col">
                                    <div className="font-semibold">
                                        {member.firstname} {member.lastname}
                                    </div>
                                    <div className="text-gray-600 text-sm">
                                        {member.username}
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 text-left mt-2 text-[#3b5998] hover:underline truncate" onClick={() => showProfile(member)}>
                                {member.email}
                            </div>
                            <div className="w-1/4 text-center mt-2 capitalize">
                                {member.status}
                            </div>
                        </div>
                    </div>
                )
            })}
            {visibleMembersCount < filteredList.length && (
                <div className="mr-4 w-full bg-gray-200 rounded-b-lg border-l border-r border-b border-gray-400 flex pb-4 pt-4 items-center">
                    <div className="text-center mt-4 w-full">
                        <Button title="Show More" onClick={handleShowMore} />
                    </div>
                </div>
            )}
        </>

    )
}

export default Component;