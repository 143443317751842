import * as React from "react";

type Props = {
    onChange: (message: string) => void;
    value?: string
};

const Component: React.FunctionComponent<Props> = ({ onChange, value }) => {
    
    const [rowsCount, setRowsCount] = React.useState<number>(1)

    const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value)
        setRowsCount((event.target.value.match(/\n/g)||[]).length + 1)
    }

    return (
        <div className="flex min-w-0 flex-1 flex-col">
            <textarea 
                color="text-gray-900" 
                className="m-0 resize-none border-0 overflow-hidden bg-transparent px-0 text-token-text-primary max-h-[25dvh] max-h-52 border-transparent focus:border-transparent focus:ring-0" 
                rows={rowsCount} 
                dir="auto" 
                placeholder="Enter a message"
                required
                onChange={onValueChange}
                defaultValue={value}
                />
        </div>
    )
};

export default Component;