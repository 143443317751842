import * as React from "react";

import { Question } from "../../../../../../redux/allMessagesReducer";
import { useAppSelector } from "../../../../../../redux";
import { selectCurrentUser } from "../../../../../../redux/userReducer";

import ToolBar from "../ToolBar";
import { diffWords } from "diff";
import { Tooltip } from "flowbite-react";

type Props = {
    question: Question
}

const Component: React.FunctionComponent<Props> = ({ question }) => {

    const user = useAppSelector(selectCurrentUser);

    const getHighlightedText = (original: string, modified: string) => {
        const diff = diffWords(original, modified);
        let lastRemoved = ''; // Stocke la dernière valeur supprimée

        return diff.map((part, index) => {
            if (part.removed) {
                // Stocke la valeur supprimée pour l'utiliser dans le prochain ajout
                lastRemoved = part.value;
                return null; // N'affiche pas le texte supprimé
            }

            if (part.added) {
                // Si une nouvelle valeur est ajoutée, affichez-la avec l'ancienne dans le tooltip
                return (
                    <div className="inline-flex">
                        <Tooltip key={index} content={lastRemoved}>
                            <span className="px-2 py-1 rounded-md bg-red-300/25">{part.value}</span>
                        </Tooltip>
                    </div>
                );
            }

            return <span key={index}>{part.value}</span>;
        });
    };

    return (
        <div className="lg:col-start-2 text-gray-800 rounded-md p-4">
            <div className="flex items-start gap-2.5">
                <div className="flex flex-col w-full lg:max-w-[calc(100%-16rem)] leading-1.5 p-4 border-gray-200 bg-green-100 rounded-b-xl rounded-l-xl dark:bg-gray-700">
                    <div className="flex items-center space-x-2 rtl:space-x-reverse">
                        <span className="text-sm font-semibold text-gray-900 dark:text-white">{user && user.username}</span>
                    </div>
                    <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white leading-7">
                        {getHighlightedText(question.prompt_original, question.prompt_send)}
                    </p>
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 italic w-full text-right">Delivered</span>
                    <ToolBar />
                </div>
                <div className="rounded-full w-10 h-10 bg-green-100 -translate-x-7"></div>
                <img className="w-8 h-8 rounded-full translate-y-1 -translate-x-20" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="" />
            </div>
        </div>
    )
};

export default Component;