import Card from "../Card"
import * as React from "react";
import { Category } from ".";
import { getIcone } from "icons/categories";

type Props = {
    categories: [string, Category][]
}

const Component: React.FunctionComponent<Props> = ({ categories }) => {

   
    return (
        <Card title="Overall categories - Top 10">
            <ul>
                {categories.map((category, index) => 
                    <ol>
                        <div className="flex gap-4">
                            {index + 1} - {getIcone(category[0], 24)} <span className="capitalize">{category[0]}</span>
                        </div>
                    </ol>
                )}
            </ul>
        </Card>
    )
};

export default Component;