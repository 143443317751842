import * as React from "react";
import Card from "../Card";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { TokensByUser } from "pages/Billing";

type Props = {
    datas: TokensByUser[]
};

const transformData = (datas: TokensByUser[]): { series: number[], labels: string[] } => {
    const series = datas.map(data => data.tokens);
    const labels = datas.map(data => data.user.email);
    return { series, labels };
}

const Component: React.FunctionComponent<Props> = ({ datas }) => {

    const { series, labels } = transformData(datas);

    const options: ApexOptions = {
        chart: {
            type: 'donut',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0
        },
        legend: {
            show: false
        },
        labels     
    }

    return (
        <Card title="Used token by user">
            <div className="flex w-full content-center justify-center">
                <ReactApexChart options={options} type="donut" height={200} width={800} series={series} />
            </div>
        </Card>
    )
}

export default Component;