import * as React from "react";

type Props = {
    title: string
}

const Component: React.FunctionComponent<Props> = ({ title }) => {

    return (
        <div className="flex items-start justify-between p-5 pl-7 pt-7 border-solid border-blueGray-200 rounded-t">
            <h3 className="text-15xl font-semibold text-black">
                {title}
            </h3>
        </div>

    )
};

export default Component;
