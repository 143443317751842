import { FaExclamationTriangle } from "react-icons/fa";
import { 
    FcBiotech, 
    FcBusinessman, 
    FcCurrencyExchange, 
    FcManager,
    FcPortraitMode, 
    FcSearch, 
    FcSimCard, 
    FcVoicePresentation, 
    FcDataEncryption, 
    FcCustomerSupport, 
    FcRules, 
    FcGraduationCap, 
    FcDeployment, 
    FcStatistics, 
    FcAssistant, 
    FcElectronics, 
    FcPrivacy, 
    FcFactory, 
    FcFinePrint, 
    FcGenealogy, 
    FcDiploma2, 
    FcDepartment 
} from "react-icons/fc";

export const getIcone = (category: string, size: number | string = 24): React.ReactNode => {
    switch (category) {
        case 'financial':
            return <FcCurrencyExchange size={size} />;
        case 'medical':
            return <FcBiotech size={size} />;
        case 'personal':
            return <FcManager size={size} />;
        case 'geolocalisation':
            return <FcSearch size={size} />;
        case 'telecommunication':
            return <FcSimCard size={size} />;
        case 'sensitive':
            return <FcDataEncryption size={size} />;
        case 'business':
            return <FcVoicePresentation size={size} />;
        case 'intellectual':
            return <FcPortraitMode size={size} />;
        case 'employee':
            return <FcBusinessman size={size} />;
        case 'customer':
            return <FcCustomerSupport size={size} />;
        case 'legal':
            return <FcRules size={size} />;
        case 'research':
            return <FcGraduationCap size={size} />;
        case 'operational':
            return <FcDeployment size={size} />;
        case 'marketing':
            return <FcStatistics size={size} />;
        case 'sales':
            return <FcAssistant size={size} />;
        case 'technical':
            return <FcElectronics size={size} />;
        case 'security':
            return <FcPrivacy size={size} />;
        case 'vendor':
            return <FcFactory size={size} />;
        case 'audit':
            return <FcFinePrint size={size} />;
        case 'project':
            return <FcGenealogy size={size} />;
        case 'educational':
            return <FcDiploma2 size={size} />;
        case 'government':
            return <FcDepartment size={size} />;
        default:
            return <FaExclamationTriangle size={size} color="red-500" />;
    }
};