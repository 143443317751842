import * as React from "react";

import { useParams } from "react-router-dom";
import { AppDispatch, RootState, useAppDispatch } from "../../../../redux";
import { addQuestion, fetchAnswer, PromptValue } from "../../../../redux/allMessagesReducer";
import Modal from "./components/Modal";
import Prompt from "./components/Prompt";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

type Props = {
    model ?: string
}

const Component: React.FunctionComponent<Props> = ({model}) => {

    const params = useParams();
    const dispatch: AppDispatch = useAppDispatch();
    const errors = useSelector((state: RootState) => state.messages.messagesList[state.messages.messagesList.length - 1]?.error)

    const [message, setMessage] = React.useState<string>();


    const sendMessage = (promptValue: PromptValue) => {
        const conversation_id = params.conversationId

        if (model === undefined) {
            setMessage(promptValue.message_original)
            toast.error("Choose a model")
            return
        }

        const data = {
            "llm": model,
            "prompt": promptValue.message,
            "original_prompt": promptValue.message_original,
            "conversation_id": conversation_id,
            "protected": promptValue.protected,
            "reason": promptValue.reason,
            "prompt_id": promptValue.prompt_id
        }
        
        dispatch(addQuestion(data))
        dispatch(fetchAnswer(data))
    }
    
    const onClose = (msg: string) => {
        // Should remove last QuestionAnswer
        setMessage(msg)
    }
    return (
        <>
            {errors && errors.errors && (<Modal prompt={errors.errors.prompt} violations={errors.errors.violations} onSend={sendMessage} onClose={onClose} prompt_id={errors.errors.prompt_id} />)}
            <div className="flex bottom-0 h-20 w-full"></div>
            <div className="fixed bottom-0 w-full">
                <div className="h-full w-full lg:w-1/2 lg:mx-auto font-medium pb-4 pr-4">
                    <Prompt onSend={sendMessage} message={message} />
                </div>
            </div>
        </>
    )
};

export default Component;