import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux";
import { Modal } from "flowbite-react";
import Button from "@components/Button";
import { FaKey, FaTrashCan, FaPencil, FaCheck } from "react-icons/fa6";
import TableCard from "@components/TableCard";
import { fetchKeys, getKeys, Key } from "../../../../redux/allKeyReducer";
import { useSelector } from "react-redux";
import IconInput from "@components/Input/iconInput";
import { User } from "redux/userReducer";
import axios from "axios";
import { API_URL } from "constant";
import { validateLLMs } from "../../../../redux/configurationReducer";

type Props = object

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useDispatch<AppDispatch>()
    const user: User = useSelector((state: RootState) => state.user.user);
    const keysList = useSelector(getKeys);
    const [disabled, setDisabled] = React.useState<boolean>(true)
    const [token, setToken] = React.useState<string>("")

    React.useEffect(() => {
        dispatch(fetchKeys())
    }, [dispatch])


    const [isModalVisible, setModalVisible] = React.useState(false);

    const delKey = async (key: Key) => {
        const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
        };
        const response = await axios.delete(`${API_URL}llm/group/${key.llm.id}/key`, { headers });
        if (response.status === 200) {

        } else {
            console.log(response.data)
        }
        dispatch(validateLLMs())
    }

    const editKey = async (key: Key) => {
        const headers = {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.token}`
        };
        const response = await axios.put(`${API_URL}llm/group/${key.llm.id}/key`, { "token": token }, { headers });
        if (response.status === 200) {

        } else {
            console.log(response.data)
        }
        dispatch(validateLLMs())
        setDisabled((prev) => !prev)
    }

    return (
        <>
            <Button icone={<FaKey />} title="Keys" onClick={() => setModalVisible(true)} appendClass={['ml-2']} />
            <Modal show={isModalVisible} onClose={() => setModalVisible(false)} dismissible size="3xl" position={"center"} className="z-999999">
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-end">
                        <FaKey className="text-xl" />
                        <div>
                            Keys
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full flex flex-col space-y-4">
                        <TableCard
                            title="Keys"
                            search={false}
                            items={keysList}
                            listSize={10}
                            showStatusFilter={false}
                            searchPlaceholder="Search for key"
                            columns={[
                                {
                                    header: "Token",
                                    accessor: "key",
                                    width: "w-full",
                                    render: (value, item) => <IconInput inputValue={value} type="password" onValueChange={(token) => {setToken(token)}} disabled={disabled} />
                                },
                                {
                                    header: "LLM",
                                    accessor: "llm",
                                    width: "w-24",
                                    render: (value, item) => value.name
                                },
                                {
                                    header: "",
                                    width: "w-16",
                                    align: "right",
                                    render: (value, item) =>
                                        <>
                                            {disabled && <FaPencil color="#3b5998" size={24} onClick={() => setDisabled((prev) => !prev)} />}
                                            {disabled && <FaTrashCan color="red" size={24} onClick={() => delKey(item)} className="ml-2" />}
                                            {disabled || <FaCheck color="green" size={24} onClick={() => editKey(item)} className="ml-2" />}
                                        </>

                                }
                            ]}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default Component;