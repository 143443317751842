import * as React from "react";

import SecureScore from "./securescore";
import Compliance from "./compliance";
import RegulatoryComponent from "./regulatory";
import { ReportOrganization } from "pages/GRC";


type Props = {
    report: ReportOrganization | null
}

const Component: React.FunctionComponent<Props> = ({ report }) => {

    return (
        <div className="pl-3 pr-3 xl:p-4 xl:mt-14">
            <div className="flex flex-col gap-y-3 xl:gap-y-6">
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        {report && <SecureScore violations={report.prompts.usage.Violations} total={report.prompts.usage.Violations + report.prompts.usage.Protected} />}
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        {report && report.prompts.regulations && <Compliance report={report} />}
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <RegulatoryComponent regulations={report?.prompts.regulations} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Component;