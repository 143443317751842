import React, { useState } from 'react';

interface AutocompleteMultipleProps {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
  disabled?: boolean;
}

const AutocompleteMultiple: React.FC<AutocompleteMultipleProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  disabled=false
}) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const filtered = options.filter(
        (option) =>
          option.toLowerCase().includes(value.toLowerCase()) &&
          !selectedOptions.includes(option)
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleAddOption = (option: string) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
      setInputValue('');
      setFilteredOptions([]);
    }
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = selectedOptions.filter((_, i) => i !== index);
    setSelectedOptions(updatedOptions);
  };

  return (
    <div className="w-full max-w-md">
      <div className="flex flex-wrap items-center border p-2 rounded-md">
        {selectedOptions.map((option:string, index:number) => (
          <div
            key={index}
            className="flex items-center bg-blue-500 text-white px-2 py-1 rounded-full mr-2 mb-2"
          >
            {option}
            <button
              type="button"
              className="ml-2 focus:outline-none"
              onClick={() => handleRemoveOption(index)}
              disabled={disabled}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className="flex-1 border-none outline-none p-1 mb-2"
          placeholder="Type to search..."
          disabled={disabled}
        />
      </div>
      {filteredOptions.length > 0 && (
        <ul className="border mt-2 rounded-md overflow-hidden max-h-40 overflow-y-auto">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleAddOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompleteMultiple;
