import * as React from "react";
import { FiPaperclip } from "react-icons/fi";

type Props = object;

const Component: React.FunctionComponent<Props> = () => {
    return (
        <div className="flex flex-col">
            <button type="button" className="flex items-center justify-center text-token-text-primary h-8 w-8 dark:text-white rounded-full focus-visible:outline-black dark:focus-visible:outline-white mb-1 ml-1.5">
                <FiPaperclip />
            </button>
        </div>
    )
};

export default Component;