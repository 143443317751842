import * as React from "react";
import Card from "../Card"
import { FaShieldVirus } from "react-icons/fa6";
import ChartOneBar from "../../../../components/ChartOneBar";

type Props = {
    violations: number
    total: number
};

const Component: React.FunctionComponent<Props> = ({ violations, total }) => {

    return (

        <Card title="Overall score">
            <div className="flex flex-row items-end">
                <FaShieldVirus className="text-9xl text-teal-800" />
                <div className="ml-6">
                    <span className="text-2xl font-semibold">{violations}</span> OF {total}
                </div>
            </div>
            <ChartOneBar firstElement={violations} totalElements={total} />
        </Card>
    )
}

export default Component;