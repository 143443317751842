import * as React from "react";
import Card from "../Card";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { TokensByDate } from "pages/Billing";

type Props = {
    datas: TokensByDate[]
};

const parseDate = (dateStr: string): Date => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day - 1);
}

const findEarliestDate = (tokensList: TokensByDate[]): string | null => {
    if (tokensList.length === 0) return null;

    return tokensList
        .map(item => item.date)
        .reduce((earliest, current) => {
            const currentDate = parseDate(current);
            const earliestDate = parseDate(earliest);
            return currentDate < earliestDate ? current : earliest;
        })
}

const findLatestDate = (tokensList: TokensByDate[]): string | null => {
    if (tokensList.length === 0) return null;

    return tokensList
        .map(item => item.date)
        .reduce((latest, current) => {
            const currentDate = parseDate(current);
            const latestDate = parseDate(latest);
            return currentDate > latestDate ? current : latest;
        });
};

const getFirstDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getLastDayOfMonth = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const transformData = (datas: TokensByDate[]): number[][] => {
    return datas.map((data) => [parseDate(data.date).getTime(), data.tokens])
}

const Component: React.FunctionComponent<Props> = ({ datas }) => {

    const earliestTmp = findEarliestDate(datas);
    const latestTmp = findLatestDate(datas);

    const earliestDate = earliestTmp ? parseDate(earliestTmp) : new Date();
    const latestDate = latestTmp ? parseDate(latestTmp) : new Date();

    const firstDayOfMonth = getFirstDayOfMonth(earliestDate);
    const lastDayOfMonth = getLastDayOfMonth(latestDate);

    const series = [{ name: "Tokens", data: transformData(datas) }]

    const options: ApexOptions = {
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            height: 335,
            width: 800,
            type: 'area',
            toolbar: {
                show: false
            },
            dropShadow: {
                enabled: true,
                color: '#623CEA14',
                top: 10,
                blur: 4,
                left: 0,
                opacity: 0.1,
            },
        },
        responsive: [
            {
                breakpoint: 425,
                options: {
                    chart: {
                        height: 200,
                        width: 350
                    },
                },
            },
            {
                breakpoint: 768,
                options: {
                    chart: {
                        height: 200,
                        width: 700
                    },
                },
            },
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        height: 200,
                        width: 800
                    },
                },
            },
            {
                breakpoint: 1366,
                options: {
                    chart: {
                        height: 250,
                        width: 800
                    },
                },
            },
        ],
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: "datetime",
            min: firstDayOfMonth.getTime(),
            max: lastDayOfMonth.getTime(),
            tickAmount: Math.ceil((lastDayOfMonth.getTime() - firstDayOfMonth.getTime()) / (1000 * 60 * 60 * 24 * 5)),
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        legend: {
            show: false
        }
    }

    return (
        <Card title="Used token by date">
            <div className="flex w-full content-center justify-center">
                <ReactApexChart options={options} height={250} width={800} type="area"series={series} />
            </div>
        </Card>
    )
}

export default Component;