import * as React from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

type Props = {
    title: string
    content: React.ReactNode
    footer: React.ReactNode
    onCloseClick?:()=>void
}

const Component: React.FunctionComponent<Props> = ({ title, content, footer, onCloseClick = ()=>{} }) => {

    const [showModal, setShowModal] = React.useState(true);

    const handleClose = () => {
        setShowModal(false);
        onCloseClick();
    }

    return (
        <>
            {showModal && (
                <>
                    <div
                        className="text-black justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-3xl shadow-2xl relative flex flex-col w-full outline-none focus:outline-none bg-white">
                                <Header title={title} />
                                {/*body*/}
                                <Content content={content} />
                                {/*footer*/}
                                <Footer content={footer} onClick={handleClose} />
                            </div>
                        </div>
                    </div>
                    <div className="text-3xl fixed inset-0 z-40 bg-black/50 backdrop-blur-sm">
                    </div>
                </>
            )}
        </>

    )
};

export default Component;
