import * as React from "react";

type Props = {
    children?: React.ReactNode
    appendClass ?:string
};

const Component: React.FunctionComponent<Props> = ({ children, appendClass = "" }) => {

    return (
        <div className={`p-6 sm:rounded-lg border bg-white shadow-md ${appendClass} dark:bg-zinc-400`}>
            {children}
        </div>
    )
}

export default Component;