import * as React from "react";

type Props = {
    icone?: React.ReactNode;
    title?: string;
    onClick?: () => void;
    active?: boolean;
};

const Component: React.FunctionComponent<Props> = ({ icone, title, onClick, active = false, ...rest }) => {

    const buttonClass = active ? "bg-[#3b5998] text-white hover:bg-white hover:text-[#3b5998]" : "text-[#3b5998] bg-white hover:bg-[#3b5998] hover:text-white";
    
    return (
        <button type="button" className={`${buttonClass} border border-[#3b5998] p-2.5 rounded-e-lg`} onClick={onClick} {...rest}>
            <div className="flex flex-cols gap-x-4 items-end">
                { icone && <div className="text-xl">{icone}</div>}
                {title}
            </div>
        </button>
    )
}

export default Component;