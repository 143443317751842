import * as React from "react";
import Question from "./question";
import Response from "./response";
import { Message } from "../../../../../../redux/allMessagesReducer";

type Props = {
    message: Message
}

const Component: React.FunctionComponent<Props> = ({ message }) => {

    return (
        <div className="w-full lg:grid lg:grid-cols-2">
            <Question question={message.question} />
            <Response response={message.response} loading={message.loading}/>
        </div>
    )
};

export default Component;