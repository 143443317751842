import { SeriesItem } from ".";
import Card from "../Card";
import ChartOneBar from "../../../../components/ChartOneBar";
import { getIcone } from "../../../../icons/categories";

type Props = {
    series: SeriesItem[]
};
  

const Component: React.FunctionComponent<Props> = ({ series }) => {

    const getTopCategories = (datas: SeriesItem[], topN: number = 4): SeriesItem[] => {
        
        const sortedData = datas.sort((a, b) => {
            if (b.byPass !== a.byPass) {
                return b.byPass - a.byPass;
            }
            return b.protected - a.protected;
        });

        // Retourner seulement les topN résultats
        return sortedData.slice(0, topN);
    };

    const violationsByCategory = getTopCategories(series);

    return (
        <Card title="Category status">
            <div className="grid grid-cols-2 justify-center gap-3 sm:grid-cols-4 sm:gap-6">
                {violationsByCategory && violationsByCategory.map((regulation, index) => {
                    return (
                        <div className="flex" key={index}>
                            {
                                getIcone(regulation.categorie, 48)
                            }
                            <div className="flex flex-col">
                                <div className="text-nowrap">
                                    <span className="font-bold">{regulation.byPass + regulation.protected}</span> <span className="capitalize truncate">{regulation.categorie}</span>
                                </div>
                                <ChartOneBar firstElement={regulation.byPass} totalElements={regulation.byPass + regulation.protected} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </Card>
    )
}

export default Component;