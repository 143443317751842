import * as React from "react";
import { Category } from ".";
import Card from "../Card"
import { getIcone } from "icons/categories";

type Props = {
    categoriesMap: [string, Category][]
    violations: number | undefined
    prompts: number | undefined
}

const MAX_CATEGORIES = 22;

const Component: React.FunctionComponent<Props> = ({ categoriesMap, violations, prompts }) => {

    const limitedCategoriesMap = categoriesMap.slice(0, MAX_CATEGORIES);

    const paddedCategoriesMap = [
        ...limitedCategoriesMap,
        ...Array(MAX_CATEGORIES - limitedCategoriesMap.length).fill(["", { name: "Empty", count: 0 }])
    ];

    const categories = paddedCategoriesMap.map(([_, category]) => category);

    const calculateRisk = () => {
        const totalWeight = categories.reduce((sum, category) => sum + category.count, 0);
        return categories.map((category) => ({
            name: category.name,
            count: category.count,
            risk: totalWeight > 0 ? ((category.count / totalWeight) * 100).toFixed(2) : "0.00", // Pourcentage de risque pour chaque catégorie
        }));
    };

    const risks = calculateRisk();

    return (
        <Card title="Risk Reduction">
            <ul>
                {risks.map((category, index) =>
                    <>
                        {category.risk !== '0.00' &&
                            <ol key={index}>
                                <div className="flex gap-4">
                                    {index + 1} - {getIcone(category.name, 24)} <span className="capitalize font-bold">{category.name} : </span><span>{category.risk} %</span>
                                </div>
                            </ol>
                        }
                    </>
                )}
            </ul>
        </Card>
    );
};

export default Component;