import * as React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { selectCurrentUser } from "../../../../redux/userReducer";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppSelector } from "../../../../redux";
import Button from "@components/Button";

type Props = {
    validate2fa: (token: string) => void
    backToLogin: ()=>void
};

type MFAInput = {
    token: string
}

const Component: React.FunctionComponent<Props> = ({ validate2fa, backToLogin }) => {

    const navigate = useNavigate();
    const user = useAppSelector(selectCurrentUser);

    const {
        handleSubmit,
        setFocus,
        register,
        formState: { errors },
    } = useForm<MFAInput>();


    const onSubmitHandler: SubmitHandler<MFAInput> = (values) => {
        console.log(values)
        validate2fa(values.token);
    };

    React.useEffect(() => {
        setFocus("token");
    }, [setFocus]);

    React.useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [navigate, user]);

    return (
        <>
            <h2 className="invisible text-lg text-center mb-4 text-ct-dark-200 md:visible">
                Verify the Authentication Code
            </h2>
            <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="max-w-md w-full mx-auto overflow-hidden md:shadow-lg md:bg-ct-dark-200 md:rounded-2xl p-8 space-y-5 md:bg-white"
            >
                <h2 className="text-center text-3xl font-semibold text-[#142149]">
                    Two-Factor Authentication
                </h2>
                <p className="text-center text-sm">
                    Open the two-step verification app on your mobile device to get your
                    verification code.
                </p>
                <input {...register("token")} placeholder="Authentication Code" type="text" className='block w-full rounded-lg sm:rounded-2xl appearance-none focus:outline-none py-2 px-4' inputMode="numeric"/>
                <p className="mt-2 text-xs text-red-600">
                    {errors.token ? errors.token.message : null}
                </p>
                <Button isSubmit title="Authenticate" isFullWidth />
                <span className="text-[#0b5cad] hover:underline block text-right cursor-pointer" onClick={() => backToLogin()}>
                        Back to basic login
                </span>
            </form>
        </>
    );

};

export default Component;
