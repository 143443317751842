import * as React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login"
import Register from "./pages/Register"
import Interact from "./pages/Interact"
import Billing from "./pages/Billing"
import GRC from "./pages/GRC"
import Organization from "./pages/Organization"
import ProtectedRoute from './components/ProtectedRoute';
import RegisterOrganization from './pages/RegisterOrganization'
import Dashboard from './pages/Dashboard';
import Layout from './layout/Layout';
import PageTitle from '@components/PageTitle';

function App() {

  return (

    <Routes>
      <Route element={<Layout />} >
        <Route path="/" element={
          <>
            <PageTitle title="Dashboard | ProxyGPT" />
            <ProtectedRoute element={Dashboard} />
          </>
          } />
        <Route path="/prompts/:conversationId?" element={<ProtectedRoute element={Interact} />} />
        <Route path="/organization" element={<ProtectedRoute element={Organization} requiredProfile="ORG_OWNER"/>} />
        <Route path="/grc" element={<ProtectedRoute element={GRC} requiredProfile="REPORT_VIEWER" />} />
        <Route path="/billing" element={<ProtectedRoute element={Billing} requiredProfile="FINANCIAL_VIEWER" />} />
      </Route>

      <Route path="/login" element={<Login />} />
      
      <Route path="/register" element={<Register />} />
      <Route path='/register/organization' element={<RegisterOrganization />}></Route>
    </Routes>
  );
}

export default App;
