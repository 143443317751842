import * as React from "react";

type Props = {
    firstElement: number
    totalElements: number
    redTooltip?: string
};

const ChartOneBar: React.FunctionComponent<Props> = ({ firstElement, totalElements, redTooltip }) => {

    const violationPercentage = (firstElement / totalElements) * 100;
    const safePercentage = 100 - violationPercentage;

    const redTitle = redTooltip ? redTooltip : `By passes Elements: ${firstElement}`;

    return (
        <div>
            <div className="h-3 bg-green-500 rounded-lg overflow-hidden mt-4">
                <div
                    className="h-full bg-red-500"
                    style={{ width: `${violationPercentage}%` }}
                    title={redTitle}
                ></div>
                <div
                    className="h-full bg-emerald-700"
                    style={{ width: `${safePercentage}%` }}
                    title={`Safe Elements: ${totalElements - firstElement}`}>
                </div>
            </div>
            <div className="text-xs lg:hidden">
                {redTitle}
            </div>
        </div>
    )
}

export default ChartOneBar;