import * as React from "react";
import { API_URL } from "constant";
import { toast } from "react-toastify";
import Authenticate from "./components/Authenticate";
import Validate from "./components/ValidateOtp";
import { login, User } from "../../redux/userReducer";
import { useAppDispatch } from "../../redux";
import { useNavigate } from "react-router-dom";

type Props = object;

export type LoginInput = {
    email: string,
    password: string
}

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useAppDispatch();

    const [user, setUser] = React.useState<User>({ authenticated: false, bio: "", email: "", firstname: "", id: 0, image: "", lastname: "", mfa: false, organization: { id: 0, members: [], name: '', owner: { email: "", firstname: "", id: 0, image: "", lastname: "", username: "" }, slug: "" }, profiles: [], token: "", username: "", usage: { details: [], prompts: 0, violations: 0 } })

    const [screen, setScreen] = React.useState<"authenticate" | "otp">("authenticate")

    const navigate = useNavigate()

    const loginUser = async (data: LoginInput) => {
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        try {
            let response = await fetch(`${API_URL}user/login`, { method: 'POST', headers: headers, body: JSON.stringify({ "user": data }) })
            if (!response.ok) throw response.statusText;
            const responseData = await response.json();
            const headers_me = { 'Accept': 'application/json', 'Authorization': `Bearer ${responseData.token}` }
            const response_me = await fetch(API_URL + 'user/me', { headers: headers_me });
            if (!response_me.ok) throw response.statusText;
            const data_me = await response_me.json();
            data_me.user.token = responseData.token
            setUser(data_me.user)
            if (data_me.user.mfa) {
                setScreen("otp")
                return
            }
            console.log("No mfa")
            dispatch(login(data_me.user));

            navigate("/");
        } catch (e: any) {
            toast.error(e, { position: "top-right" });
        }
    }


    const validate2fa = async (token: string) => {
        console.log(token)
        const headers = { 'Authorization': `Bearer ${user?.token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' }
        try {
            let response = await fetch(`${API_URL}user/otp/validate`, { method: 'POST', headers: headers, body: JSON.stringify({ "otp": { "token": token, "user_id": user.id } }) })
            if (!response.ok) throw response.statusText;
            toast.success("Authentication successfully", { position: "top-right" });
            dispatch(login(user));
            navigate("/")
        } catch (e: any) {
            toast.error(e, { position: "top-right" });
            navigate("/login")
        }
    };

    const getScreen = () => {
        if (screen === "authenticate") {
            return (<Authenticate loginUser={loginUser} />)
        }
        return user ? (<Validate validate2fa={validate2fa} backToLogin={()=>setScreen("authenticate")}/>) : (<Authenticate loginUser={loginUser} />)
    }


    return (
        <section className="bg-ct-blue-600 min-h-screen grid place-items-center bg-[#E8FAF6]">
            <div className="w-full">
                <div className="flex justify-center items-center">
                    <picture>
                        <source srcSet="/images/logo_seul.png" media="(max-width: 639px)"/>
                        <source srcSet="/images/logo.png" media="(min-width: 640px)"/>
                        <img src="/images/logo.png" alt="proxygpt"/>
                    </picture>
                </div>
                <h1 className="text-4xl lg:text-6xl text-center font-[600] text-ct-yellow-600 mb-4">
                    Welcome Back
                </h1>
                {getScreen()}
            </div>
        </section>
    );

};

export default Component;