import * as React from "react";
import Card from "../Card"
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { SeriesItem } from ".";


type Props = {
    series: SeriesItem[]
};

const Component: React.FunctionComponent<Props> = ({ series }) => {

    const datas = [
        { name: 'protected', data: series.map(item => item.protected) },
        { name: 'byPass', data: series.map(item => item.byPass) }
    ];

    const categories = series.map(item => item.categorie);

    const totalProtected = series.reduce((sum, item) => sum + item.protected, 0);
    const totalByPass = series.reduce((sum, item) => sum + item.byPass, 0);

    const options: ApexOptions = {
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            type: 'bar',
            height: 335,
            stacked: true,
            toolbar: {
                show: false
            }
        },
        colors: ['#a4cafe', '#c81e1e'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                columnWidth: '25%',
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
            }
        },
        responsive: [
            {
                breakpoint: 1536,
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                            columnWidth: '25%',
                        },
                    },
                },
            },
        ],
        grid: {
            show: true
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            }
        },
        xaxis: {
            type: "category",
            categories: categories,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        }
    }

    return (
        <Card title="Category details">
            <div className="flex flex-row items-start justify-center">
                <div className="-ml-5 -mb-9 w-full">
                    <ReactApexChart options={options} series={datas} height={350} type="bar" />
                </div>
                <div className="flex flex-col space-y-4 hidden sm:block">
                    <div className="flex flex-row">
                        <div className={'relative flex justify-center w-1 h-12 bg-[#c81e1e]'}></div>
                        <div className="ml-2">
                            <div>Violations</div>
                            <div className="font-bold">{totalByPass}</div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className={'relative flex justify-center w-1 h-12 bg-[#a4cafe]'}></div>
                        <div className="ml-2">
                            <div>Protected</div>
                            <div className="font-bold">{totalProtected}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default Component;