import * as React from "react";
import Card from "../Card"
import { Regulatory, RegulatoryViolation } from "pages/GRC";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

type Props = {
    regulations?: Regulatory[]
};

type SubSectionProps = {
    regulation: Regulatory
}

type ValueProps = {
    value: number
    isAlertable?: boolean
};

type RowProps = {
    violation: RegulatoryViolation
    isViolation?: boolean
};

const Row: React.FunctionComponent<RowProps> = ({ violation, isViolation = false }) => {

    const boxColor = isViolation ? 'bg-red-500' : 'bg-light-green-500';

    return (
        <div className="grid grid-cols-5 gap-4 w-full mb-2" >
            <div className={`border ${boxColor}`}>{violation.category}</div>
            <div className={`border ${boxColor}`}>{violation.count}</div>
            <div className={`col-span-2 text-left border ${boxColor}`}>{violation.description}</div>
            <div className={`border ${boxColor}`}>{violation.articles}</div>
        </div>
    )
}


const Value: React.FunctionComponent<ValueProps> = ({ value, isAlertable = false }) => {

    const boxColor = isAlertable && value > 0 ? 'bg-red-500' : 'bg-light-green-500';

    return (
        <div className={`border ${boxColor} text-2xl`}>
            {value}
        </div>
    )
}


const Expendable: React.FunctionComponent<SubSectionProps> = ({ regulation }) => {

    const sumViolations = regulation.byPass.map(a => a.count).reduce((a, b) => a + b);
    const sumProtected = regulation.protected.map(a => a.count).reduce((a, b) => a + b);

    const [expand, setExpand] = React.useState<boolean>(false);

    if (sumViolations === 0 && sumProtected === 0) {
        return null;
    }

    return (
        <>
            {
                !expand ? (

                    <div className="flex flex-row grid grid-cols-8 gap-4 pb-2" onClick={() => { setExpand(!expand) }} >
                        <div className="col-span-3 text-left">
                            {regulation.title}
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-2 text-right">
                                Violations
                            </div>
                            <Value value={sumViolations} isAlertable={true} />
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-2 text-right">
                                Protected
                            </div>
                            <Value value={sumProtected} />
                        </div>
                        <div className="col-end-9 flex justify-between">
                            <MdExpandMore className="order-last mt-2"/>
                            <span></span>
                        </div>
                    </div>

                ) : (
                    <div>
                        <div className="flex justify-between" onClick={() => { setExpand(!expand) }} >
                            <div className="text-left">{regulation.title}</div>
                            <MdExpandLess className="mt-2"/>
                        </div>
                        <div className="flex flex-row">
                            <div className="grid grid-cols-5 gap-4  w-full" >
                                <div>
                                    Category hits
                                </div>
                                <div>Hits count</div>
                                <div className="col-span-2">Description</div>
                                <div>Legal Articles</div>
                            </div>
                        </div>
                        {/*Violations*/}
                        {regulation.byPass.map((violation) => (violation.count > 0 && <Row violation={violation} isViolation={true} key={violation.category} />))}
                        {/*Protected*/}
                        {regulation.protected.map((violation) => (violation.count > 0 && <Row violation={violation} key={violation.category} />))}
                    </div>
                )
            }
        </>
    )
}

const Component: React.FunctionComponent<Props> = ({ regulations = [] }) => {

    return (

        <Card title="Compliance details">
            {regulations.map((regulation) => (
                <Expendable
                    regulation={regulation} key={regulation.title} />
            )
            )}
        </Card>
    )
}

export default Component;