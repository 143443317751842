import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { API_URL, LANDING_URL } from "../../constant";
import { Select, Option } from "@material-tailwind/react";
import { FaPlus } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { getTokenCurrentUser } from "../../services/auth.service";

type Props = object

type OrgResponse =
{
    organization: {
      id: number,
      slug: string,
      name: string,
      logo_url: string,
      owner: {
        id: number,
        username: string,
        email: string,
        image: string
      },
      members: [
        {
          id: number,
          username: string,
          email: string,
          image: string
        }
      ]
    }
  }

  type LlmCalls =
    {
      group: string,
      host: string,
      id: number,
      name: string
    }[]

  type LlmsList = {
    id: number,
    token: string
  }[]

const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Component: React.FunctionComponent<Props> = () => {
    const pack: string | null = useQuery().get('pack');
    const [url, setUrl] = React.useState('');
    const [name, setName] = React.useState<string>('');
    const [orgCreationError, setOrgCreationError] = React.useState<string>('');
    const [page, setPage] = React.useState<number>(1);
    const [llmChoice, setLlmChoice] = React.useState<string | undefined>(undefined); 
    const [token, setToken] = React.useState<string | undefined>(undefined);
    const [llmsList, setLlmsList] = React.useState<LlmsList>([])
    const navigate = useNavigate()

    let slug: string | undefined;
    let llms: LlmCalls | undefined

    if (pack === null) {
        window.location.href = LANDING_URL + "#pricings";
    }
    console.log(pack)
    const suffixe = {
        '1': 'st',
        '2': 'nd',
        '3': 'rd',
        '4': 'th',
    }

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
      };
    
      const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (!url.startsWith("https://") && !url.startsWith("http://") && !url.startsWith('data:image')) {
          setUrl("https://");
        }
      };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
      };

    const sendBasicInformations = () => {
        if (name) {
            const requestData = {
                organization: {
                  logo: {
                    url: url
                  },
                  name: name
                }
              };
            
              fetch(`${API_URL}organization`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + getTokenCurrentUser().token
                },
                body: JSON.stringify(requestData)
              }).then(
                async (response) => {
                const datas: OrgResponse = await response.json();
                  if (datas.organization) {
                    slug = datas.organization.slug;
                    fetch(`${API_URL}llmCalls`, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                      }).then(
                        async (response) => {
                            const datas = await response.json();
                            llms = datas.LlmsCalls
                        }
                      )
                    setPage(2)
                  }
                },
                (error) => {
                  setOrgCreationError(error.message);
                }
              );
        }
    }

    const addLlmToList = () => {
      console.log('add 1')
      if (llmChoice && token && typeof token === 'string' && typeof Number(llmChoice) == 'number'){
        console.log('add 2')
        setLlmsList([...llmsList, 
          {
            id: Number(llmChoice),
            token: token
          }
        ])
        setLlmChoice(undefined);
        setToken('')
      }
    }

    const deleteLlm = (id: number) => {
      setLlmsList(llmsList.filter(element => element.id !== id))
    }

    const getNameById = (id: any, llmCalls: LlmCalls | undefined) => {
      if (llmCalls){
        const item = llmCalls.find(call => call.id === id);
        return item ? item.name : null;
      }
      return null
    };

    const finish = () => {
      llmsList.forEach(({ id, token }) => {
        const requestData = { token };
        fetch(`${API_URL}organization/${slug}/llm/${id}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getTokenCurrentUser().token
          },
          body: JSON.stringify(requestData)
        })
        .then(() => navigate('/'))
        .catch(error => console.log(error));
      });
    }

    return (
    <>
        <div className="w-full flex h-screen justify-center items-center bg">
        {pack && ['1', '2', '3', '4'].includes(pack) && page === 1 && 
            (
            <>
            <div className="border p-5 shadow-md rounded-lg bg-white">
                <div className="text-2xl pb-3 border-b font-medium items-center">
                    <div className="flex w-full">
                        You have chosen the {pack}{suffixe[pack as '1' | '2' | '3' | '4']} pack
                        <div className="text-teal-500 py-1 px-3 hover:text-teal-600 cursor-pointer">
                            <a href={LANDING_URL + "#pricings"}>
                                <FaPencilAlt />
                            </a>
                        </div>
                    </div>
                    <div className="text-xl mt-3">
                        For create your organization, please fill this basicals informations
                    </div>
                </div>
                    <div className="flex w-full items-center py-5">
                        <div className="text-lg mr-3 min-w-max">Organization Name</div>
                        <input value={name} onChange={handleNameChange} type="text" className="w-full rounded-md caret-teal-500 accent-teal-500 focus:ring-4 focus:ring-teal-500/50 focus:border-teal-500" placeholder="Name" />
                    </div>
                    <div className="flex w-full items-center py-5 justify-between">
                        <div className="text-lg mr-3 min-w-max">Organization logo URL</div>
                        <input
                            type="text"
                            className="w-full rounded-md caret-teal-500 accent-teal-500 focus:ring-4 focus:ring-teal-500/50 focus:border-teal-500"
                            placeholder="URL"
                            value={url}
                            onChange={handleUrlChange}
                            onClick={handleClick}
                        />
                        <img src={url} alt="" className="w-20 ml-5" />
                    </div>
                    <div className="w-full mt-2 flex justify-end gap-6 items-center">
                        <div className="hover:underline text-[#3b5998] cursor-pointer" onClick={()=> {setPage(2)}}>
                          Skip this step
                        </div>
                        <div
                            className="px-4 py-2 bg-teal-600 text-lg text-white rounded-md cursor-pointer hover:scale-110 transition-all duration-300"
                            onClick={sendBasicInformations}
                        >
                            Next
                        </div>
                    </div>
                </div>
                {orgCreationError &&
                <div>
                    {orgCreationError}
                </div>
                }
            </>
        )}
        {page === 2 && (
          <>
          <div className="border p-5 shadow-md rounded-lg bg-white">
              <div className="text-xl border-b pb-4">
                  Next, add LLMs to your organization with your tokens
              </div>
              <div className="pt-5">
                {llms &&
                <Select size="lg" color="teal"
                label="Choose a model"
                animate={{mount: { y: 0 },unmount: { y: 25 },}}
                className=""
                value={llmChoice}
                onChange={(e) => setLlmChoice(e)}
                placeholder="Choose a model"
                variant="standard"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
                {llms && 
                    llms.map(({ id, name }) =>   
                      <Option key={id} value={id.toString()} disabled={!!llmsList.find(llm => llm.id === id)}>
                          {name}
                      </Option>
                    )
                }

                </Select>}
                <div className="flex items-baseline">
                  <input type="text"
                  value={token}
                  placeholder="Your API key"
                  className="w-full rounded-md caret-teal-500 accent-teal-500 focus:ring-4 focus:ring-teal-500/50 focus:border-teal-500 mt-4"
                  onChange={e => setToken(e.target.value)}
                  />
                  <div className="p-2 translate-y-2 ml-2 hover:text-teal-500 cursor-pointer" onClick={addLlmToList}>
                    <FaPlus />
                  </div>
                </div>
                {llmsList && (
                  <div className="max-h-56 overflow-y-scroll my-3 rounded-md border p-3">
                    {llmsList.map(({ id, token }) => (
                      <div className="flex justify-between">
                        <div className="flex">
                          <div className="px-1 py-0.5 border bg-teal-400 rounded-md mr-3">
                            {getNameById(id, llms)}
                          </div>
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-sm">
                            {token}
                          </div>
                        </div>
                        <div className="p-1 ml-1 hover:text-red-700 cursor-pointer" onClick={() => deleteLlm(id)}>
                          <FaTrash />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-full mt-2 flex justify-end gap-6 items-center">
                        <div className="hover:underline text-[#3b5998] cursor-pointer" onClick={()=> {navigate('/')}}>
                          Skip this step
                        </div>
              <div
                  className="px-4 py-2 bg-teal-600 text-lg text-white rounded-md cursor-pointer hover:scale-110 transition-all duration-300"
                  onClick={finish}
              >
                  Send
              </div>
              </div>
          </div>
          </>
      )}
      </div>
  </>
  )
};

export default Component;