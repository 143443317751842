import axios from "axios"
import { log } from "../tools/log"
import { API_URL } from "../constant"

export const register = (email: string, password: string, role: 'ORG'|'USER' = 'ORG') => {
    const data = {
        "user": {
            "email": email,
            "password": password,
            "role": role,
        }
    }
    return axios.post(API_URL + 'user', data)
}

export const login = (email: string, password: string) => {
    const data = {
        "user": {
            "email": email,
            "password": password,
        }
    }
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')
    return axios.post(API_URL + 'user/login', data)
        .then((response) => {
            log.debug(`Response received ${response.data}`)
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data
        }
    )
}

export const getTokenCurrentUser = () => {
    const userStr = localStorage.getItem("user")
    if (userStr) return JSON.parse(userStr)

    return null
}

export const logout = () => (dispatch: any) => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
}