import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux";
import { fetchLLMGroups, LLMGroup } from "../../../../redux/allLLMGroupReducer";
import { useSelector } from "react-redux";
import { User } from "../../../../redux/userReducer";
import { API_URL } from "constant";
import axios from "axios";
import { getIcon, getLabel } from ".";
import { Model } from "../../../../redux/allLLMReducer";
import { Modal } from "flowbite-react";
import { MdAddModerator } from "react-icons/md";
import Button from "@components/Button";
import Select from "@components/Select";
import IconInput from "@components/Input/iconInput";
import ModelsComponent from "./models";
import { FaKey } from "react-icons/fa6";

type Props = object


type KeyResponse = {
    key: string
    llm: LLMGroup
}

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useDispatch<AppDispatch>()
    const llms = useSelector((state: RootState) => state.llmGroupsState.llmGroupList);
    const user: User = useSelector((state: RootState) => state.user.user);
    const [errorToken, setErrorToken] = React.useState<string | undefined>(undefined);
    const [selectedOption, setSelectedOption] = React.useState<number>(0);
    const [showTableModel, setShowTableModel] = React.useState<boolean>(false);
    const [models, setModels] = React.useState<Model[]>([]);

    React.useEffect(() => {
        dispatch(fetchLLMGroups())
    }, [dispatch])

    const options = llms ? llms.map((llmGroup: LLMGroup) => { return { value: llmGroup.id, label: getLabel(llmGroup.name), icon: getIcon(llmGroup.name) } }) : [];

    const [keyDisplay, setKeyDisplay] = React.useState<boolean>(true)
    const [secret, setSecret] = React.useState<string|undefined>(undefined);


    const retrieveKeyLLMGroup = async (id: number) => {
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            };
            const response = await fetch(`${API_URL}llm/group/${id}/key`, { headers });
            const data: KeyResponse = await response.json();
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    const handleChooseLLM = async (value: number) => {
        setSelectedOption(value);
        const secretRetrieved = await retrieveKeyLLMGroup(value);
        setSecret(secretRetrieved && secretRetrieved.key);
        setKeyDisplay(false);
        setShowTableModel(false);
        setErrorToken(undefined);

    }

    const validateKey = async (value: string) => {
        if (value) {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            };

            try {
                const response = await axios.post(`${API_URL}llms/group/${selectedOption}`, { "token": value }, { headers: headers });

                const fetchedModels = response.data.models;
                const llm = llms.find((i: Model) => i.id === selectedOption)
                const result: Model[] = fetchedModels.map((item: any) => {
                    return {
                        "id": 0,
                        "name": item.name,
                        "llm": {
                            "name": llm.name,
                            "id": llm.id
                        }
                    }
                })
                setModels(result);
                setShowTableModel(true);
                setErrorToken(undefined);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response && error.response.status === 401) {
                        setErrorToken("Token is not authorized");
                    } else {
                        setErrorToken(error.response ? error.response.data.message : "An error occurred");
                    }
                } else {
                    setErrorToken("An unexpected error occurred");
                }
                setShowTableModel(false);
            }
        }
    }

    const [isModalVisible, setModalVisible] = React.useState(false);

    return (
        <>
            <Button icone={<MdAddModerator />} title="Add LLM" onClick={() => setModalVisible(true)} />
            <Modal show={isModalVisible} onClose={() => setModalVisible(false)} dismissible size="3xl" position={"center"} className="z-999999">
                <Modal.Header>
                    <div className="flex flex-rows gap-x-4 items-center">
                        <MdAddModerator className="text-xl" />
                        <div>
                            Add Model
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full flex flex-col space-y-4">
                        <Select
                            options={options}
                            value={selectedOption}
                            onChange={(value) => typeof value !== "string" && handleChooseLLM(value)}
                            placeholder="Select your LLM"
                        />
                        <IconInput icon={<FaKey />} placeholder="Enter your token" onValueChange={validateKey} disabled={keyDisplay} errorMessage={errorToken} submitButton={true} labelSubmit="Validate" type="password" inputValue={secret}/>

                        {showTableModel && <ModelsComponent models={models} onClick={() => setModalVisible(false)} />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default Component;