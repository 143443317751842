import React, { createContext, useState, useContext, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

type AlertType = 'success' | 'error' | 'info' | 'warning';

interface Alert {
    message: string;
    type: AlertType;
}

interface AlertContextType {
    alert: Alert | null;
    showAlert: (message: string, type: AlertType) => void;
    hideAlert: () => void;
}

// Crée le contexte avec un type ou undefined par défaut
const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = (): AlertContextType => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};

interface AlertProviderProps {
    children: ReactNode;
}

export const AlertProvider: React.FunctionComponent<AlertProviderProps> = ({ children }) => {
    const [alert, setAlert] = useState<Alert | null>(null);

    const showAlert = (message: string, type: AlertType) => {
        console.log("Showing alert:", message, type);
        setAlert({ message, type });
        setTimeout(() => {
            setAlert(null);
        }, 10000);
    };

    const hideAlert = () => {
        setAlert(null);
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
            {children}
            {alert && <AlertComponent message={alert.message} type={alert.type} />}
        </AlertContext.Provider>
    );
};

interface AlertComponentProps {
    message: string;
    type: AlertType;
}

const AlertComponent: React.FunctionComponent<AlertComponentProps> = ({ message, type }) => {

    const alertClassName = () => {
        switch (type) {
            case "success":
                return "text-green-800 bg-green-50 dark:text-green-400 border-green-300"
            case "error":
                return "text-red-800 bg-red-50 dark:text-red-400 border-red-300"
            case "info":
                return "text-blue-800 bg-blue-50 dark:text-blue-400 border-blue-300"
            case "warning":
                return "text-yellow-800 bg-yellow-50 dark:text-yellow-400 border-yellow-300"
            default:
                return "text-gray-800 bg-gray-50 dark:text-gray-400 border-gray-300"
        }
    }

    const alertButtonClassName = () => {
        switch (type) {
            case "success":
                return "bg-green-50 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:text-green-400 "
            case "error":
                return "bg-red-50 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:text-red-400 "
            case "info":
                return "bg-blue-50 text-blue-500 focus:ring-blue-400 hover:bg-blue-200 dark:text-blue-400 "
            case "warning":
                return "bg-yellow-50 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200 dark:text-yellow-400 "
            default:
                return "bg-gray-50 text-gray-500 focus:ring-gray-400 hover:bg-gray-200 dark:text-gray-400 "
        }
    }

    const id = uuidv4();

    return (
        <div id={id} className={`flex items-center p-4 mb-4 text-sm rounded-lg dark:bg-gray-800 border ${alertClassName()}`} role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only capitalize">{type}</span>
            <div>
                <span className="font-medium"><span className="capitalize">{type}</span> alert!</span> {message}
            </div>
            <button type="button" className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:hover:bg-gray-700 ${alertButtonClassName()}`} data-dismiss-target={`#${id}`} aria-label="Close">
                <span className="sr-only">Close</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
            </button>
        </div>
    );
};