import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";
import { LLMGroup } from "./allLLMGroupReducer";
import axios from "axios";

export type Model = {
    name: string;
    llm: LLMGroup;
    id: number;
    valid?: boolean;
};

type LLMsState = {
    byName: { [name: string]: Model };
    allNames: string[];
};

const initialState: LLMsState = {
    byName: {},
    allNames: []
};

export const fetchLLMs = createAsyncThunk<LLMsState, void, { rejectValue: string }>(
    "llms/fetchLLMs",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}llms`, { headers });
            const data: any =  await response.json();
            const llms: Model[] = data.llms;

            const normalizedData = llms.reduce<LLMsState>((acc, llm) => {
                acc.byName[llm.name] = llm;
                acc.allNames.push(llm.name);
                return acc;
            }, { byName: {}, allNames: [] });

            return normalizedData;
        } catch (error) {
            return rejectWithValue("Failed to fetch LLMs.");
        }
    }
);

export const fetchLLMsForOrganization = createAsyncThunk<string[], void, { rejectValue: string }>(
    "llms/fetchLLMsForOrganization",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}`, { headers });
            const data: Model[] = await response.json();

            const llmNames = data.map(llm => llm.name);
            return llmNames;
        } catch (error) {
            return rejectWithValue("Failed to fetch LLMs for organization.");
        }
    }
);

export const addModel = createAsyncThunk<void, Model, { rejectValue: string }>(
    "models/addModel",
    async (model, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };

            const response = await axios.post(`${API_URL}configuration/${state.user.user?.organization.slug}/model`, {"llm_group_id": model.llm.id, "model": model.name}, {headers: headers});

            if (response.status < 200 || response.status > 299) {
                throw new Error("Failed to add model to the organization.");
            }
        } catch (error) {
            return rejectWithValue("Failed to add agent.");
        }
    }
);

export const delModel = createAsyncThunk<void, Model, { rejectValue: string }>(
    "models/delModel",
    async (model, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };

            console.log(model)

            const response = await axios.delete(`${API_URL}configuration/${state.user.user?.organization.slug}/model`, {headers: headers, data : {"llm_group_name": model.llm.name, "model": model.name}});

            if (response.status < 200 || response.status > 299) {
                throw new Error("Failed to add model to the organization.");
            }
        } catch (error) {
            return rejectWithValue("Failed to add agent.");
        }
    }
);

const allLLMsSlice = createSlice({
    name: "llms",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLLMs.fulfilled, (state, action) => {
            state.byName = action.payload.byName;
            state.allNames = action.payload.allNames;
        });
    }
});

export const selectCombinedLLMs = createSelector(
    (state: RootState) => state.llms.byName,
    (state: RootState) => state.llms.allNames,
    (state: RootState) => state.configurationState.configuration?.llmNames || [],
    (llmsByName, allNames, organizationLLMNames) => {
        return allNames.map((name: string) => ({
            ...llmsByName[name],
            isInOrganization: organizationLLMNames.includes(name)
        }));
    }
);

export default allLLMsSlice.reducer;
