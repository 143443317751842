import * as React from "react";
import { IoCopyOutline } from "react-icons/io5";
import { Tooltip } from "@material-tailwind/react";

type Props = {
    children?: React.ReactNode
};

const Component: React.FunctionComponent<Props> = ({children}) => {

    return (
        <div className="mt-1 flex gap-3">
            <div className="items-center justify-start rounded-xl p-1 flex">
                <div className="flex items-center">
                    <Tooltip content="Copy" placement="bottom">
                        <button className="rounded-lg flex h-[30px] w-[30px] items-center justify-center">
                            <IoCopyOutline className="hover:animate-ping"/>
                        </button>
                    </Tooltip>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Component;