import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "react-redux-typescript";
import { RootState } from ".";
import { API_URL } from "../constant";

export type SimpleUSer = {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  image: string;
  profiles?: Profile[];
}

type Organization = {
  id: number;
  name: string;
  slug: string;
  owner: SimpleUSer;
  members: SimpleUSer[];
}


export type Profile = {
  name: string
}

type Violation = {
  category: string
  count: number
}

type Detail = {
  date: string
  violations: [Violation]
}

type Usage = {
  prompts: number
  violations: number
  details: Detail[]
}

export type User = {
  id: number
  username: string
  email: string
  bio: string
  image: string
  organization: Organization
  usage: Usage
  token: string
  firstname: string
  lastname: string
  profiles: Profile[]
  mfa: boolean
  authenticated: boolean
}

type UserState = {
  user: User | null;
  loading: boolean
  error: string | undefined
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: undefined,
}

export const userMe = createAsyncThunk<User, void, { rejectValue: string }>(
  "user/me",
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState
    try {
      const headers = { 'Accept': 'application/json', 'Authorization': `Bearer ${state.user.user?.token}` }
      const response = await fetch(API_URL + 'user/me', { headers: headers });
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }
      const data = await response.json();
      data.user.token = state.user.user?.token
      return data.user;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

const userSlice = createSlice({
  // name used in action types
  name: "user",
  // initial state
  initialState,
  // an object of "case reducers"
  // key names are used to generate actions
  reducers: {
    logout: (state) => {
      state.user = null
    },
    setUser: (state, action: PayloadAction<string, User>) => {
      state.user = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    updateProfile: (state, action: PayloadAction<string, User>) => {
      state.user = action.payload;
    },
    login: (state, action: PayloadAction<string, User>) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userMe.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(userMe.rejected, (state, action: PayloadAction<string, string | undefined>) => {
        state.loading = false;
        state.user = null;
      })
      .addCase(logout, (state) => {
        return initialState; // Réinitialise l'état complet du slice
      });
  }
});

export const selectCurrentUser = (state: RootState): User => state.user.user;
export const selectAuthLoading = (state: RootState) => state.user.loading;
export const selectAuthError = (state: RootState) => state.user.error;
export const { logout, setUser, login, clearError, updateProfile } = userSlice.actions;
export default userSlice.reducer;