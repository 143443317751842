import * as React from "react";
import AttachButton from "./components/AttachButton"
import SendButton from "./components/SendButton"
import Message from "./components/Message"
import { useRefreshId } from "../../../../../../hooks/useRefreshId";
import { PromptValue } from "../../../../../../redux/allMessagesReducer";

type Props = {
    onSend: (promptValue:PromptValue)=>void
    message?: string
};

const Component: React.FunctionComponent<Props> = ({onSend, message}) => {
    const [value, setValue] = React.useState<string|undefined>()
    const disabled = !value || value.trim() === "";
    const [refreshId, forceRefresh] = useRefreshId();

    const handleSend = () => {
        if(value){
            const promptValue:PromptValue = {
                message: value,
                message_original: value,
                reason: undefined,
                protected: true,
            }
            onSend(promptValue);
            forceRefresh();
        }
    }

    return (
        <div className="relative flex h-full max-w-full flex-1 flex-col">
            <div className="flex w-full items-center">
                <div className="flex w-full flex-col gap-1.5 rounded-[26px] transition-colors bg-[#f4f4f4] dark:bg-token-main-surface-secondary">
                    <div className="flex items-end gap-1.5 md:gap-2 p-2">
                        <AttachButton />
                        <Message onChange={setValue} key={refreshId} value={message}/>
                        <SendButton disabled={disabled} onSend={handleSend}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Component;