import * as React from "react";
import {v4 as uuidv4} from 'uuid';

type TabProps = {
    title: string;
    icone: React.ReactNode;
    content: React.ReactNode;
}

type Props = {
    items: TabProps[];
    initialActiveIndex?: number;
}

const slugify = (str: string) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

const Tab: React.FunctionComponent<Props> & { Header: React.FunctionComponent<{ children: React.ReactNode }>; Content: React.FunctionComponent<{ children: React.ReactNode }> } = ({ items, initialActiveIndex = 0 }) => {

    const [activeIndex, setActiveIndex] = React.useState(initialActiveIndex);

    React.useEffect(() => {
        // Vérifiez si l'URL contient déjà un hash correspondant à un onglet lors du premier rendu
        const hash = window.location.hash.substring(1);
        const index = items.findIndex(item => slugify(item.title.toLowerCase()) === hash.toLowerCase());
        if (index !== -1 && index !== activeIndex) {
            setActiveIndex(index);
        }
        // Le tableau de dépendances est vide pour ne le faire qu'au montage
    }, [activeIndex, items]);

    const handleTabClick = (index: number) => {
        const title = items[index]?.title.toLowerCase();
        if (window.location.hash.substring(1) !== slugify(title)) {
            window.location.hash = slugify(title); // Ne changez le hash que si nécessaire
        }
        setActiveIndex(index); // Changez l'index actif
    };

    return (
        <div className="w-full">
            <Tab.Header key={uuidv4()}>
                <>
                    {items.map((item, index) => (
                        <li className="me-2 flex-1" key={uuidv4()}>
                            <button
                                key={index}
                                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg w-full ${activeIndex === index
                                    ? 'border-[#3b5998] text-[#3b5998]'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                                    }`}
                                onClick={() => handleTabClick(index)}
                            >
                                {item.icone} {item.title}
                            </button>
                        </li>
                    ))}
                </>
            </Tab.Header>
            <Tab.Content key={uuidv4()}>
                {items[activeIndex]?.content}
            </Tab.Content>
        </div>
    )
}

Tab.Header = ({ children }) => {
    return (
        <div className="border-b border-gray-200 dark:border-gray-700 w-full">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 w-full">
                {children}
            </ul>
        </div>
    );
};

Tab.Content = ({ children }) => {
    return <div className="p-4">{children}</div>;
};

export default Tab;
