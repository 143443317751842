import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_URL } from "../constant";
import { RootState } from ".";
import { v4 as uuidv4 } from 'uuid';

type Status = "PENDING" | "DELIVERED" | "ON_ERROR"

export type LLMResponse = {
    response: string
    model: string
    avatar: string
}

export type Question = {
    prompt_send: string
    prompt_original: string
    status: Status
}

export type Message = {
    id: string
    question: Question
    response?: LLMResponse
    conversation_id?: string
    prompt_id?: string
    loading: boolean
    error?: ErrorResult
}

export interface PromptValue {
    message: string
    message_original: string
    reason: string|undefined
    prompt_id?: string|undefined
    protected: boolean
}

export interface Violation {
    categorie: string
    subcategorie: string
    substitution: string
    start: number
    end: number
    word: string
}

interface Errors {
    prompt: string
    slug: string
    prompt_id: string
    violations: [Violation]
}

interface ErrorResult {
    code: string
    errors?: Errors
    message: string
}

type responseMessage = {
    prompt: string
    original_prompt: string
    conversation_id: string
    prompt_id: string
    response: string
    model: string
}

type requestMessage = {
    llm: string
    prompt: string
    original_prompt: string
    conversation_id?: string
    protected: boolean
    reason?: string
}

type MessagesState = {
    messagesList: Message[]
}

const initialState: MessagesState = {
    messagesList: []
};


export const fetchAnswer = createAsyncThunk<LLMResponse, requestMessage, { rejectValue: ErrorResult }>(
    "messages/createMessage",
    async (data, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(API_URL + 'prompt', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                const errorData = await response.json();
                // should remove last question
                return rejectWithValue(errorData)
            }
            const conversation: responseMessage = await response.json();
            const llmResponse: LLMResponse = {
                response: conversation.response,
                model: "",
                avatar: ""
            }
            return llmResponse;
        } catch (error) {
            const api_error: ErrorResult = {
                code: "500",
                message:"Something went wrong",
            }
            if (error instanceof Error) {
                api_error.message = error.message
            }
            return rejectWithValue(api_error);
        }
    }
);

export const fetchMessages = createAsyncThunk<Message[], string, { rejectValue: string }>(
    "messages/getMessages",
    async (conversationId, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(API_URL + `conversation/${conversationId}`, {headers: headers});
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData)
            }

            const conversation = await response.json()
            return conversation.messages
        } catch (error) {
            return rejectWithValue("Failed to fetch issues.");
        }
    }
);

const allMessagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        addQuestion: (state, action: PayloadAction<requestMessage>) => {
            const message = state.messagesList.find((msg) => msg.loading);
                if (message) {
                    message.question.prompt_send = action.payload.prompt
                    message.question.prompt_original = action.payload.original_prompt
                    return
                }

            const question: Question = {
                prompt_send: action.payload.prompt,
                prompt_original: action.payload.original_prompt,
                status: "PENDING"
            }
            
            state.messagesList.push({
                id: uuidv4(),
                question: question,
                loading: true
            })
        },
        cleanMessages: (state) => {
            state.messagesList = []
        }
    },
    extraReducers: (builder) => {
        //  arguments of action are same first one is state
        //& second one is action
        builder
            .addCase(fetchAnswer.fulfilled, (state, action) => {
                const message = state.messagesList.find((msg) => msg.loading);
                if (message) {
                    message.loading = false;
                    message.response = action.payload
                    message.error = undefined
                }
            })
            .addCase(fetchAnswer.rejected, (state, action) => {
                const message = state.messagesList.find((msg) => msg.loading);
                if (message) {
                    //message.loading = false;
                    message.error = action.payload
                }
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.messagesList = action.payload;
            })
    },
});

export const { addQuestion, cleanMessages } = allMessagesSlice.actions;
export default allMessagesSlice.reducer;