import * as React from "react";
import Card from "../Card";
import { SimpleUSer } from "../../../../redux/userReducer";
import { FaCircleQuestion, FaRotate } from "react-icons/fa6";
import Button from "../../../../components/Button";
import AddPerson from "./addPerson";
import ListReset from "./resetPassword";
import ListUser from "./list";

type Props = {
    members: SimpleUSer[];
    invitations: SimpleUSer[];
    list_size?: number;
}

const Component: React.FunctionComponent<Props> = ({ members, invitations, list_size = 25 }) => {

    const [showList, setShowList] = React.useState<boolean>(true);

    return (
        <div className="lg:grid lg:grid-cols-4">
            <div className="lg:col-start-2 lg:col-span-2">
                <Card title={
                    <div className="flex w-full justify-between">
                        <div>Users</div>
                        <div className="text-[#3b5998]"><button className="font-normal flex flex-rows gap-1 items-center hover:underline"><FaCircleQuestion /> Help</button></div>
                    </div>
                }>
                    <div className="mb-4">
                        {showList && <AddPerson />}
                        <Button icone={<FaRotate />} title="Reset passwords" onClick={() => setShowList(!showList)} active={!showList} appendClass={showList ? ['mt-2 lg:mt-0 lg:ml-2']:[]}/>
                    </div>
                    {showList ?
                        <ListUser members={members} invitations={invitations} list_size={list_size} /> :
                        <ListReset members={members} list_size={list_size} />
                    }
                </Card>
            </div>
        </div>

    )
};

export default Component;