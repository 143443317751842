import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "@components/FormInput";
import Button from "@components/Button";
import { Link } from "react-router-dom";
import { LoginInput } from "pages/Login";

type Props = {
    loginUser: (data: LoginInput) => void
};

const Component: React.FunctionComponent<Props> = ({ loginUser }) => {

    const methods = useForm<LoginInput>();

    const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
        loginUser(values);
    };

    return (
        <>
            <h2 className="hidden text-lg text-center mb-4 text-ct-dark-200 lg:block">
                Login to have access
            </h2>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                    className="max-w-md w-full mx-auto overflow-hidden md:shadow-lg md:bg-ct-dark-200 md:rounded-2xl p-8 space-y-5 md:bg-white"
                >
                    <FormInput label="Email" name="email" type="email" />
                    <FormInput label="Password" name="password" type="password" />

                    <div className="text-right">
                        <Link to="/forgotpassword" className="text-[#0b5cad] hover:underline">
                            Forgot Password ?
                        </Link>
                    </div>
                    <Button isSubmit title="Login" isFullWidth />
                    <span className="block">
                        Need an account?{" "}
                        <Link to="/register" className="text-[#0b5cad] hover:underline">
                            Sign Up Here
                        </Link>
                    </span>
                </form>
            </FormProvider>
        </>
    );

};

export default Component;
