import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";
import { Agent } from "./allAgentReducer";
import { Model } from "./allLLMReducer";
import { SimpleUSer } from "./userReducer";

type Organization = {
    id: number;
    slug: string;
    name: string;
    logo_url: string;
    members: SimpleUSer[];
    invitations: SimpleUSer[];
};

export type Configuration = {
    organization: Organization;
    agentIds: number[];
    models: Model[];
    ttl_days: number;
    anonymization: boolean;
    bypass: boolean;
    passthrough: boolean;
};

type ConfigurationState = {
    configuration: Configuration | null;
    loading: boolean;
    error: string | undefined;
};

const initialState: ConfigurationState = {
    configuration: {
        organization: {
            id: 0,
            invitations: [],
            logo_url: "",
            members: [],
            name: "",
            slug: ""
        },
        agentIds: [],
        models: [],
        ttl_days: 0,
        anonymization: false,
        bypass: true,
        passthrough: false,
    },
    loading: false,
    error: undefined
};

export const getConfiguration = createAsyncThunk<Configuration, void, { rejectValue: string }>(
    "configuration/getConfiguration",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}`, { headers });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || "Failed to fetch configuration.");
            }

            const data = await response.json();

            const configuration: Configuration = {
                organization: data.configuration.organization,
                agentIds: data.configuration.agents.map((agent: Agent) => agent.id),
                models: data.configuration.models,
                ttl_days: data.configuration.ttl_days,
                anonymization: data.configuration.anonymization,
                bypass: data.configuration.bypass,
                passthrough: data.configuration.passthrough,
            };
            return configuration;
        } catch (error) {
            return rejectWithValue("Failed to fetch configuration.");
        }
    }
);

export const toggleAnonymization = createAsyncThunk<Configuration, void, { rejectValue: string }>(
    "configuration/toggleAnonymization",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}/anonymization`, { method: 'POST', headers: headers });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || "Failed to update configuration.");
            }

            const data = await response.json();

            const configuration: Configuration = {
                organization: data.configuration.organization,
                agentIds: data.configuration.agents.map((agent: Agent) => agent.id),
                models: data.configuration.models,
                ttl_days: data.configuration.ttl_days,
                anonymization: data.configuration.anonymization,
                bypass: data.configuration.bypass,
                passthrough: data.configuration.passthrough,
            };
            return configuration;
        } catch (error) {
            return rejectWithValue("Failed to fetch configuration.");
        }
    }
);



export const togglePassthrough = createAsyncThunk<Configuration, void, { rejectValue: string }>(
    "configuration/toggleAnonymization",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}/passthrough`, { method: 'POST', headers: headers });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || "Failed to update configuration.");
            }

            const data = await response.json();

            const configuration: Configuration = {
                organization: data.configuration.organization,
                agentIds: data.configuration.agents.map((agent: Agent) => agent.id),
                models: data.configuration.models,
                ttl_days: data.configuration.ttl_days,
                anonymization: data.configuration.anonymization,
                bypass: data.configuration.bypass,
                passthrough: data.configuration.passthrough,
            };
            return configuration;
        } catch (error) {
            return rejectWithValue("Failed to fetch configuration.");
        }
    }
);

export const toggleBypass = createAsyncThunk<Configuration, void, { rejectValue: string }>(
    "configuration/toggleBypass",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}/bypass`, { method: 'POST', headers: headers });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || "Failed to update configuration.");
            }

            const data = await response.json();

            const configuration: Configuration = {
                organization: data.configuration.organization,
                agentIds: data.configuration.agents.map((agent: Agent) => agent.id),
                models: data.configuration.models,
                ttl_days: data.configuration.ttl_days,
                anonymization: data.configuration.anonymization,
                bypass: data.configuration.bypass,
                passthrough: data.configuration.passthrough,
            };

            return configuration;
        } catch (error) {
            return rejectWithValue("Failed to fetch configuration.");
        }
    }
);

export const validateLLMs = createAsyncThunk<Model[], void, { rejectValue: string }>(
    "llms/validateLLMs",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}llms/groups/validate`, { headers });
            const data: any = await response.json();
            return data.models;
        } catch (error) {
            return rejectWithValue("Failed to validate LLMs.");
        }
    }
);

const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        addAgentToConfiguration: (state, action) => {
            if (state.configuration) {
                state.configuration.agentIds.push(action.payload);
            }
        },
        removeAgentFromConfiguration: (state, action) => {
            if (state.configuration) {
                state.configuration.agentIds = state.configuration.agentIds.filter(id => id !== action.payload);
            }
        },
        addModelToConfiguration: (state, action) => {
            if (state.configuration) {
                if (!state.configuration.models) {
                    state.configuration.models = [];
                }
                state.configuration.models.push(action.payload);
            }
        },
        removeModelFromConfiguration: (state, action) => {
            if (state.configuration) {
                state.configuration.models = state.configuration.models.filter((model) => model.name !== action.payload.name || model.llm.name !== action.payload.llm.name);
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getConfiguration.fulfilled, (state, action) => {
            state.configuration = action.payload;
            state.loading = false;
        });
        builder.addCase(getConfiguration.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(getConfiguration.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(togglePassthrough.fulfilled, (state, action) => {
            state.configuration = action.payload;
            state.loading = false;
        });
        builder.addCase(validateLLMs.fulfilled, (state, action) => {
            if (state.configuration && action.payload) {
                action.payload.forEach((validatedModel) => {
                    const model = state.configuration?.models.find(m => m.id === validatedModel.id);
                    if (model) {
                        model.valid = validatedModel.valid;
                    }
                });
            }
        });
    }
});

export const { addAgentToConfiguration, removeAgentFromConfiguration, addModelToConfiguration, removeModelFromConfiguration } = configurationSlice.actions;
export const selectConfiguration = (state: RootState): Configuration => state.configurationState.configuration;
export const selectModels = (state: RootState) => state.configurationState.configuration.models;
export const getMembers = (state: RootState) => state.configurationState.configuration.members;

export default configurationSlice.reducer;
