import * as React from "react";
import { useSelector } from "react-redux";
import { addAgent, fetchAgents, fetchAgentsForOrganization, removeAgent, selectCombinedAgents, toggleAgentOrganization } from "../../../../redux/allAgentReducer";
import TableCard from "@components/TableCard";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux";
import Toggle from "@components/Toggle";
import { addAgentToConfiguration, removeAgentFromConfiguration, selectConfiguration, toggleBypass } from "../../../../redux/configurationReducer";

type Props = object

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useDispatch<AppDispatch>();
    const agentsList = useSelector(selectCombinedAgents);
    const configuration = useSelector(selectConfiguration);

    React.useEffect(() => {
        dispatch(fetchAgents());
        dispatch(fetchAgentsForOrganization());
    }, [dispatch]);

    const handleToggleChange = (agentId: number, state: boolean) => {
        if (state) {
            dispatch(addAgent(agentId));
            dispatch(addAgentToConfiguration(agentId));
        } else {
            dispatch(removeAgent(agentId));
            dispatch(removeAgentFromConfiguration(agentId));
        }
        dispatch(toggleAgentOrganization(agentId));
    };

    const onToggleBypass = () => {
        dispatch(toggleBypass())
    }

    const customFilter = (item: any, statusFilter: string) => {
        if (statusFilter === "All") return true;
        if (statusFilter === "Actived") return item.isInOrganization === true;
        if (statusFilter === "Deactivated") return item.isInOrganization === false;
        return true;
    };

    return (

        <div className="sm:grid sm:grid-cols-4">
            <div className="sm:col-start-2 sm:col-span-2">
                <TableCard
                    title="Agents"
                    helpText="Help"
                    items={agentsList}
                    listSize={25}
                    showStatusFilter={true}
                    statusAccessor="id"
                    statusOptions={["All", "Actived", "Deactivated"]}
                    searchPlaceholder="Search for agent by name or explanation"
                    columns={[
                        {
                            header: "Name",
                            accessor: "name",
                            width: "w-1/4",
                            render: (value) => (
                                <span className="capitalize">{value}</span>
                            )
                        },
                        {
                            header: "Description",
                            accessor: "explanation",
                            width: "w-1/2",
                            xsHidden: true,
                        },
                        {
                            header: "",
                            accessor: "id",
                            width: "w-1/4",
                            align: "right",
                            render: (agentId, { isInOrganization }) => (
                                <Toggle key={agentId}
                                    checked={isInOrganization}
                                    onChange={(state: boolean) => handleToggleChange(agentId, state)} />
                            )
                        }
                    ]}
                    customFilter={customFilter}
                >
                    <div className="flex justify-end mb-4">
                        <Toggle label="Bypass" labelClass="font-bold h2" checked={configuration.bypass} onChange={() => onToggleBypass()} />
                    </div>
                </TableCard>
            </div>
        </div>
    )
};

export default Component;