import * as React from "react";
import Toggable from "./components/Toggable";
import Modal from "../../../../../../components/Modal";
import { useRefreshId } from "../../../../../../hooks/useRefreshId";
import { PromptValue, Violation } from "../../../../../../redux/allMessagesReducer";
import { useSelector } from "react-redux";
import { selectConfiguration } from "../../../../../../redux/configurationReducer";
import Button from "@components/Button";

type Props = {
    prompt: string
    violations: [Violation]
    onSend: (promptValue: PromptValue) => void
    prompt_id: string
    onClose: (message: string) => void
};

const replaceViolations = (prompt: string, violations: [Violation]): (string | { type: string, index: number })[] => {
    const violationsCopy = [...violations];
    violationsCopy.sort((a, b) => a.start - b.start);
    const result: (string | { type: string, index: number })[] = [];
    let lastIndex = 0;

    violationsCopy.forEach((violation, index) => {
        if (lastIndex < violation.start) {
            result.push(prompt.slice(lastIndex, violation.start));
        }
        result.push({ type: 'violation', index });
        lastIndex = violation.end + 1;
    });

    if (lastIndex < prompt.length) {
        result.push(prompt.slice(lastIndex));
    }

    return result;
}

const getViolationText = (
    violation: Violation, 
    isVisible: boolean, 
    anonymization: boolean
): string => {
    if (isVisible) return violation.word;
    return anonymization ? violation.substitution : `[${violation.subcategorie.toUpperCase()}]`;
};

const processPart = (
    part: string | { type: string, index: number }, 
    violations: Violation[], 
    visibleViolations: boolean[], 
    anonymization: boolean
): string => {
    if (typeof part === 'string') {
        return part;
    }

    if (part.type === 'violation') {
        const violation = violations[part.index];
        return getViolationText(violation, visibleViolations[part.index], anonymization);
    }

    return '';
};

const generateFinalPrompt = (
    promptParts: (string | { type: string, index: number })[], 
    violations: Violation[], 
    visibleViolations: boolean[], 
    anonymization: boolean
): string => {
    return promptParts
        .map(part => processPart(part, violations, visibleViolations, anonymization))
        .join('');
};

const Component: React.FunctionComponent<Props> = ({ prompt, violations, onSend, prompt_id, onClose }) => {

    const masqued = replaceViolations(prompt, violations)
    const [visibleViolations, setVisibleViolations] = React.useState<boolean[]>(new Array(violations.length).fill(false));
    const [refreshId, forceRefresh] = useRefreshId();
    const [reason, setReason] = React.useState<string | undefined>(undefined)
    const [, setReasonVisible] = React.useState<boolean>(false)
    const configuration = useSelector(selectConfiguration);

    const toggleVisibility = (index: number) => {
        if(!configuration.bypass) {
            return
        }
        
        const updatedVisibility = [...visibleViolations];
        updatedVisibility[index] = !updatedVisibility[index];
        setVisibleViolations(updatedVisibility);

        if (visibleViolations.includes(true) && reason === undefined) {
            setReasonVisible(true);
            return;
        }
    };

    const handleClose = () => {
        onClose(prompt);
    }

    const handleSend = () => {
        if (visibleViolations.includes(true) && reason === undefined) {
            setReasonVisible(true);
            return;
        }

        const finalPrompt = generateFinalPrompt(masqued, violations, visibleViolations, configuration.anonymization);
        const promptValue: PromptValue = {
            message: finalPrompt,
            message_original: prompt,
            reason: reason,
            protected: false,
            prompt_id: prompt_id
        }

        onSend(promptValue)
        forceRefresh()
    }

    const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value)
    }

    return (
        <Modal key={refreshId} title={"Some leaks detected - Pseudonymisation " + (!visibleViolations.some(Boolean) ? 'activated' : 'deactivated')} content={<p className="my-4 text-lg leading-relaxed">
            {masqued.map((part, index) => {
                if (typeof part === 'string') {
                    return <span key={index}>{part}</span>;
                } else if (part.type === 'violation') {
                    const violation = violations[part.index];
                    return (
                        <Toggable
                            key={index}
                            word={violation.word}
                            subcategory={violation.subcategorie}
                            isVisible={visibleViolations[part.index]}
                            onClick={() => toggleVisibility(part.index)}
                            substitution={configuration.anonymization ? violation.substitution : `[${violation.subcategorie.toUpperCase()}]`}
                        />

                    );
                }
                return null;
            })}
            {visibleViolations.some(Boolean) &&
                <textarea
                    placeholder="Justification mandatory"
                    onChange={onValueChange}
                    spellCheck
                    className="p-2 rounded-lg block mt-3 w-full"
                />
            }
        </p>} footer={
                <Button active={reason !== undefined && reason.length < 4 && visibleViolations.some(Boolean)} onClick={handleSend} title="Submit" />
        } onCloseClick={handleClose} />
    )
};

export default Component;