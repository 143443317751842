import * as React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux';
import { selectCurrentUser } from '../../redux/userReducer';

const ProtectedRoute = ({ element: Component, requiredProfile, ...rest }: any) => {

  const user = useAppSelector(selectCurrentUser);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (requiredProfile && !user.profiles.some((profile: { name: any; }) => profile.name === requiredProfile)) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;