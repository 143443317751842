import * as React from "react";
import TableCard from "@components/TableCard";
import Button from "@components/Button";
import { useSelector } from "react-redux";
import { addModelToConfiguration, selectModels } from "../../../../redux/configurationReducer";
import { addModel, Model } from "../../../../redux/allLLMReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux";

type Props = {
    models: Model[];
    onClick: ()=>void;
}

const Component: React.FunctionComponent<Props> = ({ models, onClick }) => {
    const [selectedItems, setSelectedItems] = React.useState<Model[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const modelsList = useSelector(selectModels);

    const modelsInListSet = modelsList ? new Set(modelsList.map((model: Model) => model.name)) : new Set([]);

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            const selectableItems = models.filter(model => !modelsInListSet.has(model.name));
            setSelectedItems(selectableItems);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (item: Model, checked: boolean) => {
        if (checked) {
            setSelectedItems((prev) => [...prev, item]);
        } else {
            setSelectedItems((prev) => prev.filter((i) => i !== item));
        }
    };

    const isItemSelected = (item: Model) => selectedItems.includes(item);

    const areAllSelectableItemsSelected = models
        .filter(model => !modelsInListSet.has(model.name))
        .every(model => isItemSelected(model));

    const addModels = () => {
        selectedItems.forEach((model) => {
            model.valid=true
            dispatch(addModelToConfiguration(model))
            dispatch(addModel(model))
        })
        onClick();
    }

    return (
        <TableCard
            title="Models"
            items={models}
            listSize={10}
            showStatusFilter={false}
            searchPlaceholder="Search for llm by name"
            columns={[
                {
                    header: (
                        <input
                            type="checkbox"
                            onChange={(e) => handleSelectAll(e.target.checked)}
                            checked={areAllSelectableItemsSelected}
                        />
                    ),
                    width: "w-12",
                    render: (_, item) => {
                        const isDisabled = modelsInListSet.has(item.name);
                        return (
                            <input
                                type="checkbox"
                                checked={isItemSelected(item)}
                                onChange={(e) => handleSelectItem(item, e.target.checked)}
                                disabled={isDisabled}
                            />
                        );
                    },
                    align: "center",
                },
                {
                    header: "Name",
                    accessor: "name",
                    width: "w-full",
                    render: (value, item) => {
                        const isDisabled = modelsInListSet.has(item.name);
                        return (
                            <span className={`lowercase ${isDisabled ? 'text-gray-400' : ''}`}>{value}</span>
                        )
                    }
                }
            ]}
            statusFilterElement={
                <Button title={`Add models (${selectedItems.length})`} onClick={addModels}/>
            }
        />
    )
};

export default Component;