import * as React from "react";
import Card from "../Card"
import { selectConfiguration } from "../../../../redux/configurationReducer";
import { FaUserGroup, FaMagento, FaBrain } from "react-icons/fa6";
import Evolution from "./evolution";
import { useSelector } from "react-redux";
import SubSection from "@components/Subsection";

type Props = object;

const Component: React.FunctionComponent<Props> = () => {

    const configuration = useSelector(selectConfiguration);

    const membersCount = configuration.organization.members.length || 0;
    const agentsCount = configuration.agentIds.length || 0;
    const modelsCount = configuration.models?.length || 0;

    return (
        <>
            <Card title="Overview" appendClass="hidden lg:block">
                <div className="flex flex-cols gap-x-4">
                    <SubSection title={
                        <div className="flex items-center">
                            <FaUserGroup size={24} color="#3b5998" />
                            <a href="#users" className="text-[#3b5998] text-2xl ml-2">Users</a>
                        </div>
                    } size="lg:flex-1">
                        <div className="flex flex-rows flex-1 items-end">
                            <span className="font-bold text-6xl">{membersCount}</span>
                            <Evolution value={-1} />
                        </div>
                    </SubSection>
                    <SubSection title={
                        <div className="flex items-center">
                            <FaMagento size={24} color="#3b5998" />
                            <a href="#agents" className="text-[#3b5998] text-2xl ml-2">Agents</a>
                        </div>
                    } size="flex-1">
                        <div className="flex flex-rows flex-1 items-end">
                            <span className="font-bold text-6xl">{agentsCount}</span>
                            <Evolution value={-1} />
                        </div>
                    </SubSection>
                    <SubSection title={
                        <div className="flex items-center">
                            <FaBrain size={24} color="#3b5998" />
                            <a href="#models" className="text-[#3b5998] text-2xl ml-2">Models</a>
                        </div>
                    } size="flex-1">
                        <div className="flex flex-rows flex-1 items-end">
                            <span className="font-bold text-6xl">{modelsCount}</span>
                            <Evolution value={-1} />
                        </div>
                    </SubSection>
                </div>
            </Card>
            <Card title={
                <div className="flex items-center">
                    <FaUserGroup size={24} color="#3b5998" />
                    <a href="#users" className="text-[#3b5998] text-2xl ml-2">Users</a>
                </div>
            } appendClass="lg:hidden">
                <div className="flex flex-rows flex-1 items-end">
                    <span className="font-bold text-6xl">{membersCount}</span>
                    <Evolution value={-1} />
                </div>
            </Card>

            <Card title={
                <div className="flex items-center">
                    <FaMagento size={24} color="#3b5998" />
                    <a href="#agents" className="text-[#3b5998] text-2xl ml-2">Agents</a>
                </div>
            } appendClass="lg:hidden mt-3">
                <div className="flex flex-rows flex-1 items-end">
                    <span className="font-bold text-6xl">{agentsCount}</span>
                    <Evolution value={-1} />
                </div>
            </Card>


            <Card title={
                <div className="flex items-center">
                    <FaBrain size={24} color="#3b5998" />
                    <a href="#models" className="text-[#3b5998] text-2xl ml-2">Models</a>
                </div>
            } appendClass="lg:hidden mt-3">
                <div className="flex flex-rows flex-1 items-end">
                    <span className="font-bold text-6xl">{modelsCount}</span>
                    <Evolution value={-1} />
                </div>
            </Card>
        </>
    )
}

export default Component;