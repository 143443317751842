import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";
import { LLMGroup } from "./allLLMGroupReducer";
import axios from "axios";

export type Key = {
    key: string;
    llm: LLMGroup;
};

type KeysState = {
    keys: Key[];
};

const initialState: KeysState = {
    keys: []
};

export const fetchKeys = createAsyncThunk<Key[], void, { rejectValue: string }>(
    "keys/fetchKeys",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await axios.get(`${API_URL}llm/groups/key`, { headers });
            const data: any =  await response.data;
            const keys: Key[] = data.keys;

            return keys;
        } catch (error) {
            return rejectWithValue("Failed to fetch Keys.");
        }
    }
);

const allKeySlice = createSlice({
    name: "llms",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKeys.fulfilled, (state, action) => {
            state.keys = action.payload;
        });
    }
});

export const getKeys = (state: RootState) => state.keyState.keys;
export default allKeySlice.reducer;
