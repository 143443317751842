import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";

export type Agent = {
    id: number;
    name: string;
    explanation: string;
    isInOrganization: boolean;
};

type AgentsState = {
    byId: { [id: number]: Agent };
    allIds: number[];
};

const initialState: AgentsState = {
    byId: {},
    allIds: []
};

// Fetch all agents
export const fetchAgents = createAsyncThunk<AgentsState, void, { rejectValue: string }>(
    "agents/fetchAgents",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}agents`, { headers });
            const data: any = await response.json();
            const agents: Agent[] = data.agents

            const normalizedData = agents.reduce<AgentsState>((acc, agent) => {
                acc.byId[agent.id] = agent;
                acc.allIds.push(agent.id);
                return acc;
            }, { byId: {}, allIds: [] });

            return normalizedData;
        } catch (error) {
            return rejectWithValue("Failed to fetch agents.");
        }
    }
);

// Fetch agents for the current organization
export const fetchAgentsForOrganization = createAsyncThunk<number[], void, { rejectValue: string }>(
    "agents/fetchAgentsForOrganization",
    async (_, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}`, { headers });
            const data: Agent[] = await response.json();

            const agentIds = data.map(agent => agent.id);
            return agentIds;
        } catch (error) {
            return rejectWithValue("Failed to fetch agents for organization.");
        }
    }
);

// Add an agent to the organization
export const addAgent = createAsyncThunk<number, number, { rejectValue: string }>(
    "agents/addAgent",
    async (agentId, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}/agent/${agentId}`, {
                method: "POST",
                headers,
            });
            if (!response.ok) {
                throw new Error("Failed to add agent to the organization.");
            }
            return agentId;
        } catch (error) {
            return rejectWithValue("Failed to add agent.");
        }
    }
);

// Remove an agent from the organization
export const removeAgent = createAsyncThunk<number, number, { rejectValue: string }>(
    "agents/removeAgent",
    async (agentId, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}`
            };
            const response = await fetch(`${API_URL}configuration/${state.user.user?.organization.slug}/agent/${agentId}`, {
                method: "DELETE",
                headers,
            });
            if (!response.ok) {
                throw new Error("Failed to remove agent from the organization.");
            }
            return agentId;
        } catch (error) {
            return rejectWithValue("Failed to remove agent.");
        }
    }
);

// Toggle agent organization status locally
export const toggleAgentOrganization = (agentId: number) => (dispatch: (arg0: { type: string; payload: { agentId: number; updatedAgent: any; }; }) => void, getState: () => any) => {
    const state = getState() as RootState;
    const agent = state.agents.byId[agentId];
    const updatedAgent = { ...agent, isInOrganization: !agent.isInOrganization };

    dispatch({
        type: "agents/toggleAgentOrganization",
        payload: { agentId, updatedAgent },
    });
};

const allAgentsSlice = createSlice({
    name: "agents",
    initialState,
    reducers: {
        // Handling the local toggle action
        toggleAgentOrganization(state, action: PayloadAction<{ agentId: number; updatedAgent: Agent }>) {
            state.byId[action.payload.agentId] = action.payload.updatedAgent;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAgents.fulfilled, (state, action) => {
            state.byId = action.payload.byId;
            state.allIds = action.payload.allIds;
        });
        builder.addCase(addAgent.fulfilled, (state, action) => {
            state.byId[action.payload].isInOrganization = true;
        });
        builder.addCase(removeAgent.fulfilled, (state, action) => {
            state.byId[action.payload].isInOrganization = false;
        });
    }
});

export const selectCombinedAgents = createSelector(
    (state: RootState) => state.agents.byId,
    (state: RootState) => state.agents.allIds,
    (state: RootState) => state.configurationState.configuration?.agentIds || [],
    (agentsById, allAgentIds, organizationAgentIds) => {
        console.log(`agentsById: ${agentsById}\nallAgentIds: ${allAgentIds}\norganizationAgentIds: ${organizationAgentIds}`)
        return allAgentIds.map((id: number) => ({
            ...agentsById[id],
            isInOrganization: organizationAgentIds.includes(id)
        }));
    }
);

export const { toggleAgentOrganization: toggleAgentOrganizationLocal } = allAgentsSlice.actions;
export default allAgentsSlice.reducer;
