import * as React from "react";
import Card from "../Card"
import { Regulatory, ReportOrganization } from "pages/GRC";
import ChartOneBar from "@components/ChartOneBar";

type Props = {
    report: ReportOrganization
};

const Component: React.FunctionComponent<Props> = (report) => {

    /*const filterRegulationsWithByPassViolations = (data: Regulatory[]) => {
        const regulationsWithViolationCounts = data.map((regulation) => {
            const totalCategories = regulation.protected.length + regulation.byPass.length;
            const violatedCategories = regulation.protected.filter(category => category.count > 0).length +
                regulation.byPass.filter(category => category.count > 0).length;

            return {
                title: regulation.title,
                violatedCategories,
                totalCategories,
            };
        });

        const sortedRegulations = regulationsWithViolationCounts.sort((a, b) => b.violatedCategories - a.violatedCategories);

        return sortedRegulations
    };*/

    const filterRegulationsWithByPassViolations = (data: Regulatory[]) => {
        const regulationsWithViolationCounts = data.map((regulation) => {
            const totalCategories = regulation.byPass.length;
            const violatedCategories = regulation.byPass.filter(category => category.count > 0).length;

            return {
                title: regulation.title,
                violatedCategories,
                totalCategories,
            };
        });

        const sortedRegulations = regulationsWithViolationCounts.sort((a, b) => b.violatedCategories - a.violatedCategories);

        return sortedRegulations
    };

    const filteredRegulations = filterRegulationsWithByPassViolations(report.report.prompts.regulations);

    return (
        <Card title="Compliance status">
            <div className="grid grid-cols-2 justify-center gap-3 sm:grid-cols-4 sm:gap-6">
                {filteredRegulations && filteredRegulations.map((regulation, index) => {
                    return (
                        <div className={`flex justify-center w-full gap-x-4 ${regulation.violatedCategories === 0 && 'hidden'}`} key={index}>
                            <div className="flex flex-col w-full">
                                <div className="ml-2 font-bold">{regulation.title}</div>
                                <div className="w-full">
                                    <ChartOneBar firstElement={regulation.violatedCategories} totalElements={regulation.totalCategories} redTooltip={`${regulation.violatedCategories} categories of ${regulation.totalCategories} violated`} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Card>
    )
}

export default Component;