import * as React from "react";
import Card from "../Card"
import { AppDispatch, useAppSelector } from "../../../../redux";
import { selectConfiguration, togglePassthrough } from "../../../../redux/configurationReducer";
import { useDispatch } from "react-redux";
import { useRefreshId } from "hooks/useRefreshId";

type Props = object;

type PassthroughProps = {
    onPassthrough: () => void
}

const Passthrough: React.FunctionComponent<PassthroughProps> = ({ onPassthrough }) => {

    const configuration = useAppSelector(selectConfiguration)
    const [passthroughClass, setPassthroughClass] = React.useState<string>("bg-gray-300 text-gray-700 font-bold ml-2 pr-4 rounded-full w-1/2 uppercase h-8");
    const dispatch = useDispatch<AppDispatch>();
    const [label, setLabel] = React.useState<string>("deactivate")

    React.useEffect(() => {
        let classname = configuration.passthrough ? "bg-green-300 text-green-700 font-bold sm:ml-2 sm:pr-4 rounded-full w-1/2 uppercase h-8" : "bg-gray-300 text-gray-700 font-bold sm:ml-2 sm:pr-4 rounded-full w-1/2 uppercase h-8";
        setPassthroughClass(classname);
        setLabel(configuration.passthrough ? "operational" : "deactivate")
    }, [configuration.passthrough])

    const onTogglePassthrough = () => {
        dispatch(togglePassthrough())
        onPassthrough();
    }

    return (
        <div className="flex-1 text-center justify-center items-center ">
            <div className="text-[#3b5998] text-2xlmb-4 text-center w-full hidden sm:block">
                Passthrough
            </div>
            <button className={passthroughClass} onClick={() => onTogglePassthrough()}>
                {label}
            </button>
        </div>
    )
}

const Component: React.FunctionComponent<Props> = () => {

    const [refreshId, forceRefresh] = useRefreshId();

    const onPassthrough = () => {
        forceRefresh();
    }

    return (
        <>
            <Card title="Status" appendClass="hidden sm:block">
                <div className="flex flex-cols gap-x-4">
                    <div className="flex-1 text-center justify-center items-center ">
                        <div className="text-[#3b5998] text-2xl mb-4 text-center w-full">
                            ProxyGPT service
                        </div>
                        <button className="bg-green-300 text-green-700 font-bold ml-2 pr-4 rounded-full w-1/2 uppercase h-8">
                            operational
                        </button>
                    </div>
                    <div className="flex-1 text-center justify-center items-center ">
                        <div className="text-[#3b5998] text-2xl mb-4 text-center w-full">
                            Agents
                        </div>
                        <button className="bg-green-300 text-green-700 font-bold ml-2 pr-4 rounded-full w-1/2 uppercase h-8">
                            operational
                        </button>
                    </div>
                    <Passthrough key={refreshId} onPassthrough={onPassthrough} />
                </div>
            </Card>

            <Card title={
                <div className="flex-1 text-center justify-center items-center ">
                    <div className="text-[#3b5998] text-2xl mb-4 text-center w-full">
                        ProxyGPT service
                    </div>
                </div>
            } appendClass="sm:hidden text-center justify-center items-center">
                <button className="bg-green-300 text-green-700 font-bold rounded-full w-1/2 uppercase h-8">
                    operational
                </button>
            </Card>

            <Card title={
                <div className="flex-1 text-center justify-center items-center ">
                    <div className="text-[#3b5998] text-2xl mb-4 text-center w-full">
                        Agents
                    </div>
                </div>
            } appendClass="sm:hidden text-center justify-center items-center mt-3">
                <button className="bg-green-300 text-green-700 font-bold rounded-full w-1/2 uppercase h-8">
                    operational
                </button>
            </Card>
            <Card title={
                <div className="flex-1 text-center justify-center items-center ">
                    <div className="text-[#3b5998] text-2xl mb-4 text-center w-full">
                        Passthrough
                    </div>
                </div>
            } appendClass="sm:hidden text-center justify-center items-center mt-3">
                <Passthrough key={refreshId} onPassthrough={onPassthrough} />
                </Card>
        </>
    )
}

export default Component;