import * as React from "react";
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik } from "formik";
import { register, login } from "../../services/auth.service";
import FieldForm from "./components/Field";
import Image from "./components/Image";
import Logo from "../../components/Logo";
import Spinner from "../../components/Spinner";
import './components/Field/style.css';

type Props = object;

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Component: React.FunctionComponent<Props> = () => {

    const navigate: NavigateFunction = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");
    const initialValues = { password: "", email: "" };
    const pack = useQuery().get('pack')

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("This field is required!"),
        password: Yup.string().required("This field is required!"),
    });

    const handleRegister = (formValue: { password: string, email: string }) => {
        const { email, password } = formValue;

        setMessage("");
        setLoading(true);
        register(email, password).then(
            () => {
                login(email, password).then(
                    () => {
                        if (pack !== null) {
                            navigate('/register/organization?pack=' + pack)
                        }
                        else {
                            navigate('/')
                        }
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setLoading(false);
                        setMessage(resMessage);
                    }
                )
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    };


    return (
        <>
            <div className="flex flex-col w-full h-screen justify-between">
                <main className="flex mb-auto h-screen">
                    <section id="form" className="w-1/2 place-content-center">
                        <div className="max-w-sm mx-auto">
                            <div className="flex items-center">
                                <Logo width={127} height={29} />
                            </div>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleRegister}>
                                {({ isSubmitting, handleSubmit }) =>
                                    <form onSubmit={handleSubmit}>
                                        <FieldForm name="email" label="Email" type="text" />
                                        <FieldForm name="password" label="Password" type="password" className="mt-4 mb-4" />

                                        <button type="submit" disabled={isSubmitting} className="w-full text-white bg-zinc-600 hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                            {loading && (
                                                <Spinner />
                                            )}
                                            <span>Register</span>
                                        </button>

                                        {message && (
                                            <div className="form-group">
                                                <div className="alert alert-danger" role="alert">
                                                    {message}
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                }
                            </Formik>
                            <div className="mt-6 text-gray-600">
                                Vous avez déjà un compte? <Link to="/login" className="text-blue-600 hover:underline">Connexion</Link>
                            </div>
                        </div>
                    </section>
                    <section id="image" className="w-1/2" style={{ position: "relative" }}>
                        <Image />
                    </section>
                </main>
                <footer className="bg-gray-800 dark:bg-white rounded-lg shadow m-4">
                    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                        <span className="text-sm text-gray-400 sm:text-center dark:text-gray-500">Langue</span>
                        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">About</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
                            </li>
                            <li>
                                <a href="#" className="hover:underline">Contact</a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        </>
    );

};

export default Component;
