import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import ConversationReducer from "./allConversationsReducer";
import MessageReducer from "./allMessagesReducer";
import UserReducer from "./userReducer";
import MemberReducer from "./allMemberReducer";
import LLMReducer from "./allLLMReducer";
import AgentReducer from "./allAgentReducer";
import UserReportReducer from "./reportUserReducer";
import ConfigurationReducer from "./configurationReducer";
import AuditReducer from "./auditReducer";
import LLMGroupReducer from "./allLLMGroupReducer";
import KeyReducer from "./allKeyReducer";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, UserReducer);

export const store = configureStore({
    reducer: {
        conversations: ConversationReducer,
        messages: MessageReducer,
        user: persistedAuthReducer,
        members: MemberReducer,
        llms: LLMReducer,
        agents: AgentReducer,
        report: UserReportReducer,
        configurationState: ConfigurationReducer,
        auditState: AuditReducer,
        llmGroupsState: LLMGroupReducer,
        keyState: KeyReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            }
        })
})

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
