import * as React from "react";
import Button from "@components/Button";
import { User } from "../../../redux/userReducer";
import { API_URL } from "constant";

type Props = {
    generateQrCode: () => void
    user: User
}

const Component: React.FunctionComponent<Props> = ({ generateQrCode, user }) => {

    const disable = async () => {
        const headers = { 'Authorization': `Bearer ${user?.token}` }
        await fetch(`${API_URL}user/otp/${user.id}`, { method: 'DELETE', headers: headers })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return
                }
                return response.json()
            })
            .then((data) => {
                //dispatch(updateProfile(profile));
            })
    }

    return (
        <div className="grid grid-cols-4 gap-4 items-center">
            <div className="col-span-3">
                <div className="font-semibold">Multi-factor authentication</div>
                <div className="text-sm text-justify">
                    Ask for an additional security step when logging in. If you fail to pass this step, you'll have the option of recovering your account by e-mail.
                </div>
            </div>
            <div className="">
                {user.mfa ? 
                (<Button title="Deactivate" onClick={() => disable()} color="error"/>) 
                :
                (<Button title="Activate" onClick={() => generateQrCode()} />)
                }
            </div>
        </div>
    )
};

export default Component;