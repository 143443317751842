import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { API_URL } from "../constant";
import { SimpleUSer } from "./userReducer";

export type Member = {
    id: number
    username: string
    email: string
    image: string
}

type MemberState = {
    membersList: Member[]
    loading: boolean;
    error: string | null;
}

const initialState:MemberState = {
    membersList: [],
    loading: false,
    error: null,
}

export const fetchMembers = createAsyncThunk<Member[], void, { rejectValue: string }>(
    "members/fetchMembers",
    async (_, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(`${API_URL}organization/${state.user.user?.organization.slug}`, { headers });
            const organization: any = await response.json();
            return organization.organization.members
        } catch (error) {
            return rejectWithValue("Failed to fetch members.");
        }
    }
);

export const deleteMember = createAsyncThunk<void, number, { rejectValue: string }>(
    "members/deleteMember",
    async (id, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(`${API_URL}organization/${state.user.user?.organization.slug}/member/${id}`, { method: 'delete', headers: headers });
            if (!response.ok) {
                const resp = await response.json()
                console.log(resp)
                throw new Error('Failed to invite member');
            }
            await response.json();
        } catch (error) {
            return rejectWithValue("Failed to delete member.");
        }
    }
);

export const inviteMember = createAsyncThunk<void, string, { rejectValue: string }>(
    "members/inviteMember",
    async (mail, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(`${API_URL}organization/${state.user.user?.organization.slug}/invitation/${mail}`, { method: 'post', headers: headers });
            if (!response.ok) {
                const resp = await response.json()
                if(response.status === 409) {
                    console.log(resp.split("] ")[1])
                    throw new Error(resp.split("] ")[1]);
                }
                console.log(resp)
                throw new Error('Failed to invite member');
            }
            await response.json();
        } catch (error) {
            return rejectWithValue("Failed to invite member.");
        }
    }
);

export const deleteInvitation = createAsyncThunk<void, string, { rejectValue: string }>(
    "members/inviteMember",
    async (mail, {rejectWithValue, getState}) => {
        const state = getState() as RootState
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
            const response = await fetch(`${API_URL}organization/${state.user.user?.organization.slug}/invitation/${mail}`, { method: 'delete', headers: headers });
            if (!response.ok) {
                const resp = await response.json()
                if(response.status === 409) {
                    console.log(resp.split("] ")[1])
                    throw new Error(resp.split("] ")[1]);
                }
                console.log(resp)
                throw new Error('Failed to delete invitation');
            }
            await response.json();
        } catch (error) {
            return rejectWithValue("Failed to delete invitation.");
        }
    }
);

export const updateUserThunk = createAsyncThunk<SimpleUSer, {userId:number; updatedUserData: SimpleUSer}, { rejectValue: string }> (
    "configuration/updateUser",
    async ({ userId, updatedUserData }, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${state.user.user?.token}` // Assume que le token est stocké dans l'objet `user`
            };

            const response = await fetch(`${API_URL}user/${userId}`, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify({
                    user: updatedUserData
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Failed to update user');
            }

            const updatedUser = await response.json();
            return updatedUser;

        } catch (error) {
            return rejectWithValue('Failed to update user');
        }
    }
);


const allMembersSlice = createSlice({
    name: "members",
    initialState,
    reducers: {
        delMember(
            state,
            action: PayloadAction<number>
        ) {
            const index = state.membersList.findIndex(member => member.id === action.payload);
            if(index > -1){
                state.membersList.splice(index, 1)
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchMembers.fulfilled, (state, action) => {
            state.membersList = action.payload
        });
        builder.addCase(updateUserThunk.fulfilled, (state, action) => {
            if (state.membersList) {
                // Mettre à jour la liste des membres
                const updatedUser = action.payload;
                state.membersList = state.membersList.map(member =>
                    member.id === updatedUser.id ? updatedUser : member
                );
            }
        });
        builder.addCase(updateUserThunk.rejected, (state, action) => {
            state.loading = false;
        });
    }
})

export const { delMember } = allMembersSlice.actions;
export const getMembers = (state: RootState): Member[] => state.members.membersList
export default allMembersSlice.reducer;