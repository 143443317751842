import * as React from "react";
import { Tooltip } from "flowbite-react";

type Props = {
    word: string;
    subcategory: string;
    isVisible: boolean;
    substitution: string;
    onClick: () => void;
};

const Component: React.FunctionComponent<Props> = ({ word, subcategory, isVisible, substitution, onClick }) => {
    
    return (
        <>
            {isVisible ? (
                <span className="px-2 py-1 rounded-md">
                    <span onClick={onClick} className="hover:underline cursor-pointer inline">
                        {word}
                    </span>
                    <span className="uppercase bg-teal-500/25 px-0.5 rounded-md text-xs select-none">
                        {subcategory.slice(0, 3)}
                    </span>
                </span>
            ) : (
                <div className="inline-flex">
                    <Tooltip content={substitution} animation="duration-500" className="inline-flex">
                        <span className="px-2 py-1 rounded-md bg-teal-300/25">
                            <span onClick={onClick} className="hover:underline cursor-pointer">
                                {word}
                            </span>
                            <span className="uppercase bg-teal-500/25 px-0.5 rounded-md text-xs select-none">
                                {subcategory.slice(0, 3)}
                            </span>
                        </span>
                    </Tooltip>
                </div>
            )}
        </>
    )
};

export default Component;