import * as React from "react";

import { useAppDispatch, useAppSelector } from "../../../redux";
import { selectCurrentUser, updateProfile, User, userMe } from "../../../redux/userReducer";
import { FaCheck, FaCircleXmark } from "react-icons/fa6";
import TextInput from "@components/Input/textInput";
import Button from "@components/Button";
import { updateUserThunk } from "../../../redux/allMemberReducer";

type Props = {
    closed: ()=>void
};

const Component: React.FunctionComponent<Props> = ({ closed }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectCurrentUser);

    const [profile, setProfile] = React.useState<User>(user)


    React.useEffect(() => {
        dispatch(userMe())
    }, [dispatch])

    const handleInputChange = (field: string, value: string) => {
        if (profile) {
            setProfile({ ...profile, [field]: value });
        }
    };

    const updateUser = async () => {
        if (!profile) {
            return;
        }

        //const profiles = selectedOptions.map(option => {return {"name": option}})

        const updatedUserData = {
            id: profile.id,
            username: profile.username,
            firstname: profile.firstname,
            lastname: profile.lastname,
            email: profile.email,
            image: "",
            profiles: profile.profiles,
        };

        // Appel à l'action Redux pour mettre à jour l'utilisateur
        dispatch(updateUserThunk({
            userId: profile.id,
            updatedUserData,
        }))
            .unwrap()
            .then(() => {
                // Fermer la modal après la mise à jour réussie
                dispatch(updateProfile(profile));
                closed();
            })
            .catch((error: any) => {
                console.error('Failed to update user:', error);
            });
    };

    return (
        <form className="max-w-md mx-auto">
            <div className="mb-5">
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                <TextInput onValueChange={(value) => handleInputChange("username", value)} id="username" inputValue={profile?.username} />
            </div>
            <div className="flex flex-cols gap-4">
                <div className="mb-5 flex-1">
                    <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Firstname</label>
                    <TextInput onValueChange={(value) => handleInputChange("firstname", value)} id="firstname" inputValue={profile?.firstname} />
                </div>
                <div className="mb-5 flex-1">
                    <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lastname</label>
                    <TextInput onValueChange={(value) => handleInputChange("lastname", value)} id="lastname" inputValue={profile?.lastname} />
                </div>
            </div>
            <div className="mb-5">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <TextInput onValueChange={(value) => handleInputChange("email", value)} id="email" inputValue={profile?.email} disabled />
            </div>
            <div className="items-center justify-center text-center">
                <Button title="Save" icone={<FaCheck />} onClick={() => updateUser()} />
                <Button icone={<FaCircleXmark />} title="Cancel" color="error" />
            </div>
        </form>
    );
};

export default Component;
