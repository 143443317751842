import React from 'react';
import { useFormContext } from 'react-hook-form';

type FormInputProps = {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
};

const FormInput: React.FC<FormInputProps> = ({ label, name, type = 'text', placeholder = ' ' }) => {

  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      <label htmlFor={name} className='block text-ct-blue-600 mb-3 font-semibold sm:font-normal'>
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        className='block w-full rounded-lg sm:rounded-2xl appearance-none focus:outline-none py-2 px-4'
        {...register(name)}
      />
      {errors[name] && (
        <span className='text-red-500 text-xs pt-1 block'>
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};

export default FormInput;