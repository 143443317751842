import * as React from "react";

import { LLMResponse } from "../../../../../../redux/allMessagesReducer";
import Markdown from "react-markdown";
import ToolBar from "../ToolBar";
import { Tooltip } from "@material-tailwind/react";
import { GiRegeneration } from "react-icons/gi";
import Spinner from  "../../../../../../components/Spinner";

type Props = {
    response: LLMResponse | undefined
    loading: boolean
}

const Component: React.FunctionComponent<Props> = ({ response, loading }) => {

    return (
        <div className="lg:col-start-1 text-gray-800 rounded-md p-4">
            <div className="flex items-start gap-2.5">
                <div className="ml-16 icon-md h-4 w-4">
                    <div className="rounded-full w-10 h-10 bg-blue-100 translate-x-2">
                        <div className="pt-3 pl-3">
                            { response && <div className="h-4 w-4" dangerouslySetInnerHTML={{ __html: response.avatar }} />}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full lg:max-w-[calc(100%-16rem)] leading-1.5 p-4 border-gray-200 bg-blue-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                    <div className="flex items-center space-x-2 rtl:space-x-reverse z-30">
                        <span className="text-sm font-semibold text-gray-900 dark:text-white">{response && response.model}</span>
                    </div>
                    <p className="text-sm font-normal py-2.5 text-gray-900 dark:text-white">
                        {loading ? <Spinner /> : (<Markdown>{response && response.response}</Markdown>)}
                    </p>
                    <ToolBar>
                        <Tooltip content="Regenerate" placement="bottom">
                            <button className="rounded-lg flex h-[30px] w-[30px] items-center justify-center">
                                <GiRegeneration className="hover:animate-spin" />
                            </button>
                        </Tooltip>
                    </ToolBar>
                </div>
            </div>
        </div>
    )
};

export default Component;