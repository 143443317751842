import * as React from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import QRCode from "qrcode";
import { toast } from "react-toastify";
import Button from "@components/Button";
import { User } from "../../../redux/userReducer";
import { API_URL } from "constant";

type Props = {
    otpauth_url: string;
    base32: string;
    user: User
    setModalVisible: (visible:boolean)=>void
}

type Inputs = {
    token: string
}

const Component: React.FunctionComponent<Props> = ({ otpauth_url, base32, user, setModalVisible }) => {

    const [qrcodeUrl, setqrCodeUrl] = React.useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>()

    React.useEffect(() => {
        if (otpauth_url && otpauth_url !== '') {
            QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);
        }
    }, [otpauth_url]);

    const verifyOtp = async (token: string) => {
        const headers = { 'Authorization': `Bearer ${user?.token}`, 'Accept': 'application/json', 'Content-Type': 'application/json' }
        try {
            let response = await fetch(`${API_URL}user/otp/verify`, { method: 'POST', headers: headers, body: JSON.stringify({"otp": { "token": token, "user_id": user.id }}) })
            if (!response.ok) throw response.statusText;
            toast.success("Two-Factor Auth Enabled Successfully", { position: "top-right" });
            setModalVisible(false);
        } catch (e:any) {
            toast.error(e, { position: "top-right" });
        }
    }

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        verifyOtp(data.token);
    }

    return (
        <div className="relative bg-white rounded-lg shadow">
            <h4>Two-Factor Authentication (2FA)</h4>
            <div className="p-6 space-y-4">
                <h5>
                    Configuring Google Authenticator or Authy
                </h5>
                <div>
                    <li>
                        Install Google Authenticator (IOS - Android) or Authy (IOS -
                        Android).
                    </li>
                    <li>In the authenticator app, select "+" icon.</li>
                    <li>
                        Select "Scan a barcode (or QR code)" and use the phone's camera
                        to scan this barcode.
                    </li>
                </div>
                <img
                    className="block w-64 h-64 object-contain justify-center"
                    src={qrcodeUrl}
                    alt="qrcode url"
                />
                <div>
                    <h3>Or Enter Code Into Your App</h3>
                    <p className="text-sm">SecretKey: {base32} (Base32 encoded)</p>
                </div>
                <div>
                    <h3>Verify Code</h3>
                    <p className="text-sm">
                        For changing the setting, please verify the authentication code:
                    </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        {...register("token")}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/4 p-2.5"
                        placeholder="Authentication Code"
                    />
                    <p className="mt-2 text-xs text-red-600">
                        {errors.token ? errors.token.message : null}
                    </p>

                    <div>
                        <Button title="Verify & Activate" isSubmit={true} />
                    </div>
                </form>
            </div>
        </div>
    )
};

export default Component;