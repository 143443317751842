import * as React from "react";

type Props = {
    value: number;
}

const Component: React.FunctionComponent<Props> = ({value}) => {

    const fleche = value >= 0 ? 
        (<svg width="18" height="18" viewBox="0 0 18 18" className="fill-green-400" xmlns="http://www.w3.org/2000/svg"><path d="M5.01554 13.5008L11.4697 7.0467V12.9758L12.9705 12.9705V4.4852H4.48521V5.98073L10.409 5.98604L3.95488 12.4402L5.01554 13.5008Z"></path></svg>)
        :(<svg width="18" height="18" viewBox="0 0 18 18" className="fill-red-400" xmlns="http://www.w3.org/2000/svg"><path d="M5.01554 13.5008L11.4697 7.0467V12.9758L12.9705 12.9705V4.4852H4.48521V5.98073L10.409 5.98604L3.95488 12.4402L5.01554 13.5008Z"></path></svg>)

    const textColor = value > 0 ? "text-green-400" : "text-red-400"
    return (
        <div>
            <div className={`${textColor} flex flex-cols`} >
                {fleche} {value}%
            </div>
            <div className="">
                last 7 days
            </div>
        </div>
    )
}

export default Component;