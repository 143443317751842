import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux";
import { delModel, fetchLLMs, fetchLLMsForOrganization, Model } from "../../../../redux/allLLMReducer";
import { useDispatch } from "react-redux";
import TableCard from "@components/TableCard";
import AddLLM from "./addLLM";
import ListKeys from "./listKeys";
import { removeModelFromConfiguration, selectConfiguration, selectModels, toggleAnonymization, validateLLMs } from "../../../../redux/configurationReducer";
import { FaTriangleExclamation, FaTrashCan } from "react-icons/fa6";
import OpenAI from "./openai";
import Toggle from "@components/Toggle";

type Props = object

type LLMIconMap = {
    [key: string]: React.ReactNode;
};

type LLMLabelMap = {
    [key: string]: string;
}

const llmIcons: LLMIconMap = {
    ChatGPT: <OpenAI height={24} width={24} />
}

const llmLabels: LLMLabelMap = {
    ChatGPT: "OpenAI"
}

export const getIcon = (llmGroup: string): React.ReactNode => {
    return llmIcons[llmGroup] || (<FaTriangleExclamation size={24} color="red-500" />);
}

export const getLabel = (llmGroup: string): string => {
    return llmLabels[llmGroup] || llmGroup;
};

const getValidationIcon = (isValid: boolean | undefined): React.ReactNode => {
    return isValid ? null : <FaTriangleExclamation size={24} color="red" className="ml-2" />;
};

const Component: React.FunctionComponent<Props> = () => {

    const dispatch = useDispatch<AppDispatch>();
    const modelsList = useSelector(selectModels);
    const configuration = useSelector(selectConfiguration);

    useEffect(() => {
        dispatch(fetchLLMs())
        dispatch(fetchLLMsForOrganization())
        dispatch(validateLLMs())
    }, [dispatch])

    const removeModel = (id: number, model: Model) => {
        dispatch(removeModelFromConfiguration(model))
        dispatch(delModel(model))
    }

    const onToggleAnonymization = () => {
        dispatch(toggleAnonymization())
    }

    return (
        <div className="lg:grid lg:grid-cols-4">
            <div className="lg:col-start-2 lg:col-span-2">
                <TableCard<Model>
                    title="Models"
                    helpText="Help"
                    items={modelsList}
                    listSize={25}
                    showStatusFilter={false}
                    searchPlaceholder="Search for model by name"
                    columns={[
                        {
                            header: "Name",
                            accessor: "name",
                            width: "w-full",
                            render: (value, item) => (
                                <div className="flex items-center">
                                    {getIcon(item.llm.name)}
                                    <span className="lowercase ml-4">{value}</span>
                                    {getValidationIcon(item.valid)}
                                </div>
                            )
                        },
                        {
                            header: "",
                            accessor: "id",
                            width: "w-12",
                            align: "center",
                            render: (value, item) => {
                                return (
                                    <FaTrashCan size={24} color="#F00" onClick={() => removeModel(value, item)} />
                                )
                            }
                        }
                    ]}
                >
                    <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
                        <div className="mb-4">
                            <AddLLM />
                            <ListKeys />
                        </div>
                        <div className="mb-2">
                            <Toggle label="Pseudonymization" labelClass="font-bold h2" checked={configuration.anonymization} onChange={() => onToggleAnonymization()} />
                        </div>
                    </div>
                </TableCard>
            </div>
        </div>
    )
};

export default Component;