import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "react-redux-typescript";
import { RootState } from ".";
import { API_URL } from "../constant";

type Violation = {
    ID: number;
    Name: string;
    Explanation: string;
    Slug: string;
}

export type Usage = {
    Prompts: number;
    Violations: number;
    Protected: number,
    Users: number,
}

export type ByCategoryAndSubCategory = {
    category: Violation;
    number: number;
}

export type RegulationItem = {
    category: string;
    count: number;
    description: string;
    article: string;
}

export type Regulation = {
    title: string;
    description: string;
    protected: RegulationItem[];
    byPass: RegulationItem[];
}

type Prompts = {
    usage: Usage;
    detail: Detail;
    byCategoryAndSubCategory: ByCategoryAndSubCategory[]
    regulations: Regulation[]
}

type ByCategory = {
    count: number;
    categorie: string;
}

export type Protected = {
    violations: ByCategory[];
    date: string;
}

export type Detail = {
    protected: Protected[];
    byPass: Protected[];
}

export type Report = {
    start_date: string;
    end_date: string;
    prompts: Prompts;
};

type ReportState = {
    report: Report;
    loading: boolean;
    error: string | undefined;
}

const initialState: ReportState = {
    report: {
        start_date: "",
        end_date: "",
        prompts: {
            usage: {
                Prompts: 0,
                Violations: 0,
                Protected: 0,
                Users: 0
            },
            detail: {
                protected: [],
                byPass: []
            },
            byCategoryAndSubCategory: [
            ],
            regulations: []
        },
    },
    loading: false,
    error: undefined
}

export const getUserReport = createAsyncThunk<Report, string | undefined, { rejectValue: string }>(
    "user/report",
    async (date, { rejectWithValue, getState }) => {
        const state = getState() as RootState
        let tomorrow = date;

        if (tomorrow === undefined) {
            const temp = new Date()
            temp.setDate(temp.getDate() + 1);
            const day = temp.getDate().toString().padStart(2, '0');
            const month = (temp.getMonth() + 1).toString().padStart(2, '0');
            const year = temp.getFullYear();
            tomorrow = `${year}/${month}/${day}`;
        }
        try {
            const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }

            const response = await fetch(`${API_URL}report/user?end=${tomorrow}`, { headers });
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            const report = await response.json()
            return report
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('An unexpected error occurred');
        }
    }
)

const reportSlice = createSlice({
    name: "user_report",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserReport.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(getUserReport.fulfilled, (state, action) => {
                state.loading = false;
                state.report = action.payload
            })
            .addCase(getUserReport.rejected, (state, action: PayloadAction<string, string | undefined>) => {

            })
    }

});

export const selectReportUser = (state: RootState) => state.report.report;
export default reportSlice.reducer;