import * as React from "react";

import { useAppSelector } from "../../redux";
import { selectCurrentUser } from "../../redux/userReducer";
import { Detail, Report } from "../../redux/reportUserReducer";
import { useNavigate } from "react-router-dom";
import { API_URL } from "constant";

import SecureScore from "./components/Compliance/securescore";
import Recommandation from "./components/Categories/recommandation"
import Resource from "./components/Categories/resource"
import Security from "./components/Categories/security"
import Categories from "./components/Awareness/categories";
import Recommended from "./components/Awareness/recommended";
import H1 from "@components/Header/h1";

type Props = object;

export type SeriesItem = {
    categorie: string;
    protected: number;
    byPass: number;
};

export type Category = {
    name: string;
    count: number;
    campaign?: Campaign;
}

export type Campaign = {
    id: number;
    theme: string;
    focus: string;
    activities: string;
}

const transformData = (detail: Detail): SeriesItem[] => {
    const series: SeriesItem[] = [];

    // Un objet pour faciliter l'accès et l'accumulation des données
    const violationsMap: { [key: string]: { protected: number; byPass: number } } = {};

    // Traiter les violations dans "protected"
    detail.protected.forEach(item => {
        item.violations.forEach(violation => {
            if (!violationsMap[violation.categorie]) {
                violationsMap[violation.categorie] = { protected: 0, byPass: 0 };
            }
            violationsMap[violation.categorie].protected += violation.count;
        });
    });

    // Traiter les violations dans "byPass"
    detail.byPass.forEach(item => {
        item.violations.forEach(violation => {
            if (!violationsMap[violation.categorie]) {
                violationsMap[violation.categorie] = { protected: 0, byPass: 0 };
            }
            violationsMap[violation.categorie].byPass += violation.count;
        });
    });

    // Construire la série à partir de la carte des violations
    for (const categorie in violationsMap) {
        const protectedCount = violationsMap[categorie].protected;
        const byPassCount = violationsMap[categorie].byPass;

        series.push({
            categorie,
            protected: protectedCount - byPassCount,
            byPass: byPassCount,
        });
    }

    return series;
};

const Dashboard: React.FunctionComponent<Props> = () => {

    const user = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [report, setReport] = React.useState<Report | null>(null)

    React.useEffect(() => {
        const reportUserFetcher = async () => {
            const temp = new Date()
            temp.setDate(temp.getDate() + 1);
            const day = temp.getDate().toString().padStart(2, '0');
            const month = (temp.getMonth() + 1).toString().padStart(2, '0');
            const year = temp.getFullYear();
            const tomorrow = `${year}/${month}/${day}`;
            const headers = { 'Authorization': `Bearer ${user?.token}` }
            await fetch(`${API_URL}report/user?end=${tomorrow}`, { headers })
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        navigate("/")
                    }
                    return response.json()
                })
                .then((data) => {
                    setReport(data);
                })
        };

        if (user?.token !== "") {
            reportUserFetcher();
        }
    }, [navigate, user, user?.token])

    const series: SeriesItem[] = report ? transformData(report.prompts.detail) : []

    const [categories, setCategories] = React.useState<[string, Category][]>([]);

    React.useEffect(() => {

        const computeCampaign = async (category: string) => {

            try {
                const headers = {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${user?.token}`
                };
                const response = await fetch(`${API_URL}campaign/${category}`, { headers });
                const data: any = await response.json();
                const campaign: Campaign = data.campaign

                return campaign
            } catch (error) {
                return undefined
            }
        }

        const computeByPass = async () => {

            if (!report?.prompts.detail.byPass) {
                return;
            }

            const categoryCounts: Record<string, Category> = {};

            for (const bypass of report.prompts.detail.byPass) {
                for (const violation of bypass.violations) {
                    const { categorie, count } = violation;
                    if (categoryCounts[categorie]) {
                        categoryCounts[categorie].count += count
                    } else {
                        const campaign = await computeCampaign(categorie);
                        categoryCounts[categorie] = {
                            name: categorie,
                            count: count,
                            campaign: campaign
                        };
                    }
                }
            }

            const sorted = Object.entries(categoryCounts).sort((a, b) => b[1].count - a[1].count);
            setCategories(sorted);
        };

        computeByPass();
    }, [report, user?.token])

    return (
        <div className="pl-3 pr-3 xl:p-4 xl:mt-14">
            <div className="flex flex-col gap-y-3 xl:gap-y-6">
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <H1 text="My Dashboard" />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        {report && <SecureScore violations={report.prompts.usage.Violations} total={report.prompts.usage.Violations + report.prompts.usage.Protected} />}
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Recommandation violations={report?.prompts.usage.Violations} blocked={report?.prompts.usage.Protected} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Resource series={series} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Security series={series} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Categories categories={categories} />
                    </div>
                </div>
                <div className="xl:grid xl:grid-cols-4">
                    <div className="xl:col-start-2 xl:col-span-2">
                        <Recommended categories={categories} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
