import * as React from "react";
import Overview from "./overview";
import Status from "./status";
import Timeline from "./timeline";
import { Audit } from "../../../../redux/auditReducer";

type Props = {
    audits: Audit[]
};

const Component: React.FunctionComponent<Props> = ({ audits }) => {

    return (
        <div className="pl-3 pr-3 sm:p-4 sm:mt-14">
            <div className="flex flex-col gap-y-3 sm:gap-y-6">
                <div className="sm:grid sm:grid-cols-4">
                    <div className="sm:col-start-2 sm:col-span-2">
                        <Overview />
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-4">
                    <div className="sm:col-start-2 sm:col-span-2">
                        <Status />
                    </div>
                </div>
                <div className="sm:grid sm:grid-cols-4">
                    <div className="sm:col-start-2 sm:col-span-2">
                        <Timeline audits={audits} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Component;