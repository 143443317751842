import * as React from "react";

interface Option {
    value: string
    label: string
    icon?: React.ReactNode
}

type Props = {
    options: Option[];
    value: string|number;
    onChange: (value: string|number) => void;
    placeholder?: string;
}

const Select: React.FunctionComponent<Props> = ({ options, value, onChange, placeholder }) => {

    const [isOpen, setIsOpen] = React.useState(false);

    const handleSelect = (value: string) => {
        onChange(value);
        setIsOpen(false);
    };

    const selectedOption = options.find((option) => option.value === value);

    return (
        <div className="relative w-full">
            <button
                type="button"
                className="w-full flex items-center justify-between bg-white border border-gray-300 text-gray-700 py-2 pl-4 pr-10 rounded leading-tight focus:outline-none focus:border-blue-500"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center">
                    {selectedOption?.icon && <span className="mr-2">{selectedOption.icon}</span>}
                    {selectedOption ? selectedOption.label : placeholder}
                </div>
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M7 10l5 5 5-5H7z" />
                </svg>
            </button>
            {isOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                            className="cursor-pointer flex items-center px-4 py-2 hover:bg-gray-100"
                        >
                            <span className="mr-2">{option.icon}</span>
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Select;