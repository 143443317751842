import * as React from "react";
import Button from "../../../../components/Button";
import { SimpleUSer } from "../../../../redux/userReducer";

type Props = {
    members: SimpleUSer[];
    list_size: number;
}

const Component: React.FunctionComponent<Props> = ({ members, list_size }) => {

    const [visibleMembersCount, setVisibleMembersCount] = React.useState<number>(list_size);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [resetsAccount, setResetsAccount] = React.useState<SimpleUSer[]>([]);

    const handleShowMore = () => {
        setVisibleMembersCount((prevCount) => prevCount + list_size);
    };

    const filteredList = members.filter((user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedMembers = filteredList.sort((a, b) => a.email.localeCompare(b.email));
    const visibleMembers = sortedMembers.slice(0, visibleMembersCount);

    const handleSelect = (member: SimpleUSer) => {
        setResetsAccount((prevSelected) => {
            if (prevSelected.includes(member)) {
                return prevSelected.filter((m) => m.id !== member.id);
            } else {
                return [...prevSelected, member];
            }
        });
    };

    const isSelected = (member: SimpleUSer) => {
        return resetsAccount.some((m) => m.id === member.id);
    };

    return (
        <>
            <div className="mr-4 w-full bg-gray-200 rounded-t-lg border-l border-r border-t border-gray-400 flex pb-4 pt-4 items-end">
                <input type="text" className="w-full ml-2 mr-2 sm:ml-4 sm:w-1/2" placeholder="Search for user by first name, email or username" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            <div className="w-full bg-gray-200 border-l border-r border-b border-gray-400 pb-4 pt-4 flex justify-between w-full">
                <div className="ml-4">
                    <Button title={`Reset password (${resetsAccount.length})`} />
                </div>
                <div className="mr-4 font-semibold hidden sm:block">
                    Showing {visibleMembers.length} of {filteredList.length}
                </div>
            </div>
            <div className="border-b border-r border-l border-gray-400">
                <div className="flex gap-4 w-full mb-2">
                    <div className="w-1/8 ml-2 mt-2">
                        <input disabled id="disabled-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    </div>
                    <div className="w-1/4 ml-2 mt-2 font-bold text-left">
                        Person & username
                    </div>
                    <div className="w-1/2 text-left mt-2 font-bold">
                        Email
                    </div>
                </div>
            </div>
            {visibleMembers.map((member, index) => {
                return (
                    <div className="border-b border-r border-l border-gray-400" key={index}>
                        <div className="flex gap-4 w-full mb-2">
                            <div className="w-1/8 ml-2 mt-2">
                                <input
                                    id="disabled-checkbox"
                                    type="checkbox"
                                    checked={isSelected(member)}
                                    onChange={() => handleSelect(member)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            </div>
                            <div className="w-1/4 ml-2 mt-2">
                                <div className="flex flex-col">
                                    <div className="text-blue-800 font-semibold hover:underline">
                                        {member.firstname} {member.lastname}
                                    </div>
                                    <div className="text-gray-600 text-sm">
                                        {member.username}
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2 text-left mt-2">
                                {member.email}
                            </div>
                        </div>
                    </div>
                )
            })}
            {visibleMembersCount < filteredList.length && (
                <div className="mr-4 w-full bg-gray-200 rounded-b-lg border-l border-r border-b border-gray-400 flex pb-4 pt-4 items-center">
                    <div className="text-center mt-4 w-full">
                        <Button title="Show More" onClick={handleShowMore} />
                    </div>
                </div>
            )}
        </>)
};

export default Component;