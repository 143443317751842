import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "react-redux-typescript";
import { API_URL } from "../constant";
import { RootState } from ".";

type ConversationId = string;

export type Conversation = {
    conversation_id: ConversationId
    name: string
    model: string
    avatar: string
}

type ConversationsState = {
    conversationList: Conversation[];
    loading: boolean;
    error: string | null;
};

type authConversation = {
    conversation_id: ConversationId;
    token: string;
}

// init state
const initialState = {
    conversationList: [],
    loading: false,
    error: null,
} as ConversationsState;


export const fetchConversations = createAsyncThunk<Conversation[], void, { rejectValue: string }>(
    "conversations/fetchConversations",
    async (_, {rejectWithValue, getState}) => {
        const state = getState() as RootState
      try {
        const headers = { 'Authorization': `Bearer ${state.user.user?.token}`, 'Accept': 'application/json' }
        const response = await fetch(API_URL + 'conversations', { headers });
        const conversations: any[] = await response.json();

        const conversations_displayed: Conversation[] = []

        conversations.forEach((conversation) => {
            if (conversation.messages && conversation.messages[0].response) {
                const temp: Conversation = {
                    conversation_id: conversation.conversation_id,
                    name: conversation.messages[0].question.prompt_original,
                    model: conversation.messages[0].response.model,
                    avatar: conversation.messages[0].response.avatar
                }
                conversations_displayed.push(temp)
            }
        })
        return conversations_displayed;
      } catch (error) {
        return rejectWithValue("Failed to fetch issues.");
      }
    }
  );


const allConversationsSlice = createSlice({
    // name used in action types
    name: "conversations",
    // initial state
    initialState,
    // an object of "case reducers"
    // key names are used to generate actions
    reducers: {
        addConversation(
            state, //type is infered from the initial state
            // `PayloadAction` is a generic-type
            // that allows you to specify an action
            // with a typped payload.
            action: PayloadAction<string, Conversation>
        ){
            state.conversationList.push(action.payload);
        },
        delConversation(
            state, //type is infered from the initial state
            // `PayloadAction` is a generic-type
            // that allows you to specify an action
            // with a typped payload.
            action: PayloadAction<string, authConversation>
        ){
            const index = state.conversationList.findIndex(conversation => conversation.conversation_id === action.payload.conversation_id);
            //state.conversationList.indexOf(action.payload, 0);
            if(index > -1){
                state.conversationList.splice(index, 1)
                const headers = {
                    'Authorization': `Bearer ${action.payload.token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
                fetch(`${API_URL}conversation/${action.payload.conversation_id}`, {method: 'delete', headers: headers})
            }
        }
    },
    extraReducers: (builder) => {
        //  arguments of action are same first one is state
        //& second one is action
        builder.addCase(fetchConversations.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchConversations.fulfilled, (state, action) => {
            state.loading = false;
            state.conversationList = action.payload
        })
        .addCase(fetchConversations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Something went wrong';
        })
    },
});

export const { addConversation, delConversation } = allConversationsSlice.actions;
export default allConversationsSlice.reducer;