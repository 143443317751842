import Card from "../Card"
import * as React from "react";
import { Campaign, Category } from ".";
import { FaInfo } from "react-icons/fa6";

type Props = {
    categories: [string, Category][]
}

type CampaignProps = {
    index: number;
    campaign?: Campaign;
}

const CampaignComponent: React.FunctionComponent<CampaignProps> = ({ index, campaign = {theme:"N/A", focus:"N/A", activities:"N/A"} }) => {

    const [expand, setExpand] = React.useState<boolean>(false);

    return (
        <>
        <div className="flex gap-4 items-center pb-2">
            {index + 1} - <span className="font-bold">Campaign theme: </span><span >{campaign.theme}</span> <FaInfo className="text-[#3b5998]" onClick={()=>setExpand(!expand)}/>
        </div>
        {expand &&
            <div className="flex flex-col pb-2">
                <div className="flex gap-4">
                <span className="font-bold">Focus: </span><span>{campaign.focus}</span>
                </div>
                <div className="flex gap-4">
                <span className="font-bold">Activities: </span><span>{campaign.activities}</span>
                </div>
            </div>
        }
        </>
    )

}
const Component: React.FunctionComponent<Props> = ({ categories }) => {

    return (
        <Card title="Recommended Training">
            <div className="flex flex-col">
                {categories.map((category, index) =>
                    <CampaignComponent index={index} campaign={category[1].campaign} />
                )}
            </div>
        </Card>
    )
};

export default Component;