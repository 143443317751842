import { useState } from "react";
import {v4 as uuidv4} from 'uuid';

/**
 * The `useRefreshId` hook fetches an unique id.
 *
 * @example
 * ```tsx
 * const refreshId = useRefreshId();
 * ```
 * @public */
export const useRefreshId = () => {
    const [refreshId, setRefreshId] = useState<string>(uuidv4());

    const forceRefresh = (): void => {
      setRefreshId(uuidv4());
    };
  
    return [refreshId, forceRefresh] as const;
}