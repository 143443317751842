import * as React from "react";
import Card from "../Card"
import { Audit } from "../../../../redux/auditReducer";

import { FaUserPlus, FaCog, FaExclamationTriangle, FaEnvelope, FaKey, FaUserShield } from "react-icons/fa";
import { FaMagento, FaBrain } from "react-icons/fa6";
import Button from "../../../../components/Button";

type Props = {
    audits: Audit[]
};

type ActionIconMap = {
    [key: string]: React.ReactNode;
};

type ActionLabelMap = {
    [key: string]: string;
};

// Mapping des actions aux icônes
const actionIcons: ActionIconMap = {
    add_member: <FaUserPlus size={24} className="text-green-500" />,
    create_configuration: <FaCog size={24} className="text-blue-500" />,
    update_configuration: <FaCog size={24} className="text-green-500" />,
    add_violation: <FaMagento size={24} className="text-green-500" />,
    send_invitation: <FaEnvelope size={24} className="text-blue-500" />,
    remove_invitation: <FaEnvelope size={24} className="text-red-500" />,
    remove_violation: <FaMagento size={24} className="text-red-500" />,
    add_llm: <FaBrain size={24} className="text-green-500" />,
    remove_llm: <FaBrain size={24} className="text-red-500"/>,
    create_llm_secret: <FaKey size={24} className="text-green-500"/>,
    update_llm_secret: <FaKey size={24} className="text-blue-500"/>,
    delete_llm_secret: <FaKey size={24} className="text-red-500"/>,
    user_activate_mfa: <FaUserShield size={24} className="text-blue-500"/>,
    user_validate_mfa: <FaUserShield size={24} className="text-green-500"/>,
    user_deactivate_mfa: <FaUserShield size={24} className="text-red-500"/>
};

const actionLabels: ActionLabelMap = {
    add_violation: "Add agent",
    update_configuration: "Update configuration",
    create_configuration: "Create configuration",
    add_member: "Add member",
    send_invitation: "Invitation sent",
    remove_invitation: "Remove invitation",
    remove_violation: "Remove agent",
    add_llm: "Add model",
    remove_llm: "Remove model",
    create_llm_secret: "Create a llm token",
    update_llm_secret: "Update a llm token",
    delete_llm_secret: "Delete a llm token",
    user_activate_mfa: "Activate MFA",
    user_validate_mfa: "Validate MFA",
    user_deactivate_mfa: "Deactivate MFA",
}



const Component: React.FunctionComponent<Props> = ({ audits }) => {

    const [showMore, setShowMore] = React.useState(false);

    const sortedAudits = [...audits].sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    const displayedAudits = showMore ? sortedAudits : sortedAudits.slice(0, 5);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const getIcon = (action: string): React.ReactNode => {
        return actionIcons[action] || (<FaExclamationTriangle size={24} color="red-500" />);
    };

    const getLabel = (action: string): string => {
        return actionLabels[action] || "Inconnu";
    };

    return (

        <Card title="Audits">
            <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
                {displayedAudits.map((audit) => {
                    const icone = getIcon(audit.action);
                    const label = getLabel(audit.action);
                    return (
                        <div key={audit.id} className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                            <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                                {icone}
                            </div>
                            <div className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow-lg">
                                <div className="flex items-center justify-between space-x-2 mb-1">
                                    <div className="font-bold text-slate-900">{label}</div>
                                    <time className="font-caveat font-medium text-[#3b5998]">{new Date(audit.created_at).toLocaleString()}</time>
                                </div>
                                <div className="text-slate-500">{audit.message}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {audits.length > 5 && (
                <div className="flex flex-col items-center m-4">
                    <Button title={showMore ? "Show Less" : "Show More"} onClick={handleShowMore} />
                </div>
            )}
        </Card>
    )
}

export default Component;