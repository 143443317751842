import * as React from "react";
import Card from "../Card";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type Props = {
    violations?: number
    blocked?: number
};

const Component: React.FunctionComponent<Props> = ({ violations = 12, blocked = 33 }) => {

    const series = [violations, blocked]

    const options: ApexOptions = {
        chart: {
            type: 'donut'
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            showAlways: true,
                            show: true
                        }
                    }
                }
            }
        },
        labels: ["violations", "protecteds"],
        colors: ["#c81e1e", "#a4cafe"],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        }
    }

    return (
        <Card title="Overall categories">
            <div className="flex w-full content-center justify-center">
                <div className="flex flex-row">
                    <ReactApexChart options={options} type="donut" height={200} width={200} series={series} />
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-row">
                            <div className={'relative flex justify-center w-1 h-12 bg-[#c81e1e]'}></div>
                            <div className="ml-2">
                                <div>Violations</div>
                                <div className="font-bold">{violations}</div>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className={'relative flex justify-center w-1 h-12 bg-[#a4cafe]'}></div>
                            <div className="ml-2">
                                <div>Protected</div>
                                <div className="font-bold">{blocked}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default Component;