import * as React from "react";
import ConversationComponent from "./conversation";
import { AppDispatch, RootState, useAppDispatch } from "../../../../redux";
import { useSelector } from "react-redux";
import { Conversation, fetchConversations } from "../../../../redux/allConversationsReducer";

type Props = object

const Component: React.FunctionComponent<Props> = () => {
    
    const dispatch: AppDispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(fetchConversations())
    },[dispatch])

    const conversationsList = useSelector((state: RootState) => state.conversations.conversationList)

    return (
        <div className="pt-2 border-l border rounded-md hidden lg:block">
            {conversationsList && conversationsList.map((conversation: Conversation) => (
                <ConversationComponent title={conversation.name} slug={conversation.conversation_id} key={conversation.conversation_id}/>
            ))}
        </div>
    )
};

export default Component;