import * as React from "react";

type Props = {
    title: string|React.ReactNode
    size?: string
    children: React.ReactNode
};

const SubSection: React.FunctionComponent<Props> = ({ title, children, size='w-full' }) => {

    return (
        <div className={`flex flex-row sm:flex-col ml-8 ${size}`}>
            <div>
                <h2 className="font-semibold mb-4">
                    {title}
                </h2>
                {children}
            </div>
        </div>
    )
}

export default SubSection;